<template>
  <v-container>
    <div v-if="series && series.length > 0">
      <ApexChart type="pie" :options="chartOptions" :series="series"> </ApexChart>
    </div>
    <div v-else>Not Data available</div>
  </v-container>
</template>

<script>
import ApexChart from "vue-apexcharts";
export default {
  components: {
    ApexChart,
  },
  props: ["month"],
  data() {
    return {
      monthVal: "",
      series: null,
      chartOptions: null,
    };
  },
  methods: {
    getTrendingMeals() {
      this.$apollo
        .query({
          query: require("../api/getTrendingMealCount.graphql"),
          variables: {
            month: this.monthVal,
          },
        })
        .then((data) => {
          let trendingMealList = data.data.getTrendingMealCount;
          let graphLabels = new Array();
          let graphCount = new Array();
          trendingMealList.forEach((element) => {
            graphLabels.push(element.meal);
            graphCount.push(parseInt(element.count));
          });

          this.series = graphCount;
          this.chartOptions = {
            chart: {
              type: "pie",
            },
            labels: graphLabels,
            legend: {
              show: false,
            },
            responsive: [
              {
                breakpoint: 200,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
          };
        });
    },
  },
  created() {
    let date = new Date();
    let currentMonth = date.getMonth();
    this.monthVal = currentMonth + 1;
    this.getTrendingMeals();
  },
  watch: {
    month(newValue) {
      this.monthVal = newValue;
      this.getTrendingMeals();
    },
    immediate: true,
    deep: true,
  },
};
</script>
