<template>
  <v-card class="mx-1 mb-1" tyle="height: 240px">
    <v-card-title class="pa-6 pb-3">
      <p>Deliveries Today</p>
    </v-card-title>
    <v-card-text class="pa-6 pt-0">
      <v-row no-gutters class="pb-1">
        <v-col>
          <div class="text-h6 card-light-grey font-weight-regular">
            Pending ({{ pending }})
          </div>
          <v-progress-linear
            :value="pendingP"
            background-color="#ececec"
            color="yellow"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row no-gutters class="pb-3">
        <v-col>
          <div class="text-h6 card-light-grey font-weight-regular">
            Delivered ({{ deliverd }})
          </div>
          <v-progress-linear
            :value="deliverdP"
            background-color="#ececec"
            color="success"
          ></v-progress-linear>
        </v-col>
      </v-row>
      <v-row no-gutters class="pb-1">
        <v-col>
          <div class="text-h6 card-light-grey font-weight-regular">
            Not Delivered ({{ notDeliverd }})
          </div>
          <v-progress-linear
            :value="notDeliverdP"
            background-color="#ececec"
            color="error"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
export default {
  created() {
    this.$apollo
      .query({
        query: require("../api/getDeliveryStatusCount.graphql"),
      })
      .then((data) => {
        // console.log(data);
        let deliveryStatusList = data.data.getDeliveryStatusCount;
        this.deliveryStatusList = deliveryStatusList;

        if (!deliveryStatusList.length) this.$emit("hasData", false);
        let notDeliverd = deliveryStatusList.find((x) => x.status == "NOT_DELIVERED");
        if (notDeliverd) {
          this.notDeliverd = parseInt(notDeliverd.count);
        }
        let deliverd = deliveryStatusList.find((x) => x.status == "DELIVERED");
        if (deliverd) {
          this.deliverd = parseInt(deliverd.count);
        }
        let pending = deliveryStatusList.find((x) => x.status == "PENDING");
        if (pending) {
          this.pending = parseInt(pending.count);
        }

        let total = this.notDeliverd + this.deliverd + this.pending;

        this.notDeliverdP = (this.notDeliverdP * 100) / total;
        this.deliverdP = (this.deliverd * 100) / total;
        this.pendingP = (this.pending * 100) / total;
      });
  },
  methods: {},
  data() {
    return {
      deliveryStatusList: null,
      notDeliverd: 0,
      deliverd: 0,
      pending: 0,
      notDeliverdP: 0,
      deliverdP: 0,
      pendingP: 0,
    };
  },
};
</script>
