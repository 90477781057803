import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      dark: {
        primary: "#E2BC89",
        secondary: "#E2BC89",
        background: "#FFFFFF",
        black: "#000000", 
        bg: "#FFFFFF"
      },
      light: {
        primary: "#E2BC89",
        secondary: "#E2BC89",
        background: "#FFFFFF",
        black: "#000000",
        bg: "#FFFFFF"
      }
    }
  }
});
