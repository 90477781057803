<template>
  <v-container grid-list-xs>
    <div v-if="mealRatings.length > 0" class="mt-4">
      <v-virtual-scroll
        height="300"
        item-height="64"
        :bench="benched"
        :items="mealRatings"
        class="mt-4"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item._id">
            <v-row>
              <v-col cols="3">
                <v-list-item-action>
                  <v-avatar :width="45" :height="45">
                    <v-img
                      v-if="item.customer.user.photo"
                      :src="imageUrl + item.customer.user.photo"
                      contain
                      max-width="45"
                      max-height="45"
                      position="left"
                    />
                    <v-img
                      v-else
                      :src="require('../../assets/user.jpg')"
                      contain
                      max-width="45"
                      max-height="45"
                      position="left"
                    />
                  </v-avatar>
                </v-list-item-action>
              </v-col>
              <v-col cols="9">
                <v-row>
                  <v-col>
                    <v-row class="mt-2">
                      <v-list-item-title>
                        <span class="font-weight-medium"
                          >{{ item.customer.user.firstnameEn }}
                          {{ item.customer.user.firstnameEn }}
                        </span>
                      </v-list-item-title>
                    </v-row>
                    <v-row class="mt-0 mt-3">
                      <v-list-item-title>
                        <v-list-item-title>
                          <span class="font-weight-light">{{ item.meal.nameEn }} </span>
                        </v-list-item-title>
                      </v-list-item-title>
                    </v-row>
                    <v-row>
                      <v-list-item-title>
                        <v-rating
                          class="ms-n1"
                          small
                          length="5"
                          size="64"
                          color="yellow"
                          :value="item.rating"
                          :readonly="true"
                        ></v-rating>
                      </v-list-item-title>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-list-item>
          <v-divider class="mt-1"></v-divider>
        </template>
      </v-virtual-scroll>

      <!-- </template> -->
      <!-- </v-virtual-scroll> -->
    </div>
    <div v-else>
      <v-list-item-title class="text-center pb-5 mt-5"
        >******No Ratings******</v-list-item-title
      >
    </div>
  </v-container>
</template>
<script>
export default {
  apollo: {
    getAllCustomerRating() {
      return {
        query: require("@/customer/api/getAllCustomerRating.graphql"),
        variables: {
          customerId: "",
          limit: 10,
          skip: 0,
          sortDesc: false,
          sortBy: "",
        },
        result({ data, loading }) {
          this.loading = loading;
          if (data.getAllCustomerRating) {
            this.mealRatings = data.getAllCustomerRating.customerMealRating;
          }
        },
      };
    },
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      mealRatings: [],
      benched: 0,
      loading: true,
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px",
      },
    };
  },
};
</script>
