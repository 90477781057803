var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-3 mt-sm-0"},[_c('h2',{staticClass:"black--text text-h5 font-weight-bold ms-3"},[_vm._v("Vendors")]),_c('v-spacer'),_c('v-layout',{staticClass:"me-3",attrs:{"justify-end":""}},[(_vm.hasRole(_vm.AdminUserPermissions.SUPPLIER_STOCK_ADD))?_c('CreateVendor'):_vm._e()],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.itemsWithSno,"loading":_vm.loading},scopedSlots:_vm._u([{key:"item.brand",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBrandName(item))+" ")]}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [(item.address && item.address.area)?_c('span',[_vm._v(" "+_vm._s(item.address.area.nameEn)+" ")]):_vm._e()]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [(item.address && item.address.block)?_c('span',[_vm._v(" "+_vm._s(item.address.block.block)+" ")]):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-row',[(_vm.hasRole(_vm.AdminUserPermissions.SUPPLIER_STOCK_EDIT))?_c('EditVendor',{attrs:{"vendorDetails":item}}):_vm._e(),(_vm.hasRole(_vm.AdminUserPermissions.SUPPLIER_STOCK_DELETE))?_c('DeleteVendor',{attrs:{"vendorDetails":item}}):_vm._e()],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }