<template>
  <div>
    <v-btn
      @click="showLog"
      color="primary"
      class="white--text text-capitalize"
      :small="$vuetify.breakpoint.xs"
      :width="$vuetify.breakpoint.xs ? 100 : ''"
    >
      Log
    </v-btn>

    <v-dialog v-model="dialog" width="1200">
      <v-card class="pa-2 pa-sm-3" color="bg">
        <v-progress-linear
          indeterminate
          v-if="loading"
          color="yellow darken-2"
        ></v-progress-linear>
        <ul v-if="status">
          <div v-if="!quickView">
            <li>Delivery Report : {{ status.delivery ? "Success" : "NO" }}</li>
            <li>Meal Report : {{ status.meal ? "Success" : "NO" }}</li>
            <li>Order Report : {{ status.order ? "Success" : "NO" }}</li>
            <li>
              Ingredient Report : {{ status.ingredient ? "Success" : "NO" }}
            </li>
          </div>
          <div v-else>
            <li>Report Status : {{ status.delivery ? "Success" : "NO" }}</li>
          </div>
        </ul>
        <v-btn
          @click="onRefresh"
          color="primary"
          class="white--text text-capitalize"
          :small="$vuetify.breakpoint.xs"
          :width="$vuetify.breakpoint.xs ? 100 : ''"
        >
          Recheck
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    date: {
      required: true
    },
    quickView: {
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      status: "",
      loading: false
    };
  },

  methods: {
    onRefresh() {
      this.getReportStatus();
    },
    getReportStatus() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../reports/api/getReportStatus.graphql"),
          variables: {
            date: this.date,
            quickView: this.quickView
          }
        })
        .refetch({
          variables: {
            date: this.date
          }
        })
        .then(data => {
          this.loading = false;
          this.status = null;
          if (data.data.getReportStatus) {
            this.status = data.data.getReportStatus;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    showLog() {
      this.dialog = true;
      this.getReportStatus();
    }
  }
};
</script>
