<template>
  <div v-if="blockList != null">
    <v-row >
      <v-col cols="12" sm="6" md="4"    >
        <v-text-field
          placeholder="Block.."
          v-model="search"
          solo
          dense
          @keydown.enter="runSearch()"
        >
          <template v-slot:append>
            <v-icon
              v-if="search.length > 0"
              color="black"
              class="me-n2"
              @click="clearSearch()"
              >mdi-close</v-icon
            >
            <v-icon
              v-if="search.length == 0"
              color="black"
              class="me-n2"
              @click="runSearch()"
              >mdi-magnify</v-icon
            >
          </template>
        </v-text-field>
        </v-col>
        <v-col class="mt-n13 mt-sm-0" >   
          <ExportBlock :blockList="blockList"/> 
        </v-col>
        </v-row>
    <v-data-table
      :headers="headers"
      :items="blockList"
      class="elevation-1 "
      :mobile-breakpoint="0"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <BlockUpdateForm v-if="hasRole(AdminUserPermissions.LOCATION_EDIT)"  :blockInfo="item" />
          <deleteBlock v-if="hasRole(AdminUserPermissions.LOCATION_DELETE)"  :shift="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import BlockUpdateForm from "../components/BlockUpdateForm";
import deleteBlock from "../components/BlockDeleteForm";
import ExportBlock from "../components/Export/ExportBlock.vue";
export default {
  components: {
    BlockUpdateForm,
    deleteBlock,
    ExportBlock 
  },
  mounted() {
    this.$root.$on("updateBlock", () => {
      this.getAllBlocks();
    });
  },

  data: () => ({
    search:"",
    dialog: false,
    blockList: [],
    loading: true,
    headers: [
      {
        text: "Area",
        value: "area.nameEn",
      },
      { text: "Block", value: "block" },
      { text: "Actions", value: "actions", sortable: false },
    ],
  }),
  created() {
    this.getAllBlocks();
  },
  methods: {
    getAllBlocks() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllBlocks.graphql"),
          variables: {
            limit: 0,
            skip: 0,
            searchKey:this.search
          },
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          if (data.data.getAllBlocks) {
            this.blockList = data.data.getAllBlocks.blocks;
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
     runSearch() {
     this.getAllBlocks()
    },
    clearSearch() {
      this.search = "";
      this.getAllBlocks()
    },
  },
};
</script>

