<template>
  <v-container>
    <v-form class="ms-8 ms-sm-0 pa-sm-8" @submit="createNotification()" v-model="valid" ref="form">
      <v-layout class="mb-5">
        <v-flex md5 class="mt-4">
          Placeholder :<strong> EN_FIRSTNAME / AR_FIRSTNAME</strong>
        </v-flex>
      </v-layout>
      <v-layout class="mt-5 mt-md-0">
        <v-flex xs6 sm2 md8>
          <v-text-field label="Title" v-model="title" :rules="[requiredValidator('titile')]" solo dense></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout class="mt-5 mt-md-0">
        <v-flex xs6 sm2 md8>
          <v-autocomplete v-model="customer" :items="customerList" item-text="customerName" item-value="_id" solo chips
            dense attach small-chips label="Search customers" multiple clearable deletable-chips
            :search-input.sync="customersSearchKey" :loading="customerLoading" return-object>
            <template slot="no-data">
              <v-flex xs12 md12 class="mt-2 mb-2">
                <h5 class="font-weight-medium ml-4">Search for customer...</h5>
              </v-flex>
            </template>
            <template slot="item" slot-scope="data">{{ data.item.user.firstnameEn }}
              {{ data.item.user.lastnameEn }}</template>
          </v-autocomplete>
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox :disabled="customer.length > 0" label="Female" v-model="reciverType" value="Female"></v-checkbox>

          <v-icon class="black--text ms-2 ms-sm-3" :large="$vuetify.breakpoint.smAndUp">fa-female</v-icon>
        </v-flex>
        <v-flex xs6 sm3 lg2 class="d-flex">
          <v-checkbox :disabled="customer.length > 0" label="Male" v-model="reciverType" value="Male"></v-checkbox>
          <v-icon class="black--text ms-2 ms-sm-3" :large="$vuetify.breakpoint.smAndUp">fa-male</v-icon>
        </v-flex>
        <v-flex xs3 sm2>
          <v-checkbox :disabled="customer.length > 0" label="All" v-model="reciverType" value="All"></v-checkbox>
        </v-flex>
      </v-layout>
      <!-- <v-layout wrap>
        <v-flex xs12 sm8 md3>
          <v-checkbox
            label="Include customer first name"
            v-model="is_IncludeFirstName"
          ></v-checkbox>
        </v-flex>
        
      </v-layout> -->
      <v-layout wrap>
        <v-flex xs12 sm8 md6>
          <v-textarea solo name="input-7-4" label="Description" v-model="description"
            :rules="[requiredValidator('description')]"></v-textarea>
        </v-flex>

        <v-flex class=" ml-sm-5 " xs12 sm3 md4>
          <croppa v-model="myCroppa" :width="200" :height="150" canvas-color="black" :placeholder="'Choose an image'"
            :placeholder-font-size="16" placeholder-color="yellow" :accept="'image/*'" :file-size-limit="0" :quality="2"
            :zoom-speed="3" :disabled="false" :disable-drag-and-drop="false" :disable-click-to-choose="false"
            :disable-drag-to-move="false" :disable-scroll-to-zoom="false" :disable-rotation="false"
            :prevent-white-space="false" :reverse-scroll-to-zoom="false" :show-remove-button="true"
            :remove-button-color="'red'" :remove-button-size="20"></croppa>

          <v-layout class="mb-3">
            <span style="color: red">
              *Use mouse to crop image*
            </span>
          </v-layout>

        </v-flex>
      </v-layout>
      <v-layout class="mt-5 mt-md-0">
        <v-btn color="secondary" class="black--text text-capitalize" @click="createNotification"
          :width="$vuetify.breakpoint.smAndUp ? 150 : 120" :loading="btnloading" :disabled="!valid">Send</v-btn>
      </v-layout>
    </v-form>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
  </v-container>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: {},
  data() {
    return {
      requiredValidator: requiredValidator,
      btnloading: false,
      text: "",
      snackbar: false,
      valid: true,
      snackbarColor: "",
      title: "",
      description: "",
      reciverType: "",
      customer: [],
      is_IncludeFirstName: false,
      customerList: [],
      totalItemsCount: 25,
      options: {
        itemsPerPage: 0,
        page: 1,
      },
      variables: {
        limit: 25,
        skip: 0,
      },
      customersSearchKey: "",
      customerLoading: false,
      myCroppa: null,
      image: null
    };
  },
  watch: {
    customersSearchKey: function (val) {
      if (val == null || val == undefined) return;
      if (val.length < 3) return;
      let customer = JSON.parse(JSON.stringify(this.customer));
      if (!this.awaitingSearch) {
        setTimeout(() => {
          this.awaitingSearch = false;
          this.customerLoading = true;
          this.$apollo
            .query({
              query: require("@/customer/api/searchCustomers.graphql"),
              variables: {
                limit: 25,
                skip: 0,
                searchKey: val,
              },
            })
            .then((data) => {
              let customerList = data.data.searchCustomers.customers;
              customerList.forEach((element) => {
                if (element.user)
                  element.customerName = `${element.customerId} - ${element.user.firstnameEn} ${element.user.lastnameEn}`;
              });

              customer.forEach((element) => {
                let item = customerList.find((x) => x._id == element._id);
                if (!item) customerList.unshift(element);
              });

              this.customerList = customerList;
              console.log(this.customerList);
              this.customerLoading = false;
            });
        }, 1000);
      }
      this.awaitingSearch = true;
    },
    reciverType: {
      handler() {
        if (!this.reciverType) {
          this.reciverType = " ";
        }
      },
    },
  },

  methods: {
    createNotification() {
      this.btnloading = true;
      if (this.myCroppa.hasImage()) {
        this.myCroppa.generateBlob(blob => {
          this.image = blob;
          this.createNotificationAPI();
        });
      } else {
        this.createNotificationAPI();
      }
    },
    createNotificationAPI() {
      this.btnloading = true;
      if (!this.reciverType) {
        this.reciverType = "";
      }
      if (this.is_IncludeFirstName == undefined) {
        this.is_IncludeFirstName = false;
      }
      let customers = new Array();
      this.customer.forEach((element) => {
        customers.push(element._id);
      });
      this.$apollo
        .mutate({
          mutation: require("../api/createNotification.graphql"),
          variables: {
            title: this.title,
            description: this.description,
            reciverType: this.reciverType,
            is_IncludeFirstName: this.is_IncludeFirstName,
            customer: customers,
            image: this.image
          },
          update: (store, { data: { createNotification } }) => {
            try {
              const query = {
                query: require("../api/getAllNotifications.graphql"),
                variables: {
                  limit: 25,
                  skip: 0,
                },
              };
              let allNotifications = store.readQuery(query);
              allNotifications.getAllNotifications.notifications.unshift(
                createNotification
              ); //push to top
              store.writeQuery({
                ...query,
                data: allNotifications,
              });
              this.text = "Notifications Added";
              this.snackbarColor = "success";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.$refs.form.reset();
            this.reciverType = " ";
            this.btnloading = false;
            this.image = null
            this.myCroppa.remove()
          },
        })
        .then(() => {
          this.dialog = false;
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            this.btnloading = false;
            // this.text = error.graphQLErrors[0].message;
            this.text = "Some error";
            this.snackbarColor = "error";
            this.snackbar = true;
          }

          this.btnloading = false;
          console.log(error);
        });
    },
  },
};
</script>
<style>
.v-input--selection-controls .v-input__slot>.v-label,
.v-input--selection-controls .v-radio>.v-label {
  color: rgb(0, 0, 0) !important;
  font-weight: 400;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
