var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('h2',{staticClass:"black--text text-h5 font-weight-bold mb-4"},[_vm._v(" Paused Meals Report ")]),_c('v-layout',{staticClass:"mt-n5 mt-md-2 ms-n4",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mt-sm-n5 mt-md-0 ms-sm-5 me-5",attrs:{"xs7":"","sm4":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate,"label":"Date","readonly":"","solo":"","append-icon":"fa-calendar-alt black--text","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5 ms-md-0 mb-8 mb-sm-0",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-btn',{staticClass:"ms-sm-5 px-6 black--text text-capitalize",attrs:{"color":"secondary"},on:{"click":_vm.searchMeals}},[_vm._v("Search ")])],1)],1),_c('v-layout',{attrs:{"justify-end":""}},[_c('v-flex',{attrs:{"md3":""}},[_c('ExportPausedMealReport',{attrs:{"date":_vm.date,"headers":_vm.headers}})],1)],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"id":"myTable","headers":_vm.headers,"items":_vm.customerList,"loading":_vm.loading,"item-key":"slNo","options":_vm.options,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.slNo",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(item.slNo)+" ")]}},{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'CustomerDetails',
        params: { customerId: '' },
        query: { customerId: '' }
      }}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")])]}},{key:"item.startDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.start_date)),"DD MMM YYYY")))]}},{key:"item.endDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.end_date)),"DD MMM YYYY")))]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }