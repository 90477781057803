<template>
  <v-container>
    <ApexChart height="350" type="bar" :options="chartOptions" :series="series">
    </ApexChart>
  </v-container>
</template>
<script>
import ApexChart from "vue-apexcharts";
export default {
  components: {
    ApexChart,
  },
  name: "BarChartContainer",
  // props: ["chartdata"],

  props: {
    orderReportType: {
      required: true,
    },
    orderYear: {
      required: true,
    },
    orderMonth: {
      required: true,
    },
  },
  watch: {
    orderReportType: {
      handler(val) {
        if (val === "Year" && this.orderYear) {
          this.getChartDataYear();
        } else if (val === "Month" && this.orderMonth) {
          this.getChartDataMonth();
        }
      },
    },
    orderYear: {
      handler() {
        this.getChartDataYear();
      },
    },
    orderMonth: {
      handler() {
        this.getChartDataMonth();
      },
    },
  },
  data() {
    return {
      chartOptions: null,
      series: null,
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },

  created() {
    this.getChartDataYear();
  },

  methods: {
    getChartDataMonth() {
      this.$apollo
        .watchQuery({
          query: require("../api/getOrdersByDay.graphql"),
          variables: {
            month: this.orderMonth,
            year: this.orderYear,
          },
        })
        .refetch({
          variables: {
            month: this.orderMonth,
            year: this.orderYear,
          },
        })
        .then((data) => {
          let customers = data.data.getOrdersByDay;
          let graphLabels = new Array();
          let graphCount = new Array();
          customers.forEach((element) => {
            graphLabels.push(element.date);
            graphCount.push(element.count);
          });

          this.chartOptions = {
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              categories: graphLabels,
            },
          };
          this.series = [
            {
              name: "order",
              data: graphCount,
            },
          ];

          let chartData = {
            labels: graphLabels,
            datasets: [
              {
                label: "ORDER REPORT",
                backgroundColor: "#000000",
                data: graphCount,
              },
            ],
          };
          this.chartData = chartData;
        });
    },
    getChartDataYear() {
      this.$apollo
        .watchQuery({
          query: require("../api/getOrdersByMonth.graphql"),
          variables: {
            year: this.orderYear,
          },
        })
        .refetch({})
        .then((data) => {
          let customers = data.data.getOrdersByMonth;
          let graphLabels = new Array();
          let graphCount = new Array();
          customers.forEach((element) => {
            graphLabels.push(element.month);
            graphCount.push(element.count);
          });

          this.chartOptions = {
            chart: {
              id: "basic-bar",
            },
            xaxis: {
              categories: graphLabels,
            },
          };
          this.series = [
            {
              name: "Orders",
              data: graphCount,
            },
          ];

          let chartData = {
            labels: graphLabels,
            datasets: [
              {
                label: "ORDER REPORT",
                backgroundColor: "#000000",
                data: graphCount,
              },
            ],
          };
          this.chartData = chartData;
        });
    },
  },
};
</script>
