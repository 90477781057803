<template>
  <div>
    <!-- <v-layout justify-end> -->
    <v-snackbar
      v-model="successAlert"
      :bottom="false"
      color="success"
      :multi-line="false"
      :right="true"
      :top="true"
    >
      Customers successfully uploaded.
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="successAlert = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-snackbar
      v-model="errorAlert"
      :bottom="false"
      color="error"
      :multi-line="false"
      :right="true"
      :top="true"
    >
      Error occured, please check your csv file.
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="errorAlert = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-btn
      class="white-bg me-3 text-capitalize "
      width="195px"
      height="40px"
      color="secondary"
      @click.stop="showDialog = true"
    >
      <v-icon left>mdi-microsoft-excel</v-icon>Upload Customers
    </v-btn>

    <v-dialog v-model="showDialog" max-width="800">
      <v-card class="pa-4 pa-md-12">
        <v-row justify-sm="space-between" justify="start" class="mb-4 px-4">
          <v-col cols="12" sm="5">
            <p class="primary--text">Upload Customers (csv)</p>
          </v-col>
          <v-col cols="12" sm="3" class="d-flex justify-end">
            <v-btn
              class=""
              color="black"
              @click="sampleFileDownload"
              outlined
              depressed
              small
            >
              <v-icon left>mdi-export</v-icon> Sample
            </v-btn>
          </v-col>
        </v-row>

        <v-file-input
          v-model="fileUpload"
          label="File input"
          outlined
          placeholder="Select your file"
          dense
          @change="readFile"
        ></v-file-input>

        <v-data-table :headers="headers" :items="tableData"></v-data-table>

        <v-row justify="space-between" class="mt-4">
          <v-btn
            class="ms-3"
            depressed
            @click="
              showDialog = false;
              clearFields();
            "
            >Close</v-btn
          >

          <v-btn
            class="mt-1 me-4"
            color="error"
            v-show="errorMessages.length > 0"
            @click="showErrorDialog = true"
            outlined
            depressed
            small
          >
            Errors
          </v-btn>
          <v-btn
            class="primary me-3 text-capitalize"
            @click.stop="onUploadClick"
            :disabled="errorMessages.length > 0"
            :loading="btnLoading"
          >
            <!-- <v-icon left>mdi-plus</v-icon> -->
            Upload Customers
          </v-btn>
        </v-row>

        <v-overlay :value="btnLoading">
          <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>
        <v-span v-if="this.errorFromApi" class="mx-7">
          <p class="red--text">
            {{ errorFromApi }}
          </p>
        </v-span>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showErrorDialog" max-width="800">
      <v-card class="pa-4 pa-md-12">
        <v-row v-for="item in errorMessages" :key="item" class="error">
          {{ item }}
        </v-row>
      </v-card>
    </v-dialog>
    <!-- </v-layout> -->
  </div>
</template>

<script>
import Vue from "vue";
import { emailValidator } from "../../core/methods/validations";
import VuePapaParse from "vue-papa-parse";
Vue.use(VuePapaParse);

export default Vue.extend({
  components: {},
  created() {
    // this.loadLanguages();
  },
  methods: {
    clearFields() {
      this.tableData = [];
      this.errorMessages = [];
      this.errorFromApi = "";
      this.btnLoading = false;
    },
    sampleFileDownload() {
      const url = "../sampleCustomersCSV.csv";
      window.open(url);
    },
    readFile(event) {
      this.importcustomerData = [];
      this.errorMessages = [];
      this.errorFromApi = "";
      const self = this;
      if (!event) {
        self.clearFields();
        return false;
      }
      let errorMessages = new Array();
      this.$papa.parse(event, {
        header: true,
        dynamicTyping: true,
        complete: function (results) {
          const arrVal = new Array();
          // let previousProductCode = null;
          console.log(results.data);
          results.data.forEach((element) => {
            if (
              element["First Name En"] !== null &&
              element["First Name En"].trim() !== ""
            ) {
              const obj = {
                firstnameEn: String(element["First Name En"]).trim(),
                firstnameAr: String(element["First Name Ar"]).trim(),
                lastnameEn: element["Last Name En"],
                lastnameAr: String(element["Last Name Ar"]).trim(),
                email: String(element["Email"]).trim(),
                phone: String(element["Phone"]).trim(),
                dob: String(element["Dob"]).trim(),
                gender: String(element["Gender"]).trim(),
                howFoundAboutApp: String(element["How Found About App"]).trim(),
                contactMethod: String(element["Contact Method"]).trim(),
                customerGoal: String(element["Customer Goal"]).trim(),
                Comment: String(element["comment"]).trim(),
                martialStatus: String(element["Marital Status"]).trim(),
                pregnantStatus: String(element["Pregnant Status"]).trim(),
                activity: String(element["Activity"]).trim(),
                healthIssues: String(element["Health Issues"]).trim(),
                referredBy: String(element["Referred By"]).trim(),
                area: String(element["Area"]).trim(),
                addressType: String(element["Address Type"]).trim(),
                block: String(element["Block"]).trim(),
                shift: String(element["Shift"]).trim(),
                street: String(element["Street"]).trim(),
                jedha: String(element["Jedha"]).trim(),
                house_number: String(element["House Number"]).trim(),
                building_number: String(element["Building Number"]).trim(),
                apartment_number: String(element["Apartment Number"]).trim(),
                comments: String(element["Comments"]).trim(),
                map_location: String(element["Map Location"]).trim(),
              };
              arrVal.push(obj);
            } else {
              errorMessages.push("Error column customer firstName");
            }
          });
          self.errorMessages = errorMessages;
          const validatedData = self.validateCustomers(arrVal); //validation for required fields
          self.tableData = validatedData;
        },
      });
    },
    validateCustomers(customers) {
      let validEntries = [];
      let errorMessages = new Array();
      for (const [index, customer] of customers.entries()) {
        let signUpInput = new Object();
        let deliveryInput = new Object();
        let isValid = true;
        if (!customer.firstnameEn || customer.firstnameEn === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - customer firstnameEn (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          signUpInput.firstnameEn = customer.firstnameEn;
        }
        if (!customer.firstnameAr || customer.firstnameAr === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - firstnameAr (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          signUpInput.firstnameAr = customer.firstnameAr;
        }
        if (!customer.lastnameEn || customer.lastnameEn === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - lastnameEn (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          signUpInput.lastnameEn = customer.lastnameEn;
        }
        if (!customer.lastnameAr || customer.lastnameAr === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - lastnameAr (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          signUpInput.lastnameAr = customer.lastnameAr;
        }
        if (!customer.email || customer.email === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - email (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          signUpInput.email = customer.email;
        }
        if (!customer.phone || customer.phone === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - phone (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          if (customer.phone.length !== 8) {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - phone(should 8 digit) (customer name:${
                customer.firstnameEn
              })`
            );
          } else {
            signUpInput.phone = customer.phone;
          }
        }
        if (!customer.dob || customer.dob === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - dob (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          let result = this.validateDate(customer.dob);
          if (result == "DAY") {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - dob day (format) (customer name:${
                customer.firstnameEn
              })`
            );
          } else if (result == "MONTH") {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - dob month (format) (customer name:${
                customer.firstnameEn
              })`
            );
          } else if (result == "YEAR") {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - dob year (format) (customer name:${
                customer.firstnameEn
              })`
            );
          } else {
            signUpInput.dob = result;
          }
        }
        if (!customer.gender || customer.gender === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - gender (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          if (customer.gender !== "MALE" && customer.gender !== "FEMALE") {
            isValid = false;
            errorMessages.push(
              `Error row - ${index + 1}, coloumn - gender (capitalize) (customer name:${
                customer.firstnameEn
              })`
            );
          } else {
            signUpInput.gender = customer.gender;
          }
        }
        // if (!customer.howFoundAboutApp || customer.howFoundAboutApp === "null") {
        //   isValid = false;
        //   errorMessages.push(
        //     `Error row - ${index + 1}, coloumn - howFoundAboutApp (customer name:${
        //       customer.firstnameEn
        //     })`
        //   );
        // }
        if (!customer.contactMethod || customer.contactMethod === "null") {
          // isValid = false;
          // errorMessages.push(
          //   `Error row - ${index + 1}, coloumn - contactMethod (customer name:${
          //     customer.firstnameEn
          //   })`
          // );
        } else {
          signUpInput.contactMethod = customer.contactMethod;
        }
        if (!customer.customerGoal || customer.customerGoal === "null") {
          // isValid = false;
          // errorMessages.push(
          //   `Error row - ${index + 1}, coloumn - customerGoal (customer name:${
          //     customer.firstnameEn
          //   })`
          // );
        } else {
          signUpInput.customerGoal = customer.customerGoal;
        }
        if (!(!customer.activity || customer.activity === "null")) {
          signUpInput.activity = customer.activity;
        }
        if (customer.healthIssues) {
          signUpInput.healthIssues = customer.healthIssues;
        }
        // if (!customer.martialStatus || customer.martialStatus === "null") {
        //   isValid = false;
        //   errorMessages.push(
        //     `Error row - ${index + 1}, coloumn - martialStatus (customer name:${
        //       customer.firstnameEn
        //     })`
        //   );
        // } else {
        //   let martialStatus = ["MARRIED", "UNMARRIED", "WIDOWED", "DIVORCED"];
        //   let result = martialStatus.indexOf(customer.martialStatus);
        //   if (result == -1) {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index +
        //         1}, coloumn - martialStatus (format check) (customer name:${
        //         customer.firstnameEn
        //       })`
        //     );
        //   } else {
        //     signUpInput.martialStatus = customer.martialStatus;
        //   }
        // }
        // if (!customer.pregnantStatus || customer.pregnantStatus === "null") {
        //   if (customer.gender == "FEMALE") {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index +
        //         1}, coloumn - pregnantStatus (customer name:${
        //         customer.firstnameEn
        //       })`
        //     );
        //   } else {
        //     signUpInput.pregnantStatus = customer.pregnantStatus;
        //   }
        // } else {
        //   if (customer.gender == "FEMALE") {
        //     let pregnantStatus = [
        //       "No",
        //       "First Trimester",
        //       "Second Trimester",
        //       "Third Trimester"
        //     ];
        //     let result = pregnantStatus.indexOf(customer.pregnantStatus);
        //     if (result == -1) {
        //       isValid = false;
        //       errorMessages.push(
        //         `Error row - ${index +
        //           1}, coloumn - pregnantStatus (format check) (customer name:${
        //           customer.firstnameEn
        //         })`
        //       );
        //     } else {
        //       signUpInput.pregnantStatus = customer.pregnantStatus;
        //     }
        //   } else {
        //     isValid = false;
        //     errorMessages.push(
        //       `Error row - ${index +
        //         1}, coloumn - gender is not female, check pregnant status (customer name:${
        //         customer.firstnameEn
        //       })`
        //     );
        //   }
        // }
        if (!customer.area || customer.area === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - Address - area (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          deliveryInput.area = customer.area;
        }
        if (!customer.addressType || customer.addressType === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - Address - addressType (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          let addressType = ["Home", "Office", "Others"];
          let result = addressType.indexOf(customer.addressType);
          if (result == -1) {
            isValid = false;
            errorMessages.push(
              `Error row - ${
                index + 1
              }, coloumn - addressType (format check) (customer name:${
                customer.firstnameEn
              })`
            );
          } else {
            deliveryInput.addressType = customer.addressType;
          }
        }
        if (!customer.block || customer.block === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - Address - block (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          deliveryInput.block = customer.block;
        }
        if (!customer.shift || customer.shift === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - Address - shift (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          deliveryInput.shift = customer.shift;
        }
        if (!customer.street || customer.street === "null") {
          isValid = false;
          errorMessages.push(
            `Error row - ${index + 1}, coloumn - Address - street (customer name:${
              customer.firstnameEn
            })`
          );
        } else {
          deliveryInput.street = customer.street;
        }
        if (customer.jedha && customer.jedha !== "null") {
          deliveryInput.jedha = customer.jedha;
        }
        if (customer.house_number && customer.house_number !== "null") {
          deliveryInput.house_number = customer.house_number;
        }
        if (customer.building_number && customer.building_number !== "null") {
          deliveryInput.building_number = customer.building_number;
        }
        if (customer.apartment_number && customer.apartment_number !== "null") {
          deliveryInput.apartment_number = customer.apartment_number;
        }
        // if (customer.map_location && customer.map_location !== "null") {
        //   deliveryInput.map_location = customer.map_location;
        // }

        if (customer.comments && customer.comments !== "null") {
          deliveryInput.comments = customer.comments;
        }
        if (isValid) {
          let signupInputs = new Object();
          signupInputs.deliveryInput = deliveryInput;
          signupInputs.signUpInput = signUpInput;
          this.importcustomerData.push(signupInputs);
          validEntries.push(customer);
        }
      }
      this.errorMessages = errorMessages;

      return validEntries;
    },
    validateDate(date) {
      let result = date.split("-");
      let day = parseInt(result[0]);
      let month = parseInt(result[1]);
      let year = result[2];
      if (month > 12 || month < 1) {
        return "MONTH";
      }
      if (year.length !== 4) {
        return "YEAR";
      }
      if (day > 31 || day < 1) {
        return "DAY";
      }
      let dateFormat = `${parseInt(year)}-${month}-${day}`;

      return dateFormat;
    },
    onUploadClick() {
      debugger;
      this.errorMessages = [];
      this.errorFromApi = "";
      if (this.tableData.length > 0) {
        this.btnLoading = true;
        this.$apollo
          .mutate({
            mutation: require("../api/importCustomer.graphql"),
            variables: {
              ImportCustomerSignUpInputs: {
                importInputs: this.importcustomerData,
              },
            },
          })
          .then((data) => {
            console.log(data);
            this.successAlert = true;
            this.$root.$emit("updateCustomers");
            this.btnLoading = false;
            this.showDialog = false;
          })
          .catch((error) => {
            this.btnLoading = false;
            let errorFromApi = JSON.parse(error.graphQLErrors[0].message);
            if (errorFromApi.message) {
              console.log("message");
              this.errorFromApi = `Error From API : ${errorFromApi.message}`;
              this.errorMessages = errorFromApi.data;
            } else {
              if (errorFromApi == "NO_DATA") {
                this.errorFromApi = "Some Error";
              }
            }
          });
        console.log(this.importcustomerData);
        this.btnLoading = false;
      } else {
        this.errorAlert = true;
      }
    },
  },
  data() {
    return {
      showErrorFromApi: false,
      errorFromApi: null,
      validInputs: null,
      totalInputs: null,
      errorsFromApi: [],
      importcustomerData: [],
      btnLoading: false,
      showErrorDialog: false,
      errorMessages: [],
      fileUpload: [],
      tableData: [],
      showDialog: false,
      successAlert: false,
      errorAlert: false,
      emailValidator,
      headers: [
        {
          text: "First Name En",
          value: "firstnameEn",
          width: 150,
          sortable: false,
        },

        {
          text: "First Name Ar",
          value: "firstnameAr",
          width: 150,
          sortable: false,
        },
        {
          text: "Last Name En",
          value: "lastnameEn",
          width: 150,
          sortable: false,
        },
        {
          text: "Last Name Ar",
          value: "lastnameAr",
          width: 150,
          sortable: false,
        },
        {
          text: "Email",
          value: "email",
          sortable: false,
        },
        {
          text: "Phone",
          value: "phone",
          sortable: false,
        },
        // {
        //   text: "Photo",
        //   value: "photo",
        //   sortable: false
        // },
        {
          text: "Dob",
          value: "dob",
          width: 150,
          sortable: false,
        },
        {
          text: "Gender",
          value: "gender",
          sortable: false,
        },
        {
          text: "How Found About App",
          value: "howFoundAboutApp",
          sortable: false,
        },
        {
          text: "Contact Method",
          value: "contactMethod",
          sortable: false,
        },
        {
          text: "Customer Goal",
          value: "customerGoal",
          sortable: false,
        },
        {
          text: "Comment",
          value: "comment",
          sortable: false,
        },
        // {
        //   text: "Marital Status",
        //   value: "martialStatus",
        //   sortable: false,
        // },
        // {
        //   text: "Pregnant Status",
        //   value: "pregnantStatus",
        //   sortable: false,
        // },
        // {
        //   text: "Activity",
        //   value: "activity",
        //   sortable: false,
        // },
        {
          text: "Health Issues",
          value: "healthIssues",
          sortable: false,
        },
        {
          text: "Referred By",
          value: "referredBy",
          sortable: false,
        },
        {
          text: "Area",
          value: "area",
          sortable: false,
        },
        {
          text: "Address Type",
          value: "addressType",
          sortable: false,
        },
        {
          text: "Block",
          value: "block",
          sortable: false,
        },
        {
          text: "Shift",
          value: "shift",
          sortable: false,
        },
        {
          text: "Street",
          value: "street",
          sortable: false,
        },
        {
          text: "Jedha",
          value: "jedha",
          sortable: false,
        },
        {
          text: "House Number",
          value: "house_number",
          sortable: false,
        },
        {
          text: "Building Number",
          value: "building_number",
          sortable: false,
        },
        {
          text: "Apartment Number",
          value: "apartment_number",
          sortable: false,
        },
        {
          text: "Comments",
          value: "comments",
          sortable: false,
        },
        // {
        //   text: "Map Location",
        //   value: "map_location",
        //   sortable: false,
        // },
      ],
    };
  },
});
</script>

<style scoped>
.vue-csv-mapping {
  display: none;
}
</style>
