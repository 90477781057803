var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasRole(_vm.AdminUserPermissions.DASHBOARD_VIEW))?_c('v-container',{staticClass:"pa-3",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"mt-5 px-xl-8",attrs:{"justify-space-around":"","wrap":""}},_vm._l((_vm.items),function(item,index){return _c('dashboard-main-card',{key:index,attrs:{"card-item":item,"index":index}})}),1),_c('v-layout',{staticClass:"ms-3",attrs:{"justify-start":""}},[_c('v-flex',{attrs:{"xs12":"","md6":"","lg2":""}},[_c('h3',{staticClass:"mt-5 ms-md-4 ms-lg-5 ms-xl-16 text-h5 text-xl-h4 font-weight-bold"},[_vm._v(" Statistics ")])])],1),_c('v-layout'),_c('v-layout',{staticClass:"mt-5 px-0",attrs:{"wrap":"","justify-md-space-around":"","justify-lg-start":""}},[_c('v-flex',{staticClass:"px-8",attrs:{"xs12":"","md6":"","lg6":""}},[_c('v-card',{staticClass:"pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto",attrs:{"max-width":_vm.$vuetify.breakpoint.xl
            ? 400
            : _vm.$vuetify.breakpoint.lg
            ? 600
            : _vm.$vuetify.breakpoint.md
            ? 390
            : _vm.$vuetify.breakpoint.sm
            ? 420
            : 340,"height":_vm.$vuetify.breakpoint.smAndUp ? 550 : 550,"tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"text-h6 ms-6 mt-5"},[_vm._v("Customer")])]),_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","menu-props":{ top: false, offsetY: true },"items":['Year', 'Month'],"dense":"","solo":"","label":"Type","rounded":""},model:{value:(_vm.customerReportType),callback:function ($$v) {_vm.customerReportType=$$v},expression:"customerReportType"}})],1),(_vm.customerReportType == 'Year')?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","menu-props":{ top: false, offsetY: true },"items":_vm.yearList,"item-text":"text","item-value":"value","dense":"","solo":"","rounded":"","label":"Year"},model:{value:(_vm.customerYear),callback:function ($$v) {_vm.customerYear=$$v},expression:"customerYear"}})],1):_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","rounded":"","menu-props":{ top: false, offsetY: true },"items":_vm.months,"item-text":"text","item-value":"value","dense":"","solo":"","label":"Month"},model:{value:(_vm.customerMonth),callback:function ($$v) {_vm.customerMonth=$$v},expression:"customerMonth"}})],1)],1),_c('CustomerGraph',{staticClass:"mt-lg-8",attrs:{"customerReportType":_vm.customerReportType,"customerYear":_vm.customerYear,"customerMonth":_vm.customerMonth}})],1)],1),_c('v-flex',{staticClass:"px-8",attrs:{"xs12":"","md6":"","lg6":""}},[_c('v-card',{staticClass:"pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto mt-8 mt-md-0",attrs:{"max-width":_vm.$vuetify.breakpoint.xl
            ? 400
            : _vm.$vuetify.breakpoint.lg
            ? 750
            : _vm.$vuetify.breakpoint.md
            ? 390
            : _vm.$vuetify.breakpoint.sm
            ? 420
            : 340,"height":_vm.$vuetify.breakpoint.smAndUp ? 550 : 550,"tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('label',{staticClass:"text-h6 ms-6 mt-5"},[_vm._v("Order Report")])]),_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","rounded":"","menu-props":{ top: false, offsetY: true },"items":['Year', 'Month'],"dense":"","solo":"","label":"Type"},model:{value:(_vm.orderReportType),callback:function ($$v) {_vm.orderReportType=$$v},expression:"orderReportType"}})],1),(_vm.orderReportType == 'Year')?_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","rounded":"","menu-props":{ top: false, offsetY: true },"items":_vm.yearList,"item-text":"text","item-value":"value","dense":"","solo":"","label":"Year"},model:{value:(_vm.orderYear),callback:function ($$v) {_vm.orderYear=$$v},expression:"orderYear"}})],1):_c('v-col',{attrs:{"cols":"6","lg":"4"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","rounded":"","menu-props":{ top: false, offsetY: true },"items":_vm.months,"item-text":"text","item-value":"value","dense":"","solo":"","label":"Month"},model:{value:(_vm.orderMonth),callback:function ($$v) {_vm.orderMonth=$$v},expression:"orderMonth"}})],1)],1),_c('OrderReport',{staticClass:"mt-lg-8",attrs:{"orderReportType":_vm.orderReportType,"orderYear":_vm.orderYear,"orderMonth":_vm.orderMonth}})],1)],1),_c('v-flex',{staticClass:"mt-8 mt-sm-10 mt-lg-8",attrs:{"xs12":"","md6":"","lg4":""}},[_c('v-card',{staticClass:"mx-auto pt-6",attrs:{"max-width":_vm.$vuetify.breakpoint.xl
            ? 400
            : _vm.$vuetify.breakpoint.lg
            ? 350
            : _vm.$vuetify.breakpoint.md
            ? 390
            : _vm.$vuetify.breakpoint.sm
            ? 420
            : 340,"height":_vm.$vuetify.breakpoint.mdAndUp ? 460 : '',"tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"5"}},[_c('label',{staticClass:"text-h6 ms-6"},[_vm._v("Packages")])]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-menu',{attrs:{"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","background-color":"#E2BC89","dense":"","rounded":"","value":_vm.packageDate,"append-icon":"fa-calendar-alt black--text","readonly":""}},'v-text-field',attrs,false),on))]}}],null,false,2953442633)},[_c('v-date-picker',{attrs:{"color":"primary"},model:{value:(_vm.packageDateModel),callback:function ($$v) {_vm.packageDateModel=$$v},expression:"packageDateModel"}})],1)],1)],1),_c('div',{staticClass:"pa-6 pa-sm-12 pa-md-10 pa-lg-8"},[_c('PackageReport',{staticClass:"mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n10 mb-n10 mb-md-0",attrs:{"date":_vm.packageDate}})],1)],1)],1),_c('v-flex',{staticClass:"mt-8 mt-sm-10 mt-lg-8",attrs:{"xs12":"","md6":"","lg4":""}},[_c('v-card',{staticClass:"pa-6 pa-sm-8 pa-md-10 pa-lg-6 mx-auto",attrs:{"max-width":_vm.$vuetify.breakpoint.xl
            ? 400
            : _vm.$vuetify.breakpoint.lg
            ? 350
            : _vm.$vuetify.breakpoint.md
            ? 390
            : _vm.$vuetify.breakpoint.sm
            ? 420
            : 340,"height":_vm.$vuetify.breakpoint.smAndUp ? 460 : 360,"tile":""}},[_c('label',{staticClass:"text-h6"},[_vm._v("Delivery Status")]),(!_vm.hasDeliveryStatusCountData)?_c('p',{staticClass:"mt-10 text-center"},[_vm._v(" No data available ")]):_c('DeliveryStatus',{on:{"hasData":_vm.setDeliveryStatusNoData}})],1)],1),_c('v-flex',{staticClass:"mt-8 mt-sm-10 mt-lg-8",attrs:{"xs12":"","md6":"","lg4":""}},[_c('v-card',{staticClass:"mx-auto pt-6",attrs:{"max-width":_vm.$vuetify.breakpoint.xl
            ? 400
            : _vm.$vuetify.breakpoint.lg
            ? 350
            : _vm.$vuetify.breakpoint.md
            ? 390
            : _vm.$vuetify.breakpoint.sm
            ? 420
            : 340,"height":_vm.$vuetify.breakpoint.lgAndUp ? 460 : '',"tile":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"7"}},[_c('label',{staticClass:"text-h6 ms-6 mt-5"},[_vm._v("Trending Meal")])]),_c('v-col',{attrs:{"cols":"5"}},[_c('v-select',{staticClass:"ma-0 pa-0 mr-3",attrs:{"background-color":"#E2BC89","rounded":"","solo":"","dense":"","menu-props":{ top: false, offsetY: true },"items":_vm.months,"item-text":"text","item-value":"value","label":"Month"},model:{value:(_vm.monthTrendingMeal),callback:function ($$v) {_vm.monthTrendingMeal=$$v},expression:"monthTrendingMeal"}})],1)],1),_c('div',{staticClass:"pa-6 pa-sm-12 pa-md-10 pa-lg-8"},[_c('TrendingMealGraph',{staticClass:"mt-n5 mt-sm-n10 mt-md-n12 mt-lg-n10 mb-n10 mb-md-0",attrs:{"month":_vm.monthTrendingMeal}})],1)],1)],1),_c('TodaysCustomerBirthday',{staticClass:"mt-5 mt-md-7 mt-lg-5"}),_c('DietitianCard',{staticClass:"mt-5 mt-md-7 mt-lg-5"})],1),_c('div',{staticClass:"mt-9 ps-md-4 px-2 px-sm-9 px-md-0 px-lg-7 px-xl-16"},[_c('v-card',{staticClass:"pt-5",attrs:{"color":"blue lighten-5"}},[_c('label',{staticClass:"text-h6 ms-6"},[_vm._v("Sales Trend")]),_c('SalesTrendGraph',{staticClass:"mt-md-3 px-4"})],1)],1),_c('v-layout',{staticClass:"mt-8 ps-md-4 px-sm-6 px-md-0 px-lg-4 px-xl-16",attrs:{"wrap":""}},[_c('MealRatingCard'),_c('StockAlert')],1),_c('v-layout',{staticClass:"mt-3 mt-sm-8 mb-5 px-3 px-sm-6 px-md-0 px-lg-7 px-xl-16",attrs:{"row":"","wrap":""}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }