var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[(_vm.packagesList.length > 0)?_c('h2',{staticClass:"primary--text text-h5 font-weight-bold ms-4"},[_vm._v(" Monthly Sales ")]):_vm._e(),_c('v-layout',{staticClass:"mt-5 mb-n5",attrs:{"wrap":""}},[_c('v-flex',{attrs:{"xs12":"","sm2":""}},[_c('v-select',{attrs:{"items":[2023, 2024],"label":"Year","solo":""},on:{"change":_vm.onYearChange},model:{value:(_vm.year),callback:function ($$v) {_vm.year=$$v},expression:"year"}})],1),_c('v-flex',{attrs:{"xs12":"","sm10":""}})],1),_c('v-data-table',{staticClass:"elevation-1 mt-10",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.packagesList,"dense":"","loading":_vm.loading,"options":_vm.options,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.packagesList.indexOf(item) + 1 + _vm.options.itemsPerPage * (_vm.options.page - 1))+" ")]}},{key:"item.user",fn:function(ref){
var item = ref.item;
return [_c('v-row',[_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
            name: 'CustomerDetails',
            params: { customerId: item._id },
            query: { customerId: item._id },
          }}},[(item.user)?_c('span',[_vm._v(" "+_vm._s((" " + (item.user.firstnameEn[0].toUpperCase()) + (item.user.firstnameEn .substring(1) .toLowerCase()) + " " + (item.user.lastnameEn[0].toUpperCase()) + (item.user.lastnameEn .substring(1) .toLowerCase()) + " ")))]):_c('span',[_vm._v(" Deleted User ")])])],1)]}},{key:"item.phone",fn:function(ref){
          var item = ref.item;
return [(item.user)?_c('v-row',[_vm._v(" "+_vm._s(item.user.phone)+" ")]):_vm._e()]}},{key:"item.january",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:0,attrs:{"item":_vm.getMonthlyData(item, 0)}})]}},{key:"item.february",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:1,attrs:{"item":_vm.getMonthlyData(item, 1)}})]}},{key:"item.march",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:2,attrs:{"item":_vm.getMonthlyData(item, 2)}})]}},{key:"item.april",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:3,attrs:{"item":_vm.getMonthlyData(item, 3)}})]}},{key:"item.may",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:4,attrs:{"item":_vm.getMonthlyData(item, 4)}})]}},{key:"item.june",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:5,attrs:{"item":_vm.getMonthlyData(item, 5)}})]}},{key:"item.july",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:6,attrs:{"item":_vm.getMonthlyData(item, 6)}})]}},{key:"item.august",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:7,attrs:{"item":_vm.getMonthlyData(item, 7)}})]}},{key:"item.september",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:8,attrs:{"item":_vm.getMonthlyData(item, 8)}})]}},{key:"item.october",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:9,attrs:{"item":_vm.getMonthlyData(item, 9)}})]}},{key:"item.november",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:10,attrs:{"item":_vm.getMonthlyData(item, 10)}})]}},{key:"item.december",fn:function(ref){
          var item = ref.item;
return [_c('PlanInfo',{key:11,attrs:{"item":_vm.getMonthlyData(item, 11)}})]}},{key:"item.totalPackage",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTotalPurchase(item))+" ")]}},{key:"item.totalAmount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithCommas(_vm.getTotalAmount(item)))+" KD ")]}}],null,true)},[_c('template',{slot:"body.append"},[_c('tr',{staticClass:"black--text"},[_c('th',[_c('strong',[_vm._v("Total ")])]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td',[_c('strong',[_vm._v(_vm._s(_vm.formatCurrency(_vm.totalAmount))+" ")])])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }