<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="550px">
      <template v-slot:activator="{ on }">
        <v-icon v-on="on" color="black" class="ms-2">mdi-square-edit-outline</v-icon>
      </template>
      <v-card class="px-4 py-6 py-sm-8" color="pop_bg">
        <v-row>
          <v-card-title class="black--text text-h6 mt-n2 ms-2">Edit Vendor</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>

        <v-card-text class="pa-0 pt-2">
          <v-container class="mt-2">
            <!-- <h3 class="ms-sm-8">Enter Contact Method Details</h3> -->
            <v-form @submit="editVendor()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text mb-3 mb-sm-0">
                    Company Name English
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="vendorDetails.companyNameEn" :rules="[requiredValidator('company')]" solo
                    placeholder="Company Name English" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text mb-3 mb-sm-0">
                    Company Name Arabic
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="vendorDetails.companyNameAr" :rules="[requiredValidator('company')]" solo
                    placeholder="Company Name Arabic" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="text-body-2 black--text mb-3 mb-sm-0">Phone</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="vendorDetails.phone" solo placeholder="Phone" dense :rules="[requiredValidator('phone'), minLengthValidator('phone', 8),
                  maxLengthValidator('phone', 8),]"></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Name
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="vendorDetails.contactPersonName" solo placeholder="Contact Person Name" dense>
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Phone
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-text-field v-model="vendorDetails.contactPersonPhone" solo type="number"
                    placeholder="Contact Person Phone" dense></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">
                    Contact Person Type
                  </h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select :items="contactPersonTypeList" v-model="vendorDetails.contactPersonType" solo
                    label="Select Type" dense attach></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Brands</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select :items="brandList" v-model="brands" item-text="nameEn" item-value="_id"
                    label="Select Brands" dense attach multiple solo></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Area</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select v-model="area" :items="areasList" item-text="nameEn" item-value="_id" solo
                    label="Select Area" @change="getBlocksByArea(area)" dense></v-select>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm5>
                  <h4 class="mb-3 mb-sm-0 text-body-2 black--text">Block</h4>
                </v-flex>
                <v-flex xs12 sm7 md7>
                  <v-select v-model="block" :items="blocksByArea" item-text="block" item-value="_id" solo label="Block"
                    dense></v-select>
                </v-flex>
              </v-layout>

              <v-layout row justify-end class="mt-3 me-1">
                <v-btn :width="$vuetify.breakpoint.smAndUp ? 120 : 90" color="primary"
                  class="white--text text-capitalize" @click="editVendor()" :loading="btnloading" :disabled="!valid">
                  Save</v-btn>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
        text
    }}</v-snackbar>
  </div>
</template>

<script>
import { requiredValidator, minLengthValidator, maxLengthValidator } from "../../core/methods/validations";
export default {
  props: {
    vendorDetails: {
      required: true,
    },
  },
  created() {
    this.getAllBrands();
    this.brands = this.vendorDetails.brands.map((x) => x._id);
    this.area = this.vendorDetails.address.area
      ? this.vendorDetails.address.area._id
      : "";
    this.block = this.vendorDetails.address.block
      ? this.vendorDetails.address.block._id
      : "";
    this.getAllAreas();
    this.getBlocksByArea(this.area);
  },
  data() {
    return {
      requiredValidator: requiredValidator,
      minLengthValidator,
      maxLengthValidator,
      btnloading: false,
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      show_dialog_box: false,
      brandList: [],
      brands: [],
      contactPersonTypeList: ["OWNER", "MANAGER", "SALESMAN"],
      areasList: [],
      blocksByArea: [],
      area: "",
      block: "",
    };
  },
  methods: {
    getAllBrands() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/stock/api/getAllBrands.graphql"),
        })

        .then((data) => {
          if (data.data) {
            console.log("dataaaa", data.data);

            this.brandList = data.data.listAllBrands;
          }
          console.log(this.brandList);
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
    getAllAreas() {
      this.loading = true;
      this.$apollo
        .query({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: "",
          },
        })
        .then((data) => {
          this.areasList = data.data.getAllAreas.areas;
        });
    },
    getBlocksByArea(area) {
      debugger;
      if (area) {
        this.$apollo
          .query({
            query: require("@/customer/api/getBlocksByArea.graphql"),
            variables: {
              id: area,
            },
          })
          .then((data) => {
            this.blocksByArea = data.data.getBlocksByArea.blocks;
          });
      }
    },
    editVendor() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/editVendor.graphql"),
          variables: {
            id: this.vendorDetails._id,
            companyNameEn: this.vendorDetails.companyNameEn,
            companyNameAr: this.vendorDetails.companyNameAr,
            phone: this.vendorDetails.phone,
            contactPersonName: this.vendorDetails.contactPersonName,
            contactPersonPhone: this.vendorDetails.contactPersonPhone,
            contactPersonType: this.vendorDetails.contactPersonType,
            brands: this.brands,
            area: this.area,
            block: this.block,
            jedha: this.vendorDetails.jedha,
            street: this.vendorDetails.street,
          },
        })
        .then(() => {
          this.$root.$emit("updateVendor");
          this.btnloading = false;
          this.show_dialog_box = false;
          this.snackbar = true;
          this.text = "Vendor Updated";
        })
        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
        });
    },
    close() {
      this.show_dialog_box = false;
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
