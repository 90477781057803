<template>
  <div>
    <v-dialog v-model="dialog" max-width="600px">
      <template v-slot:activator="{ on }">
        <v-btn small class="primary" v-on="on">Select Off Day</v-btn>
      </template>
      <v-card class=" pa-5 pb-10" color="pop_bg">
        <v-layout justify-end>
          <v-btn small color="black" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-layout>
        <v-card-title class="pa-0 ">
          <v-layout justify-center>
            <span class="text-h6 black--text">
              Off Days
            </span>
            <hr />
          </v-layout>
        </v-card-title>
        <v-card-text>
          <v-form @submit="updateDietitian()" v-model="valid" ref="form">
            <v-container class="mt-4">
              <v-layout wrap class="mb-4">
                <v-flex xs12 sm4 class=" mt-6 ">
                  <span>Select Off Day</span>
                </v-flex>
                <v-flex xs12 sm6 class=" ">
                  <v-menu
                    ref="menu1"
                    v-model="menu1"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        :value="formatDate"
                        hint="MM/DD/YYYY"
                        persistent-hint
                        v-bind="attrs"
                        v-on="on"
                        class="pt-0 pt-sm-4"
                        :rules="[dateValidator(), requiredValidator('date')]"
                        readonly
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      no-title
                      @change="changeDate"
                      @input="menu1 = false"
                      :min="currentDate"
                    ></v-date-picker>
                  </v-menu>
                </v-flex>
              </v-layout>
              <v-layout wrap class="">
                <v-flex xs12 sm4 class="mb-3 mb-sm-0 mt-3">
                  <span>Select shift</span>
                </v-flex>
                <v-flex xs12 sm6 class=" ">
                  <v-select
                    :items="shiftList"
                    :rules="[requiredValidator('shift')]"
                    v-model="shift"
                    item-text="text"
                    item-value="value"
                    dense
                    attach
                    solo
                  ></v-select>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions justify="center">
          <v-row justify="center">
            <v-btn color=" mx-2 secondary white--text" depressed @click="close"
              >Cancel</v-btn
            >
            <v-btn
              color="primary"
              depressed
              @click="updateDietitian()"
              :disabled="!valid"
              >Add</v-btn
            >
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">{{
      text
    }}</v-snackbar>
  </div>
</template>
<script>
import { requiredValidator } from "../../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT } from "../../../utils/utils";

export default {
  props: {
    OffDayList: {
      require: true
    },
    dietitian: {
      required: true
    }
  },
  data() {
    return {
      valid: true,
      dialog: false,
      show_alert: false,
      error: null,
      snackbar: false,
      text: "",
      date: "",
      menu1: "",
      shiftList: [
        { text: "Full Day", value: "FULL_DAY" },
        { text: "Morning Shift", value: "MORNING_SHIFT" },
        { text: "Evening Shift", value: "EVENING_SHIFT" }
      ],
      shift: null,
      requiredValidator,
      slotList: "",
      isBooked: false
    };
  },
  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
    currentDate() {
      let data = new Date();
      return moment(data).format("YYYY-MM-DD");
    }
  },
  methods: {
    changeDate() {
      this.getDietitianSlots();
    },
    getDietitianSlots() {
      this.$apollo
        .watchQuery({
          query: require("../api/getSlotDetails.graphql"),
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
          }
        })
        .refetch({
          variables: {
            dietitianId: this.dietitian._id,
            date: this.date
          }
        })
        .then(data => {
          if (data.data) {
            this.slotList = data.data.getSlotDetails;
          }
        })
        .catch(error => {
          console.error(error.graphQLErrors);
        });
    },
    dateValidator() {
      if (this.OffDayList.length && this.date) {
        let data = this.OffDayList.find(x => x.date == this.date);
        if (data) {
          return "Already used this date";
        }
      }
      if (this.date && this.shift) {
        let shift;
        this.shift == "MORNING_SHIFT"
          ? (shift = "Morning Shift")
          : this.shift == "EVENING_SHIFT"
          ? (shift = "Evening Shift")
          : (shift = null);
        if (this.slotList !== null) {
          let data = this.slotList.slots.filter(x => {
            if (shift) {
              return x.customer !== null && x.shift == shift;
            } else {
              return x.customer !== null;
            }
          });
          if (data.length) {
            return `Please Contact admin there is ${data.length} booking on this day`;
          }
          return true;
        }
        return true;
      }
      return true;
    },
    updateDietitian() {
      let data = {
        date: this.date,
        shift: this.shift
      };
      this.$emit("addOffDay", data);
      this.$refs.form.reset();
      this.dialog = false;
    },
    close() {
      this.error = null;
      this.dialog = false;
    }
  }
};
</script>
