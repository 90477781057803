<template>
  <div class="box">
    <v-layout justify-end>
      <ExportAccount :headers="headers" :accountType="'invoice'" />
    </v-layout>
    <v-data-table
      :headers="headers"
      :items="accountList"
      class="elevation-1 mt-2"
      :options.sync="options"
      :mobile-breakpoint="0"
      :items-per-page="25"
      :server-items-length="totalItemsCount"
      :loading="loading"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.siNo`]="{ item }">
        {{
          accountList
            .map(function(x) {
              return x._id;
            })
            .indexOf(item._id) +
            1 +
            options.itemsPerPage * (options.page - 1)
        }}
      </template>
      <template v-slot:[`item.customerName`]="{ item }">
        <router-link
          v-if="item.user"
          :to="{
            name: 'CustomerDetails',
            query: { customerId: item.customer }
          }"
          class="mx-auto"
        >
        {{ capitalizeFirstLetter(item.user.firstnameEn) }}
        {{ capitalizeFirstLetter(item.user.lastnameEn) }}
        </router-link>
      </template>
      <template v-slot:[`item.contact`]="{ item }">
        {{ item.user.phone }}
      </template>
      <template v-slot:[`item.date`]="{ item }">
        {{ dateFormat(new Date(parseInt(item.createdAt))) }}
      </template>
      <template v-slot:[`item.amount`]="{ item }">
        {{ numberWithCommas(item.amount) }}
      </template>
      <template v-slot:[`item.discount`]="{ item }">
        {{ numberWithCommas(item.discount) }}
      </template>
      <!-- <template v-slot:[`item.taxes`]="{ item }">
        {{ numberWithCommas(item.tax) }}
      </template> -->
      <template v-slot:[`item.total`]="{ item }">
        {{ numberWithCommas(item.total) }}
      </template>
      <template v-slot:[`item.action`]="{ item }">
        <InvoiceDownload :invoice="item" />
      </template>
    </v-data-table>
  </div>
</template>
<script>
import moment from "moment";
import InvoiceDownload from "../components/InvoiceDownload.vue";
import ExportAccount from "../components/ExportAccount.vue";
export default {
  components: {
    InvoiceDownload,
    ExportAccount
  },
  data: () => ({
    accountList: [],
    loading: true,
    headers: [
      {
        text: "Si No",
        value: "siNo",
        sortable: false
      },
      { text: "Customer Name", value: "customerName" },
      { text: "Contact", value: "contact", sortable: false },
      { text: "Date and Time", value: "date" },
      { text: "Amount", value: "amount" },
      { text: "Discount", value: "discount" },
      { text: "Total ", value: "total" },
      { text: "Action ", value: "action", sortable: false }
    ],
    totalItemsCount: 0,
    variables: {
      limit: 25,
      skip: 0
    },
    options: {
      itemsPerPage: 25,
      page: 1
    }
  }),
  watch: {
    options: {
      handler() {
        if (!this.loading) {
          this.variables.limit = this.options.itemsPerPage;
          this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage;
          (this.variables.sortDesc = this.options.sortDesc
            ? this.options.sortDesc[0]
            : ""),
            (this.variables.sortBy = this.options.sortBy
              ? this.options.sortBy[0]
              : ""),
            this.getAllInvoiceTransaction();
        }
      },
      deep: true
    }
  },
  created() {
    this.getAllInvoiceTransaction();
  },
  methods: {
    getAllInvoiceTransaction() {
      this.$apollo
        .query({
          query: require("../api/getAllInvoiceTransaction.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data.getAllInvoiceTransactions) {
            this.accountList =
              data.data.getAllInvoiceTransactions.transactionList;
            this.totalItemsCount =
              data.data.getAllInvoiceTransactions.totalCount;
          }
          this.loading = false;
        });
    },
    dateFormat(date) {
      return moment(date, "MM-DD-YYYY").format("DD-MM-YYYY, h:mm:ss A");
    }
  }
};
</script>
<style>
.box {
  background-color: #FFFFFF;
}
</style>
