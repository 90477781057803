<template>
  <div>

    <v-card class="ma-0 pa-3 pa-sm-6" color="pop_bg">
      <v-card-text>
        <v-row>
          <h3 class="black--text text-h5 text-sm-h6 ms-2 mb-4">
            Customers Using Meal
          </h3>
          <!-- <v-spacer></v-spacer> -->
          <!-- <v-btn color="black" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn> -->
        </v-row>
        <v-divider></v-divider>

        <v-layout class="mt-6 mt-md-4 ms-md-n4" wrap>
          <v-flex xs12 sm12 md3 class="mt-sm-n5 mt-md-0 ms-sm-5 me-5">
            <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
              <template v-slot:activator="{ on, attrs }">
                <v-text-field :value="formatDate" label="Date" readonly solo append-icon="mdi-calendar-month-outline"
                  v-bind="attrs" v-on="on" dense></v-text-field>
              </template>

              <v-date-picker v-model="date" @change="menu = false"></v-date-picker>
            </v-menu>
          </v-flex>

          <v-flex xs12 sm4 md2 lg2 class="mt-md-0 ms-sm-5 ms-md-0 mb-8 mb-sm-0">
            <v-btn class="ms-sm-5 px-6 white--text text-capitalize" color="primary" @click="searchMealCustomers">
              Search
            </v-btn>
          </v-flex>
        </v-layout>

        <v-data-table :mobile-breakpoint="0" :headers="headers" :items="customersUsingMeal" :options.sync="options"
          :loading="loading" :server-items-length="totalCount"
          :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
          <template v-slot:item.customerName="{ item }">
            {{
                item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
            }}
            {{
                item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
            }}
          </template>
          <template v-slot:item.date="{ item }">
            {{
                new Date(parseInt(item.date)) | moment("DD MMM YYYY")
            }}</template>
          <!-- <template v-slot:item.shift="{ item }">
              <span v-if="item.shift">{{ item.shift }}</span>
              <span v-else>--</span>
            </template>
            <template v-slot:item.endDate="{ item }">
              {{
                new Date(parseInt(item.subscribed_packages.end_date))
                  | moment("DD MMM YYYY")
              }}</template
            > -->
        </v-data-table>
      </v-card-text>
    </v-card>

  </div>
</template>
<script>
import moment from "moment";
import { format, parseISO } from "date-fns";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  props: {
    menuItemInfo: {
      required: true
    }
  },

  computed: {
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    }
  },
  watch: {
    options: {
      handler() {
        this.getAllCustomersUsingMealByDate();
        // this.tableIndex =
        //   this.options.page * this.options.itemsPerPage -
        //   this.options.itemsPerPage;
      }
    }
  },
  data() {
    return {
      menu: false,
      date: format(parseISO(new Date().toISOString()), "yyyy-MM-dd"),
      valid: true,
      onShowDialog: false,
      loading: true,
      button_loading: false,
      error: null,
      options: {
        itemsPerPage: 25,
        page: 1
      },
      totalCount: 25,
      customersUsingMeal: [],
      headers: [
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false
        },

        {
          text: "Phone",
          value: "user.phone",
          width: 110,
          sortable: false
        },
        {
          text: "Date",
          value: "date",
          width: 110,
          sortable: false
        }
      ]
    };
  },
  methods: {
    onShowClick() {
      this.onShowDialog = true;
      this.getAllCustomersUsingMealByDate();
    },
    searchMealCustomers() {
      this.getAllCustomersUsingMealByDate();
    },
    getAllCustomersUsingMealByDate() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllCustomersUsingMealByDate.graphql"),
          variables: {
            mealId: this.menuItemInfo._id,
            date: this.date,
            limit: 25,
            skip: 0
          }
        })
        .refetch({
          mealId: this.menuItemInfo._id,
          date: this.date,
          limit:
            this.options.itemsPerPage !== -1 ? this.options.itemsPerPage : 200,
          skip:
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage
        })
        .then(data => {
          this.customersUsingMeal = [];
          this.loading = false;
          this.totalCount = 25;
          if (data.data.getAllCustomersUsingMealByDate) {
            this.customersUsingMeal =
              data.data.getAllCustomersUsingMealByDate.customers;
            this.totalCount =
              data.data.getAllCustomersUsingMealByDate.totalCount;
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },
    close() {
      // this.error = null;
      this.onShowDialog = false;
      // this.$emit("updateStatusDialogBox", this.menuItem, false);
    }
  }
};
</script>
