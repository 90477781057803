<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          class="white--text text-capitalize mt-1 mb-5"
          color="secondary"
          v-on="on"
          @click="dialogOpen"
        >
          <v-icon class="me-1" small>mdi-plus</v-icon>Create Plan Switch
        </v-btn>
      </template>

      <v-spacer></v-spacer>
      <v-card class="pa-6" color="pop_bg">
        <v-row>
          <v-card-title class="text-h6 black--text mt-n2">Add Plan Switch</v-card-title>
          <v-spacer></v-spacer>
          <v-btn color="black" @click="close" text>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider class="mt-n2 mb-6"></v-divider>
        <v-container>
          <v-form @submit="createSwitchPackage()" v-model="valid" ref="form">
            <v-layout wrap>
              <v-flex xs12 sm6 class="pa-2">
                <v-card>
                  <v-container>
                    <p class="black--text text-center">From</p>
                    <v-divider class="mt-n2 mb-6"></v-divider>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Package</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          @change="fromPackageChange"
                          v-model="packageFrom"
                          :items="packagesList"
                          :rules="[requiredValidator('Package'), packageValidation]"
                          item-value="_id"
                          item-text="titleEn"
                          label="Plan"
                          dense
                          attach
                          solo
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Choice</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          @change="fromChoiceChange"
                          :items="fromChoiceList.availableChoices"
                          :rules="[requiredValidator('Choice')]"
                          v-model="choiceFrom"
                          item-text="choiceNameEn"
                          item-value="_id"
                          label="Choice"
                          dense
                          attach
                          solo
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">No of Days</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-text-field
                          v-model="fromVariantList.availableNumOfDays"
                          attach
                          solo
                          dense
                          type="number"
                          min="0"
                          readonly
                        >
                          <template v-slot:append>
                            <h4 class="mt-1 grey--text">Days</h4>
                          </template>
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Variant</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          :items="fromVariantList.mealChoice"
                          :rules="[requiredValidator('Variant')]"
                          v-model="variantFrom"
                          item-text="variantNameEn"
                          item-value="_id"
                          dense
                          label="Variant"
                          attach
                          solo
                          @change="fromVariantChange"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
              <v-flex xs12 sm6 class="pa-2">
                <v-card>
                  <v-container>
                    <p class="black--text text-center">To</p>
                    <v-divider class="mt-n2 mb-6"></v-divider>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Package</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          @change="toPackageChange"
                          v-model="packageTo"
                          :items="packagesList"
                          :rules="[requiredValidator('Package'), packageValidation]"
                          item-value="_id"
                          item-text="titleEn"
                          chips
                          label="Plan"
                          dense
                          attach
                          solo
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Choice</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          @change="toChoiceChange"
                          :items="toChoiceList.availableChoices"
                          :rules="[requiredValidator('Choice')]"
                          v-model="choiceTo"
                          item-text="choiceNameEn"
                          item-value="_id"
                          label="Choices"
                          dense
                          attach
                          solo
                        ></v-select>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">No of Days</h4>
                      </v-flex>
                      <!--  :rules="[sameNoOfDays]" -->
                      <v-flex xs12 sm8 md9>
                        <v-text-field
                          v-model="toVariantList.availableNumOfDays"
                          attach
                          solo
                          dense
                          type="number"
                          min="0"
                          readonly
                        >
                          <template v-slot:append>
                            <h4 class="mt-1 grey--text">Days</h4>
                          </template>
                        </v-text-field>
                      </v-flex>
                    </v-layout>
                    <v-layout wrap>
                      <v-flex xs12 sm4 md3 class="mt-n4 mt-sm-2">
                        <h4 class="text-body-2 black--text">Variant</h4>
                      </v-flex>
                      <v-flex xs12 sm8 md9>
                        <v-select
                          :items="toVariantList.mealChoice"
                          :rules="[requiredValidator('Variant')]"
                          v-model="variantTo"
                          item-text="variantNameEn"
                          item-value="_id"
                          dense
                          label="Variant"
                          attach
                          solo
                          @change="toVariantChange"
                        ></v-select>
                      </v-flex>
                    </v-layout>
                  </v-container>
                </v-card>
              </v-flex>
            </v-layout>
            <v-layout class="mt-4 mr-2" justify-end>
              <v-btn
                width="120"
                color="secondary"
                class="text-capitalize white--text"
                @click="createSwitchPackage()"
                :loading="btnloading"
                :disabled="!valid"
              >
                <v-icon left>mdi-plus</v-icon>Add</v-btn
              >
            </v-layout>
          </v-form>
          <TempPlanSwitchTable
            :switchPakage="switchPakage"
            @updatePlanSwitch="updatePlanSwitch"
          />
        </v-container>
        <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackColor">{{
          text
        }}</v-snackbar>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { requiredValidator } from "../../core/methods/validations";
import TempPlanSwitchTable from "./TempPlanSwtichTable.vue";
export default {
  components: {
    TempPlanSwitchTable,
  },
  props: {},
  created() {
    this.getAllPackages();
  },
  data() {
    return {
      btnDisabled: false,
      requiredValidator: requiredValidator,
      show_dialog_box: false,
      btnloading: false,
      valid: true,
      errors: [],
      snackColor: "",
      snackbar: false,
      text: "",
      variables: {
        limit: 0,
        skip: 0,
      },
      packageTo: "",
      choiceTo: "",
      variantTo: "",
      packageFrom: "",
      choiceFrom: "",
      variantFrom: "",
      packagesList: [],
      fromChoiceList: [],
      toChoiceList: [],
      fromVariantList: "",
      toVariantList: "",
      switchPackageList: [],
      switchPakage: {},
      toVariantDetails: {},
      fromVariantDetails: {},
    };
  },
  // apollo: {
  //   getAllPackages() {
  //     return {
  //       query: require("@/menu-packages/api/getAllPackagesWithChoice.graphql"),
  //       variables: this.variables,
  //       result({ data, loading }) {
  //         this.loading = loading;
  //         this.packagesList = data.getAllPackages.packages.filter(
  //           x => x.active
  //         );
  //       }
  //     };
  //   }
  // },
  methods: {
    getAllPackages() {
      this.$apollo
        .watchQuery({
          query: require("@/menu-packages/api/getAllPackagesWithChoice.graphql"),
          variables: this.variables,
        })
        .refetch({})
        .then((data) => {
          if (data.data) {
            this.packagesList = data.data.getAllPackages.packages;
          }
        });
    },
    updatePlanSwitch() {
      this.show_dialog_box = false;
      this.$emit("updateplanSwitch");
    },
    sameNoOfDays() {
      if (this.toVariantList && this.fromVariantList) {
        if (
          this.toVariantList.availableNumOfDays == this.fromVariantList.availableNumOfDays
        ) {
          return true;
        }
        return "No of days should be same";
      }
      return true;
    },
    packageValidation() {
      if (this.packageTo && this.packageFrom) {
        if (this.packageTo.toString() == this.packageFrom.toString()) {
          return "Same Package cant switch";
        }
        return true;
      }
      return true;
    },
    toPackageChange() {
      this.choiceTo = "";
      this.variantTo = "";
      this.toChoiceList = this.packagesList.find((x) => x._id == this.packageTo);
    },
    toChoiceChange() {
      this.variantTo = "";
      this.toVariantList = this.toChoiceList.availableChoices.find(
        (x) => x._id == this.choiceTo
      );
    },
    fromChoiceChange() {
      this.variantFrom = "";
      this.fromVariantList = this.fromChoiceList.availableChoices.find(
        (x) => x._id == this.choiceFrom
      );
    },
    fromPackageChange() {
      this.choiceFrom = "";
      this.variantFrom = "";
      this.fromChoiceList = this.packagesList.find((x) => x._id == this.packageFrom);
    },
    toVariantChange() {
      this.toVariantDetails = this.toVariantList.mealChoice.find(
        (x) => x._id == this.variantTo
      );
    },
    fromVariantChange() {
      this.fromVariantDetails = this.fromVariantList.mealChoice.find(
        (x) => x._id == this.variantFrom
      );
    },
    close() {
      this.show_dialog_box = false;
    },
    dialogOpen() {
      this.show_dialog_box = false;
    },
    createSwitchPackage() {
      let switchPakage = {
        to: {
          package: { id: "", name: "" },
          choice: { id: "", name: "" },
          variant: { id: "", name: "" },
        },
        from: {
          package: { id: "", name: "" },
          choice: { id: "", name: "" },
          variant: { id: "", name: "" },
        },
      };
      if (
        this.toVariantList.availableNumOfDays !== this.fromVariantList.availableNumOfDays
      ) {
        this.text = "No of Days of Packages should be same";
        this.snackColor = "red";
        this.snackbar = true;
        return;
      }
      switchPakage.to.package.id = this.packageTo;
      switchPakage.to.package.name = this.toChoiceList.titleEn;
      switchPakage.to.choice.id = this.choiceTo;
      switchPakage.to.choice.name = this.toVariantList.choiceNameEn;
      switchPakage.to.variant.id = this.variantTo;
      switchPakage.to.variant.name = this.toVariantDetails.variantNameEn;
      switchPakage.from.package.id = this.packageFrom;
      switchPakage.from.package.name = this.fromChoiceList.titleEn;
      switchPakage.from.choice.id = this.choiceFrom;
      switchPakage.from.choice.name = this.fromVariantList.choiceNameEn;
      switchPakage.from.variant.id = this.variantFrom;
      switchPakage.from.variant.name = this.fromVariantDetails.variantNameEn;
      this.switchPakage = switchPakage;
      this.packageTo = "";
      this.toChoiceList = {};
      this.toVariantList = {};
      this.packageFrom = "";
      this.fromChoiceList = {};
      this.fromVariantList = {};
    },
  },
  computed: {},
};
</script>
