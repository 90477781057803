<template>
  <v-container>
    <ApexChart v-if="chartOptions" type="pie" :options="chartOptions" :series="series">
    </ApexChart>
  </v-container>
</template>
<script>
import ApexChart from "vue-apexcharts";
export default {
  components: {
    ApexChart,
  },
  props: {
    date: {
      required: true,
    },
  },
  data() {
    return {
      series: null,
      chartOptions: null,
    };
  },
  created() {
    this.getPackageCount();
  },
  watch: {
    date: {
      handler() {
        this.getPackageCount();
      },
      deep: true,
    },
  },
  methods: {
    getPackageCount() {
      this.$apollo
        .watchQuery({
          query: require("../api/getPackageCount.graphql"),
          variables: {
            date: this.date,
          },
        })
        .refetch({
          variables: {
            date: this.date,
          },
        })
        .then((data) => {
          let packageList = data.data.getPackageCount;
          let graphLabels = new Array();
          let graphCount = new Array();
          packageList.forEach((element) => {
            graphLabels.push(element.package);
            graphCount.push(parseInt(element.count));
          });

          this.series = graphCount;
          this.chartOptions = {
            chart: {
              type: "pie",
            },
            labels: graphLabels,
            legend: {
              show: false,
            },
            responsive: [
              {
                breakpoint: 200,
                options: {
                  chart: {
                    width: 200,
                  },
                  legend: {
                    show: false,
                  },
                },
              },
            ],
          };
        });
    },
  },
};
</script>
