<template>
  <div v-if="areaList != null" class="mt-n10">
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="areaList"
      class="elevation-1 mt-10"
      :loading="loading"
    >
      <template #item.name="{ item }"
        >{{ item.nameEn }} {{ item.nameAr }}</template
      >
      <template v-slot:item.active="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>
          <AreaUpdateForm v-if="hasRole(AdminUserPermissions.LOCATION_EDIT)"  :areaInfo="item" />
          <AreaDelete v-if="hasRole(AdminUserPermissions.LOCATION_DELETE)" :area="item" />
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import AreaUpdateForm from "../components/AreaUpdateForm.vue";
import AreaDelete from "../components/AreaDelete.vue";
export default {
  components: {
    AreaUpdateForm,
    AreaDelete,
  },
  props: {
    areaList: {
      default: [],
    },
    totalItemsCount: {
      default: 25,
    },
    loading: {
      default: true,
    },
  },
  data: () => ({
    dialog: false,
    headers: [
      {
        text: "Area En",
        value: "nameEn",
        sortable: false,
      },
      { text: "Area Ar", value: "nameAr", sortable: false },
      // { text: "Price", value: "price" },
      //  { text: "Days", value: "availableWeekdays" },
      { text: "Action", value: "actions", sortable: false },
    ],
  }),

  methods: {
    // ItemsByCategory(categoryType) {
    //   let listBycategory = this.menuList;
    //   // console.log(categoryType);
    //   if (categoryType !== "") {
    //     console.log("hidoid");
    //     listBycategory = this.menuList.filter(function (elt) {
    //       return elt.category.nameEn === categoryType;
    //     });
    //   }
    //   return listBycategory;
    // },
    // editItem() {
    //   this.dialog = true;
    // },
    // deleteItem() {},
    // close() {
    //   this.dialog = false;
    // },
  },
};
</script>

