<template>
  <div>
    <v-btn
      @click="startDeliveryExport"
      :width="$vuetify.breakpoint.xs ? 115 : 150"
      color="primary"
      class="white--text text-capitalize text-end me-2 me-sm-3"
      :small="$vuetify.breakpoint.xs"
    >
      <v-icon :small="$vuetify.breakpoint.xs" class="me-1"
        >mdi-file-document</v-icon
      >Meal Sticker
    </v-btn>
    <v-snackbar v-model="exportStarted" timeout="6000" right top color="success">Export Started
      <router-link :to="{ name: 'ReportStatus' }">click here to view report status</router-link>
    </v-snackbar>
  </div>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import { tz } from "moment-timezone";
// import { TIME_ZONE } from "../../utils/utils";
import { print_meal_sticker } from "../methods/print_meal_sticker";

export default Vue.extend({
  props: {
    reportList: { require: true },
    disabled: { require: true },
    deliveryDate: { require: true },
    reportFilter: { require: true }
  },
  created() {
    let formatted = new Date(this.deliveryDate);
    let expDate = moment(formatted).add(2, "days");
    this.expDate = expDate;
    if (this.reportList) this.getMealRawData();
  },
  watch: {
    deliveryDate: {
      handler(newVal) {
        let formatted = new Date(newVal);
        let expDate = moment(formatted).add(2, "days");
        this.expDate = expDate;
        if (this.reportList) this.getMealRawData();
      },
      deep: true
    },
    reportList: {
      handler() {
        this.getMealRawData();
      },
      deep: true
    }
  },

  data() {
    return {
      menu: false,
      exportStarted:false,
      tz: tz,
      loading: false,
      expDate: new Date(),
      btnloading: false,
      meanInfos: [],
      mealStickers: []
    };
  },

  methods: {
    getMealRawData() {
      let data = this.reportList;
      let mealStickers = new Array();
      if (data) {
        data.forEach(item => {
          try {
            if (item.isParent == false) {
              item.meanInfo.forEach(meanInfo => {
                for (let i = 1; i <= meanInfo.count; i++) {
                  try {
                    let sticker = {
                      itemName: item.itemName,
                      dislikes: this.getDislikes(item.dislikedIngredients),
                      orderNumber: meanInfo.orderNumber[i - 1]
                        ? this.padLeft(meanInfo.orderNumber[i - 1], 4)
                        : "NO_ORDER",
                      protein: meanInfo.protein,
                      carbs: meanInfo.carbs,
                      categoryName: item.menuCategory.nameEn,
                      pcValueNotRequired: item.menuCategory.pcValueNotRequired,
                      customerName: meanInfo.customerInfo[i - 1]
                        ? meanInfo.customerInfo[i - 1].customerName
                        : "NO_NAME",
                      prd: this.getDate(this.deliveryDate)
                    };
                    mealStickers.push(sticker);
                  } catch (error) {
                    //  debugger;
                    console.log(error);
                  }
                }
              });
            }
          } catch (error) {
            //  debugger;
            console.log(error);
          }
        });
        this.mealStickers = mealStickers.sort((a, b) => {
          let fa = a.itemName.toLowerCase(),
            fb = b.itemName.toLowerCase();

          if (fa < fb) {
            return -1;
          }
          if (fa > fb) {
            return 1;
          }
          return 0;
        });
      } else {
        this.mealStickers = [];
      }
    },
    getDate(date) {
      let converted = moment(date); //.subtract(1, "days");
      return converted;
    },

    getDislikes(dislikes) {
      let dislikeNames = new Array();
      dislikes.forEach(element => {
        dislikeNames.push(`No ${element.nameEn}`);
      });
      let joined = dislikeNames.join(",");
      let response = "";
      if (dislikes.length > 0) {
        response = `( ${joined} )`;
      }
      return response;
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    printSticker() {
      print_meal_sticker(this.mealStickers);
    },
     startDeliveryExport() {
      this.exportStarted = true
      const dateTime = moment(`${this.deliveryDate}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      this.$apollo
        .watchQuery({
          query: require("@/delivery/api/excelDeliveryExportReport.graphql"),
          variables: {
            status: "",
            date: modifiedDate,
            type: this.reportFilter,
            shift: ""
          }
        })
        .refetch({
          variables: {
            status: "",
            date: modifiedDate,
            type: this.reportFilter,
            shift: ""
          }
        })
        .then(() => {
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    },
  }
});
</script>

<style scoped>
.border {
  border: solid 0.1mm black;
  height: 9.525mm;
  width: 14.8mm;
  margin-bottom: 2px;
  font-family: sans-serif;
}
.section {
  position: absolute;
  background-color: black;
  -webkit-print-color-adjust: exact;
  color: white;
  height: 4.5mm;
  width: 14.378mm;

  /* padding: 10; */
}
.section2 {
  margin-top: 4.5mm;
  position: absolute;
  background-color: white;
  -webkit-print-color-adjust: exact;
  color: black;
  height: 4.5mm;
  width: 14.21mm;
}

#item_name {
  margin-top: 1px;
  font-size: 2.2pt;
  text-align: center;
}
.id_item {
  font-size: 2pt;
  text-align: center;
  margin-top: 0.5px;
  overflow: hidden;
  white-space: nowrap;
}
#dis_item {
  margin-top: 0.5px;
  font-size: 2pt;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
}
ul {
  margin: 0;
  padding: 0;
  overflow: hidden;
  color: white !important;
  font-size: 2pt;
  list-style-type: none;
}

li {
  color: black !important;
  float: left;
}
.list_main {
  margin-left: 7px;
  margin-top: 2px;
}
.customer_address {
  text-align: center;
  margin-top: 0.2px;
  font-size: 1.9pt;
  overflow: hidden;
  white-space: nowrap;
}

.list {
  margin-left: 5px;
}

#category {
  font-size: 2.2pt;
  text-align: center;
  font-weight: 600;
  overflow: hidden;
  white-space: nowrap;
}
.menuCategory {
  margin-top: -0.8pt;
  margin-left: 2px;
}
.sep {
  margin-top: 0px;
}

.date {
  font-size: 1.8pt;
  text-align: center;
}

.logo {
  text-align: center;
  position: sticky;
  margin-top: 2.2px;
  bottom: 100%;
}

.print-break-page {
  clear: both;
  page-break-after: always;
}
</style>
