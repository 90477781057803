<template>
  <v-container class="px-0 mx-0">
    <v-btn
      outlined
      @click="exportExcel"
      width="150"
      class="mt-n3 text-capitalize"
      color="primary"
    >
      <v-icon small class="me-2">fa-file-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="exportUnit"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="unitList"
        class="elevation-1 mt-6"
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    unitList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
    headers: [
      { text: "Si No", value: "id", width: 100, sortable: false },
      { text: "Unit Name", value: "name", sortable: false },
      { text: "Conversion Unit", value: "conversionUnit", sortable: false },
      { text: "Conversion Scale", value: "conversionValue", sortable: false },
      
    ],
    };
  },

  created() {},

  methods: {
    getAllUnits() {
      this.loading = true;
      if(this.unitList){
        this.unitList = this.unitList.map((d, index) => ({
                ...d,
                id: index + 1
              }));
      }
      setTimeout(() => {
        this.loading = false;
        Utils.htmlToExcel("exportUnit", "Units", false);
      }, 1000);
    },

    exportExcel() {
      this.getAllUnits();
    }
  }
};
</script>
