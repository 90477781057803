<template>
  <div>
    <!-- {{ faqList }} -->
    <v-data-table disable-sort :mobile-breakpoint="0" :headers="headers" :items="itemsWithSno" class="elevation-1"
      :items-per-page="25" :options.sync="options" :loading="loading" :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }">
      <template v-slot:item.active="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="primary">mdi-checkbox-marked</v-icon>
        <v-icon v-show="!item.active" color="grey">mdi-checkbox-blank-outline</v-icon>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-row>

          <BrandUpdateForm :brand="item" />
          <BrandDelete :brand="item" />
        </v-row>
      </template>
    </v-data-table>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false"> Close </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import BrandDelete from "../components/BrandDelete.vue";
import BrandUpdateForm from "../components/BrandUpdateForm.vue";
export default {
  components: { BrandDelete, BrandUpdateForm },
  props: {
    brandList: {
      required: true,
    },
    totalItemsCount: {
      default: 25,
    },
    loading: {
      default: true,
    },
  },
  computed: {
    itemsWithSno() {
      if (this.brandList == null) return [];
      return this.brandList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
  data() {
    return {
      variables: {
        limit: 25,
        skip: 0,
      },
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      headers: [
        {
          text: "#",
          value: "slNo",
          width: 80
        },
        { text: "Name (English)", value: "nameEn", width: 120 },
        { text: "Name (Arabic)", value: "nameAr", width: 120 },
        { text: "Description (English)", value: "descriptionEn", width: 120 },
        { text: "", value: "actions", sortable: false, width: 75 },
      ],
      snack: false,
      snackColor: "",
      snackText: "",
    };
  },
};
</script>