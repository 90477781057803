<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class=" white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportArea"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="areaList"
        class="elevation-1 "
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    areaList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      headers: [ 
        {
          text: "Area En",
          value: "nameEn",
          sortable: false
        },
        { text: "Area Ar", value: "nameAr", sortable: false }
      ],
      blockHeaders: [
        {
          text: "Area",
          value: "area.nameEn"
        },
        { text: "Block", value: "block" }
      ]
    };
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportArea", "Area", false);

      this.loading = false;
    }
  }
};
</script>
