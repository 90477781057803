<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
     <v-btn
        @click="exportExcel"
        width="180"   
        :loading="loading"
        class=" white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportZone"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="zoneList"
        class="elevation-1 "
      >
       <template v-slot:[`item.areaDetails`]="{ item }">
        <div v-for="(areaItem, index) in item.areaDetails" :key="index">
          {{ areaItem.area.nameEn }}
          <span  v-if="areaItem.block">
          <span v-if="areaItem.block.length > 0">
            ({{ areaItem.block.map((x) => x.block).join(", ") }})
          </span>
          </span>
        </div>
      </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    zoneList: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
       headers: [
      {
        text: "Zone",
        value: "zoneName",
      },
      { text: "Area", value: "areaDetails" }
    ],
    };
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportZone", "Zone", false);

      this.loading = false;
    }
  }
};
</script>
