<template>
  <v-container grid-list-xs>
    <div v-if="dietitianList.length > 0" class="mt-4">
      <v-virtual-scroll
        height="300"
        item-height="64"
        :bench="benched"
        :items="dietitianList"
        class="mt-4"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item._id">
            <v-list-item-action>
              <v-avatar :width="80" :height="80">
                <v-img
                  :src="require('../../assets/user.jpg')"
                  contain
                  max-width="50"
                  max-height="50"
                  position="left"
                />
              </v-avatar>
            </v-list-item-action>
            <v-row>
              <v-col cols="9" sm="8" lg="9">
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ item.user.firstnameEn }}
                    {{ item.user.lastnameEn }}
                  </v-list-item-title>
                  <!-- <v-list-item-title class="text-body-2 mt-1">
                    Consultant
                  </v-list-item-title> -->
                  <v-list-item-title class="mt-1">
                    <!-- <v-row>
                      <v-col cols="12" sm="1">
                        <v-btn
                          x-small
                          outlined
                          text
                          light
                          class="black--text custom_btn"
                        >
                          5.0
                        </v-btn>
                      </v-col>
                     <v-col cols="12" sm="10" class="mt-n6 mt-sm-n1 ms-sm-3">
                        <v-rating
                          :small="$vuetify.breakpoint.smAndUp"
                          :x-small="$vuetify.breakpoint.xs"
                          length="5"
                          size="64"
                          color="primary"
                          :readonly="true"
                        ></v-rating
                      ></v-col> 
                    </v-row> -->
                  </v-list-item-title>
                </v-list-item-content>
              </v-col>
              <v-col cols="3" sm="4" lg="3">
                <div class="mt-8 ms-sm-4">
                  <v-btn color="#FFEF66" depressed :small="$vuetify.breakpoint.xs">
                    <v-icon color="black">mdi-account-multiple</v-icon>
                  </v-btn>
                  <label class="text-body-1 text-sm-h6 font-weight-medium ms-1">
                    {{ item.appointmentCount }}</label
                  >
                </div>
              </v-col>
            </v-row>
          </v-list-item>
          <!-- <v-divider></v-divider> -->
        </template>
      </v-virtual-scroll>

      <!-- </template> -->
      <!-- </v-virtual-scroll> -->
    </div>
    <div v-else>
      <v-list-item-title class="text-center pb-5 mt-5"
        >******No Dietitians******</v-list-item-title
      >
    </div>
  </v-container>
</template>
<script>
export default {
  methods: {
    getAllDietitians() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("@/dietitian/api/getAllDietitians.graphql"),
          variables: {
            limit: 5000,
            skip: 0,
          },
        })
        .refetch({})
        .then((data) => {
          this.loading = false;
          if (data.data.getAllDietitians) {
            this.dietitianList = data.data.getAllDietitians.dietitians.filter(
              (x) => x.isActive == true
            );
          }
        })
        .catch((error) => {
          this.loading = false;
          console.log(error);
        });
    },
  },
  created() {
    this.getAllDietitians();
  },
  data() {
    return {
      imageUrl: process.env.VUE_APP_BASE_URL,
      customerList: [],
      dietitianList: [],
      variables: {
        limit: 10,
        skip: 0,
      },
      benched: 0,
      loading: true,
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px",
      },
    };
  },
};
</script>
<style>
.custom_btn {
  border: 1px solid #ffe500 !important;
}
</style>
