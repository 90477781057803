var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"pa-4 pa-sm-8",attrs:{"fluid":""}},[_c('v-layout',{staticClass:"d-flex",attrs:{"wrap":""}},[_c('v-flex',[_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" Birthday Report ")])]),_c('v-flex',{attrs:{"xs12":"","sm8":"","md9":"","lg4":""}},[_c('v-select',{attrs:{"items":_vm.reportTypes,"item-text":"text","item-value":"value","dense":"","attach":"","solo":""},on:{"change":_vm.onTypeChange},model:{value:(_vm.reportType),callback:function ($$v) {_vm.reportType=$$v},expression:"reportType"}})],1)],1),_c('div',{staticClass:"d-flex mt-4 mt-sm-0"},[_c('ExportBirthdayReport',{staticClass:"ml-3",attrs:{"dob":_vm.fDate,"type":_vm.reportType,"headers":_vm.headers}})],1),_c('div',{staticClass:"d-flex mt-4 mt-sm-0"},[_c('v-btn',{staticClass:"white--text ms-n5 ms-sm-0 text-capitalize",attrs:{"color":"primary","small":_vm.$vuetify.breakpoint.xs,"width":_vm.$vuetify.breakpoint.xs ? 100 : 150},on:{"click":_vm.printSticker}},[_c('v-icon',{staticClass:"me-1",attrs:{"small":_vm.$vuetify.breakpoint.xs,"size":_vm.$vuetify.breakpoint.smAndUp ? 20 : ''}},[_vm._v(" mdi-file-document")]),_vm._v("Meal Sticker ")],1)],1),_c('v-layout',{staticClass:"mt-8"},[_c('v-flex',{attrs:{"xs8":"","sm3":"","md3":"","lg2":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.fromDate,"label":"From","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.fDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectFromDate},model:{value:(_vm.fDate),callback:function ($$v) {_vm.fDate=$$v},expression:"fDate"}})],1)],1)],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.itemsWithSno,"items-per-page":25,"id":"breport","loading":_vm.loading,"mobile-breakpoint":0,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},scopedSlots:_vm._u([{key:"item.customerId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.padLeft(item.customerId, 4))+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [(item.user)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
  name: 'CustomerDetails',
  params: { customerId: item._id },
  query: { customerId: item._id }
}}},[_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "),_vm._v(" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.tag",fn:function(ref){
var item = ref.item;
return _vm._l((item.tags),function(tag){return _c('span',{key:tag._id},[_vm._v(" "+_vm._s(tag.name)),_c('br')])})}},{key:"item.area",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"area"}})]}},{key:"item.block",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"block"}})]}},{key:"item.building",fn:function(ref){
var item = ref.item;
return [_c('CustomerAddressColumn',{attrs:{"address":_vm.defaultAddress(item.addressDetails),"type":"building"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }