<template>
  <v-container>
    <v-btn
      class="mt-n3 white--text text-capitalize me-1"
      @click="exportExcel"
      width="150"
      color="primary" 
    >
      <v-icon >mdi-export</v-icon>Export
    </v-btn>

    <div v-show="false">
      <v-data-table
        id="printCurrentStock"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="stockListAll"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.quantity`]="{ item }">
          <span v-if="item.quantityUnit == 'PIECE'">
            <span v-if="item.stock">
              {{ parseFloat(item.stock.currentStock).toFixed(0) }}&nbsp;{{
                itemQuantityUnit(item)
              }}
            </span>
          </span>
          <span v-else>
            <span v-if="item.stock">
              {{
                parseFloat(item.stock.currentStock / 1000).toFixed(3)
              }}&nbsp;{{ itemQuantityUnit(item) }}
            </span>
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    headers: {
      require: true
    }
  },

  methods: {
    exportExcel() {
      this.getAllStocks();
    },
    itemQuantityUnit(item) {
      let unit = "";
      if (item.quantityUnit === "GRAMS") unit = "KG";
      else if (item.quantityUnit === "ML") unit = "L";
      else if (item.quantityUnit === "PIECE") unit = "PCS";
      return unit;
    },
    getAllStocks() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllCurrentStocks.graphql"),
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .refetch({
          limit: 0,
          skip: 0
        })
        .then(data => {
          this.loading = false;
          if (data.data) {
            this.stockListAll = data.data.getAllCurrentStocks.ingredients;
              console.log(this.stockListAll.length) 
            setTimeout(() => {
              Utils.htmlToExcel("printCurrentStock", "Ingredient", false);
            }, 100);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  },
  data() {
    return {
      loading: true,
      stockListAll: []
    };
  }
};
</script>
