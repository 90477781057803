<template>
  <v-card class="mx-1 mb-1">
    <v-card-title class="flex-nowrap pa-6 pb-3">
      <p class="text-truncate">Sales Trend</p>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text class="pa-6 pt-0 mb-1">
      <v-row no-gutters>
        <v-col cols="12">
          <ApexChart height="300" type="line" :options="chartOptions" :series="series">
          </ApexChart>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ApexChart from "vue-apexcharts";
import moment from "moment";

// import { DATE_FORMAT } from "../../utils/utils";

export default {
  components: {
    ApexChart,
  },

  methods: {},
  data() {
    return {
      monthVal: "",
      apexData: null,
      data: null,
      series: null,
      chartOptions: null,
    };
  },

  mounted() {
    this.$apollo
      .query({
        query: require("../api/getSalesTrendGraph.graphql"),
      })
      .then((data) => {
        // console.log(data);
        let salesTrendList = data.data.getSalesTrendGraph;
        let graphLabels = new Array();
        let graphCount = new Array();

        salesTrendList.forEach((element) => {
          let date = moment(element.date);
          graphLabels.push(date.format("DD-MM-YYYY"));
          graphCount.push(element.price);
        });

        this.chartOptions = {
          chart: {
            height: 350,
            type: "line",
            zoom: {
              enabled: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "straight",
          },
          title: {
            text: "Sales Trend by date (KWD)",
            align: "left",
          },
          grid: {
            row: {
              colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
              opacity: 0.5,
            },
          },
          xaxis: {
            categories: graphLabels,
          },
        };
        this.series = [
          {
            name: "Sales Trend",
            data: graphCount,
          },
        ];
      });
  },
};
</script>
