<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="172 "
        :loading="loading"
        class="mt-n1  white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportMealRating"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="ratingExportList"
        class="elevation-1 "
      >
        <template v-slot:[`item.createdAt`]="{ item }">{{
            new Date(parseInt(item.createdAt)) | moment("MMM DD YYYY")
        }}</template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
//import moment from 'moment' 
export default {
  props: {
    headers: {
      required: true
    },
    fDate:{
        required: true
    },
    tDate: {
        required: true
    },
    mealId:{
        required:true
    }

  },
  data() {
    return {
      ratingExportList: [],
      loading: false,
      variables: {
        fromDate:this.fDate,
        toDate:this.tDate,
        mealId:this.mealId,
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "slNo"
    );
   
  },
  watch: {
     fDate: {
        handler() {
            this.getRatingByMealId();
        }
    },
    tDate: {
        handler() {
            this.getRatingByMealId();
        }
    },

  },

  methods: {
    getRatingByMealId() {
      this.$apollo
        .query({
          query: require("@/menu-packages/meal-ratings/api/getRatingByMealId.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.ratingExportList = data.data.getRatingByMealId.ratings

            
            setTimeout(() => {
              Utils.htmlToExcel("exportMealRating", "Meal Ratings", false);
              this.loading=false
            }, 100);
          }
        });
    },
    exportExcel() {
      this.loading = true;
      this.getRatingByMealId();
    },

  }
};
</script>
