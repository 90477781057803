<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class=" mt-n4  white--text text-capitalize btn_fnt"
        color="primary" 
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false"> 
      <v-data-table
        id="exportPromoCode" 
        hide-default-footer
        :items-per-page="-1"
        :headers="head" 
        :items="promoCodeList"
        class="elevation-1 "
      >
       <template v-slot:[`item.startDate`]="{ item }">
        {{ new Date(parseInt(item.startDate)) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:[`item.endDate`]="{ item }">
        {{ new Date(parseInt(item.endDate)) | moment("MMM DD YYYY") }}
      </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils"; 
export default {
  props: {
    promoCodeList: {
      required: true
    },
    headers: {
      required: true
    }, 
    
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: [
      ],
    };
  },
  created(){
   this.head=this.headers.filter(x=>x.value!='actions' && x.value!='index')
  },

  methods: {
    exportExcel() {
      this.loading = true;
      Utils.htmlToExcel("exportPromoCode", "Promo Code", false);  

      this.loading = false;
    }
  }
};
</script>
