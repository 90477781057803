<template>
  <v-card class="mx-1 mb-1" style="height: 240px">
    <v-card-title class="flex-nowrap pa-6 pb-3">
      <p class="text-truncate">Trending Meals (%)</p>
      <v-spacer></v-spacer>
    </v-card-title>

    <v-card-text class="mb-1 mt-n4">
      <v-row no-gutters>
        <v-col cols="12">
          <ApexChart
            v-if="apexData"
            height="170"
            type="donut"
            :options="apexData.options"
            :series="generatePieSeries()"
          >
          </ApexChart>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ApexChart from "vue-apexcharts";
import config from "@/config";
export default {
  components: {
    ApexChart,
  },
  props: ["month"],
  data() {
    return {
      monthVal: "",
      apexData: null,
      graphCount: null,
    };
  },
  methods: {
    generatePieSeries() {
      let series = [];

      this.graphCount.forEach((element) => {
        let y = Math.floor(element);
        series.push(y);
      });

      return series;
    },
    getTrendingMeals() {
      this.$apollo
        .query({
          query: require("../api/getTrendingMealCount.graphql"),
          variables: {
            month: this.monthVal,
          },
        })
        .then((data) => {
          let trendingMealList = data.data.getTrendingMealCount;
          let graphLabels = new Array();
          let graphCount = new Array();

          trendingMealList.forEach((element) => {
            graphLabels.push(element.meal);
            graphCount.push(element.count);
          });
          this.graphCount = graphCount;

          this.apexData = {
            options: {
              dataLabels: {
                enabled: false,
              },
              colors: [
                config.light.primary,
                config.light.secondary,
                config.light.success,
                config.light.warning,
              ],
              labels: graphLabels,
              legend: {
                show: false,
              },
            },
            series: [
              {
                data: [1, 3],
              },
            ],
          };
        });
    },
  },
  created() {
    let date = new Date();
    let currentMonth = date.getMonth();
    this.monthVal = currentMonth + 1;
    this.getTrendingMeals();
  },
};
</script>
