<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row>
      <h2 class="black--text text-h5 font-weight-bold ms-3 mb-6 mt-3">
        {{ title }} Permission
      </h2>
    </v-row>
    <v-form ref="form" v-model="valid" class="mt-6">
      <v-layout wrap>
        <v-flex xs12 sm4 md2>
          <h4 class="text-body-1 black--text">User Role</h4>
        </v-flex>
        <v-flex xs12 sm8 md4>
          <v-text-field
            v-model="userRole"
            :rules="[requiredValidator('user role')]"
            solo
            dense
          ></v-text-field>
        </v-flex>
      </v-layout>

      <!-- ------------------------------------------- -->

      <v-expansion-panels accordion>
        <v-expansion-panel v-for="(data, index) in datas" :key="index">
          <v-expansion-panel-header>
            <span class="title">{{ data.title }}</span>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-expansion-panels>
              <v-expansion-panel
                v-for="(sub, index) in data.menus"
                :key="index"
              >
                <v-expansion-panel-header flat>
                  <span class="title">{{ sub.name }}</span>
                </v-expansion-panel-header>
                <v-expansion-panel-content flat>
                  <PermissionTable :permissionMenuItems="sub.items" />
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-form>

    <v-row justify="center" class="mt-5">
      <span class="d-flex justify-center mt-5 mx-5">
        <router-link
          style="text-decoration: none"
          :to="{
            name: 'Permission'
          }"
          class="mx-auto"
        >
          <v-btn color="secondary" class="black--text text-capitalize mb-3">
            {{ cancelButton }}</v-btn
          >
        </router-link>
      </span>

      <span class="d-flex justify-center mt-5 mx-5">
        <v-btn
          color="secondary"
          class="black--text text-capitalize mb-3"
          @click="onPermissionSave"
          :loading="buttonLoader"
          >Submit</v-btn
        >
      </span>
    </v-row>
    <v-snackbar v-model="snackbar" top right color="yellow" :timeout="2000">
      <span class="d-flex justify-center black--text">
        {{ textSnackbar }}!</span
      >
    </v-snackbar>
  </v-container>
</template>

<script>
import PermissionTable from "../components/PermissionTable.vue";
import { USER_ROLES } from "../../utils/user-role";
import { requiredValidator } from "../../core/methods/validations";
export default {
  components: { PermissionTable },
  name: "CreatePermission",
  data() {
    return {
      datas: USER_ROLES,
      userRoleId: this.$route.query.id,
      requiredValidator,
      buttonLoader: false,
      snackbar: false,
      textSnackbar: "",
      userRole: "",
      select: "",
      dialog: false,
      valid: true,
      cancelButton: "Cancel",
      title: ""
    };
  },
  created() {
    if (this.userRoleId) {
      if (!this.hasRole(this.AdminUserPermissions.PERMISSION_EDIT)) {
        this.$router.push({
          name: "Error404"
        });
      }
      this.getUserRolePermission();
      this.title = "Update";
    } else {
      if (!this.hasRole(this.AdminUserPermissions.PERMISSION_ADD)) {
        this.$router.push({
          name: "Error404"
        });
      }
      this.title = "Add";
    }
  },
  methods: {
    getUserRolePermission() {
      const value = this.datas
        .flatMap(val => val.menus)
        .flatMap(val => val.items)
        .flatMap(val => val.data);

      this.$apollo
        .query({
          query: require("../api/getUserRolePermission.graphql"),
          variables: { id: this.$route.query.id }
        })
        .then(data => {
          let permissionData = data.data.getUserRoleById.permissions;

          this.userRole = data.data.getUserRoleById.role;
          console.log(value);
          value.forEach(role => {
            // console.log(role);
            if (permissionData.includes(role.key)) {
              role.value = true;
            }
          });
        });
    },
    onPermissionSave() {
      if (this.$refs.form.validate()) {
        this.buttonLoader = true;
        const allPermissions = this.datas
          .flatMap(val => val.menus)
          .flatMap(val => val.items)
          .flatMap(val => val.data);

        let selectedPermissions = allPermissions
          .filter(x => x.value)
          .map(p => p.key);
        if (this.userRoleId) {
          this.updateUserRoles(selectedPermissions);
          setTimeout(() => {
            this.$router.push({ name: "Permission" });
          }, 300);
        } else {
          this.saveUserRoles(selectedPermissions);
        }
      }
    },

    saveUserRoles(selectedPermissions) {
      this.$apollo
        .mutate({
          mutation: require("../api/createUserRole.graphql"),
          variables: {
            role: this.userRole,
            permissions: selectedPermissions
          }
        })
        .then(() => {
          this.textSnackbar = "Permission is added";
          this.buttonLoader = false;
          this.snackbar = true;
          this.dialog = false;
          this.$refs.form.reset();
          this.cancelButton = "Back";
        })
        .catch(error => {
          console.log(error.graphQLErrors[0].message);
          this.buttonLoader = false;
          this.snackbar = true;
          this.textSnackbar = error.graphQLErrors[0].message;
        });
    },
    updateUserRoles(selectedPermissions) {
      this.$apollo
        .mutate({
          mutation: require("../api/updateUserRolePermission.graphql"),
          variables: {
            id: this.$route.query.id,
            input: {
              role: this.userRole,
              permissions: selectedPermissions
            }
          }
        })
        .then(() => {
          this.textSnackbar = "Permission is added";
          this.buttonLoader = false;
          this.snackbar = true;
          this.dialog = false;
          this.cancelButton = "Back";
        })
        .catch(error => {
          console.log(error.graphQLErrors[0].message);
          this.buttonLoader = false;
          this.snackbar = true;
          this.textSnackbar = error.graphQLErrors[0].message;
        });
    }
  }
};
</script>

<style scoped></style>
