var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"ma-0 pa-3 pa-sm-6",attrs:{"color":"pop_bg"}},[_c('v-card-text',[_c('v-row',[_c('h3',{staticClass:"black--text text-h5 text-sm-h6 ms-2 mb-4"},[_vm._v(" Customers Using Meal ")])]),_c('v-divider'),_c('v-layout',{staticClass:"mt-6 mt-md-4 ms-md-n4",attrs:{"wrap":""}},[_c('v-flex',{staticClass:"mt-sm-n5 mt-md-0 ms-sm-5 me-5",attrs:{"xs12":"","sm12":"","md3":""}},[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.formatDate,"label":"Date","readonly":"","solo":"","append-icon":"mdi-calendar-month-outline","dense":""}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{on:{"change":function($event){_vm.menu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-flex',{staticClass:"mt-md-0 ms-sm-5 ms-md-0 mb-8 mb-sm-0",attrs:{"xs12":"","sm4":"","md2":"","lg2":""}},[_c('v-btn',{staticClass:"ms-sm-5 px-6 white--text text-capitalize",attrs:{"color":"primary"},on:{"click":_vm.searchMealCustomers}},[_vm._v(" Search ")])],1)],1),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.customersUsingMeal,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.user.firstnameEn[0].toUpperCase() + item.user.firstnameEn.substring(1))+" "+_vm._s(item.user.lastnameEn[0].toUpperCase() + item.user.lastnameEn.substring(1))+" ")]}},{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.date)),"DD MMM YYYY")))]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }