<template>
  <v-container grid-list-xs>
    <div v-if="ingrediants.length">
      <v-virtual-scroll
        v-scroll.self="onScrollTop"
        height="300"
        item-height="60"
        :items="itemsWithSno"
        class="mt-4"
      >
        <template v-slot:default="{ item }">
          <v-list-item :key="item._id">
            <!-- <v-list-content style="width: 560px" class="overflow-x-auto">   -->
            <v-row style="height: 60px">
              <v-col cols="2">
                <v-list-item-action>
                  <v-btn
                    :x-small="$vuetify.breakpoint.xs"
                    :small="$vuetify.breakpoint.smAndUp"
                    depressed
                    color="#F4E2CE"
                    class="black--text font-weight-bold mt-n3"
                  >
                    {{ item.sno }}
                  </v-btn>
                </v-list-item-action>
              </v-col>
              <v-col cols="5">
                <v-list-item-title class="text-caption mt-1">
                  <span> {{ item.nameEn }}</span>
                </v-list-item-title>
              </v-col>
              <v-col cols="3">
              
                <v-list-item-title class="text-caption mt-1">
                  {{
                    `${item.stock ? (item.stock.currentStock / 1000).toFixed(2) : 0} ${
                      item.quantityUnit.name
                    }`
                  }}
                </v-list-item-title>
              </v-col>
            </v-row>
          </v-list-item>

          <v-divider></v-divider>
        </template>
      </v-virtual-scroll>
    </div>
    <div v-else>
      <v-list-item-title class="text-center pb-5 mt-5"
        >******No Alerts******</v-list-item-title
      >
    </div>
  </v-container>
</template>
<script>
export default {
  computed: {
    itemsWithSno() {
      return this.ingrediantDatas.map((d, index) => ({ ...d, sno: index + 1 }));
    },
  },
  mounted() {
    this.variables.input.limit = this.limit;
    this.variables.input.skip = this.skip;
    this.getAlertQuantity();
  },
  methods: {
    getAlertQuantity() {
      this.$apollo
        .watchQuery({
          query: require("../api/getAlertQuantity.graphql"),
          variables: this.variables,
        })
        .refetch({})
        .then((data) => {
          if (data.data) {
            this.ingrediants = data.data.getAlertQuantity.ingredients;
            if (this.ingrediants.length) this.ingrediantDatas.push(...this.ingrediants);
          }
        });
    },
    onScrollTop(e) {
      this.offsetTop = e.target.scrollTop;
    },
  },
  watch: {
    offsetTop: {
      handler(e, previousValue) {
        if (e > previousValue) {
          if (this.$vuetify.breakpoint.xs) {
            if (!this.dataIndex) {
              let initialLength = 300;
              let firstChange = initialLength + (this.limit - 10) * 60;
              if (Math.round(e) == firstChange) {
                this.firstTotalHeight = firstChange;
                this.dataIndex++;
                this.variables.input.skip = this.limit * this.dataIndex;
                this.variables.input.limit = (this.dataIndex + 1) * this.limit;
                this.getAlertQuantity();
              }
            } else {
              let itemHeight = 60;
              let noOfItem = this.limit;
              let height = itemHeight * noOfItem;
              console.log(this.firstTotalHeight + height);
              if (e == this.firstTotalHeight + height) {
                this.firstTotalHeight += height;
                this.dataIndex++;
                this.variables.input.skip = this.limit * this.dataIndex;
                this.variables.input.limit = (this.dataIndex + 1) * this.limit;
                this.getAlertQuantity();
              }
            }
          } else {
            if (!this.dataIndex) {
              let initialLength = 304;
              let firstChange = initialLength + (this.limit - 10) * 60;

              if (e == firstChange) {
                this.firstTotalHeight = firstChange;
                this.dataIndex++;
                this.variables.input.skip = this.limit * this.dataIndex;
                this.variables.input.limit = (this.dataIndex + 1) * this.limit;
                this.getAlertQuantity();
              }
            } else {
              let itemHeight = 60;
              let noOfItem = this.limit;
              let height = itemHeight * noOfItem;
              if (e == this.firstTotalHeight + height) {
                this.firstTotalHeight += height;
                this.dataIndex++;
                this.variables.input.skip = this.limit * this.dataIndex;
                this.variables.input.limit = (this.dataIndex + 1) * this.limit;
                this.getAlertQuantity();
              }
            }
          }
        }
      },
    },
  },
  data() {
    return {
      offsetTop: 0,
      dataIndex: 0,
      firstTotalHeight: 0,
      loading: true,
      ingrediants: [],
      ingrediantDatas: [],
      benched: 0,
      headingStyle: {
        fontFamily: "Franklin Gothic",
        fontSize: "20px",
      },
      limit: 20,
      skip: 0,
      variables: {
        input: {
          limit: 0,
          skip: 0,
        },
      },
    };
  },
};
</script>
