<template>
  <div>
    <!-- :disabled="salesReport.length == 0" -->

    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        color="primary"
        :loading="loading"
        class="white--text text-capitalize"
      >
        <v-icon size="17" class="me-1">mdi-export</v-icon>Export
      </v-btn>
    </v-layout> 
    <div v-show="false ">
      <v-data-table
        id="printMe"
        hide-default-footer
        :headers="head"
        :items-per-page="-1"
        :items="customerList"
        class="elevation-1 "
      >
        <template v-slot:[`item.customerName`]="{ item }">
          <router-link
            :to="{
              name: 'CustomerDetails',
              params: { customerId: '' },
              query: { customerId: '' }
            }"
            class="mx-auto"
          >
            {{
              item.user.firstnameEn[0].toUpperCase() +
                item.user.firstnameEn.substring(1)
            }}
            {{
              item.user.lastnameEn[0].toUpperCase() +
                item.user.lastnameEn.substring(1)
            }}
          </router-link>
        </template>
        <template v-slot:[`item.startDate`]="{ item }">
          {{
            new Date(parseInt(item.start_date))
              | moment("DD MMM YYYY")
          }}</template
        >
        <template v-slot:[`item.shift`]="{ item }">
          <span
            v-if="!item.addressDetails.shift || item.addressDetails.shift == ''"
            >MORNING</span
          >
          <span v-else>{{ item.addressDetails.shift }}</span>
        </template>
        <template v-slot:[`item.endDate`]="{ item }">
          {{
            new Date(parseInt(item.end_date))
              | moment("DD MMM YYYY")
          }}</template
        >
      </v-data-table>
    </div>
  </div>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  components: {},
  props: {
    date: {},
    headers: {
      required: true
    }
  },
  data() {
    return {
      head: [],
      customerList: [],
      loading: false
    };
  },
  created() {
    this.head = this.headers.filter(x => x.value != "slNo");
  },
  methods: {
    exportExcel() {
      this.loading = true;
      this.getPausedMealsDetailsByDate();
    },
    getPausedMealsDetailsByDate() {
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getPausedMealsDetailsByDate.graphql"),
          variables: {
            date: this.date,
            limit: 25,
            skip: 0
          }
        })
        .refetch({})
        .then(data => {
          this.loading = false;
          if (data.data.getPausedMealsDetailsByDate) {
            this.customerList = data.data.getPausedMealsDetailsByDate.customers;
            console.log(this.customerList.length);
            setTimeout(() => {
              Utils.htmlToExcel("printMe", "Paused Meal Report", false);
              this.loading = false;
            }, 100);
          }
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    }
  }
};
</script>
