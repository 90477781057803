<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row>
      <h2 class="primary--text text-h5 font-weight-bold">Sales</h2>
    </v-row>
    <v-spacer> </v-spacer>
    <v-card>
      <v-row class="mt-8">
        <v-col cols="12" class="ml-10">
          <v-row>
            <v-col cols="5" sm="3" md="1" class="mt-3">Month </v-col>
            <v-col cols="5" sm="3" md="2" class="mt-3">
              <v-select
                :items="monthList"
                :label="today"
                v-model="monthFilter"
                item-value="items"
                dense
                attach
                solo
              ></v-select>
            </v-col>
            <v-col cols="5" sm="3" md="1" class="mt-3">Year </v-col>
            <v-col cols="5" sm="3" md="2" class="mt-3">
              <v-select
                :items="yearList"
                label="year"
                v-model="yearFilter"
                dense
                attach
                solo
              >
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="3" md="1" class="mt-3">Phone </v-col>
            <v-col cols="5" sm="3" md="2" class="mt-3">
              <v-text-field dense attach solo v-model="phone"></v-text-field>
            </v-col>
            <v-col cols="5" sm="3" md="1" class="mt-3">Order No </v-col>
            <v-col cols="5" sm="3" md="2" class="mt-3">
              <v-text-field
                dense
                attach
                solo
                v-model="orderNumber"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="5" sm="3" md="10" class="mt-3"> </v-col>
            <v-col cols="5" sm="3" md="2" class="text--end">
              <v-btn
                class="secondary black--text"
                height="40"
                :loading="exportStarted"
                @click="startExport"
                >Export</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-card>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="color">{{
      text
    }}</v-snackbar>
  </v-container>
</template>

<script>
import moment from "moment";
// import ExportSalesReport from "../components/ExportSalesReport.vue";
export default {
  components: {
    // ExportSalesReport,
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SALES_REPORT_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
    var oneDate = moment();
    this.monthFilter = oneDate.format("MMMM");
    this.yearFilter = moment().year();
    this.monthList = moment.months();
  },
  methods: {
    startExport() {
      this.exportStarted = true;
      this.$apollo
        .watchQuery({
          query: require("@/orders/api/excelGetSalesReport.graphql"),
          variables: {
            input: {
              monthFilter: this.monthFilter,
              yearFilter: this.yearFilter,
              orderNumber: this.orderNumber,
              phone: this.phone,
            },
          },
        })
        .refetch({
          input: {
            monthFilter: this.monthFilter,
            yearFilter: this.yearFilter,
            orderNumber: this.orderNumber,
            phone: this.phone,
          },
        })
        .then((data) => {
          debugger
          this.exportStarted = false;
          this.text = "Some error occured";
          this.color = "red";
          if (data.data.excelGetSalesReport) {
            if (data.data.excelGetSalesReport.status == "SUCCESS") {
              this.text =
                "Please check report page after some time to know the report status";
              this.color = "success";
            }
          }
          this.snackbar = true;
        })
        .catch((error) => {
          this.text = "Some error occured";
          this.color = "red";
          this.snackbar = true;

          this.exportStarted = false;
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
        });
    },
    findYearList() {
      let yearList = [];
      let startingYear = 2021;
      let currentYear = moment().year();
      while (currentYear >= startingYear) {
        yearList.push(startingYear);
        startingYear = startingYear + 1;
      }
      return yearList;
    },
  },
  data() {
    return {
      exportStarted: false,
      today: "",
      snackbar: false,
      text: "",
      color: "",
      orderNumber: "",
      phone: "",
      tabs: "",
      salesReport: [],
      monthFilter: "",
      yearFilter: "",
      yearList: this.findYearList(),
      selectedId: -1,
      loading: false,
      search: "",
      btnloading: true,
      selected: [],
      headers: [],
      monthList: [],
      variables: {
        monthFilter: "",
        yearFilter: "",
      },
    };
  },
};
</script>
<style >
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>