var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mx-5"},[_c('v-layout',[_c('v-flex',[_c('h4',{staticClass:"black--text text-h6  mt-5 font-weight-bold"},[_vm._v(" Dietitian Appointment Report ")])])],1),_c('v-layout',{staticClass:"mt-5",attrs:{"wrap":"","justify-end":""}},[_c('v-flex',{staticClass:"mr-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.paymentStatuses,"item-text":"text","item-value":"value","solo":"","dense":"","attach":"","label":"Payment Status","clearable":""},on:{"change":_vm.filterSearch},model:{value:(_vm.filterData.paymentStatus),callback:function ($$v) {_vm.$set(_vm.filterData, "paymentStatus", $$v)},expression:"filterData.paymentStatus"}})],1),_c('v-flex',{staticClass:"mr-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.dietitianList,"item-text":"user.firstnameEn","item-value":"_id","solo":"","dense":"","attach":"","label":"Search dietitian","clearable":""},on:{"change":_vm.filterSearch},scopedSlots:_vm._u([{key:"selection",fn:function(data){return [_vm._v(" "+_vm._s(data.item.user.firstnameEn)+" "+_vm._s(data.item.user.lastnameEn)+" ")]}},{key:"item",fn:function(data){return [_vm._v(_vm._s(data.item.user.firstnameEn)+" "+_vm._s(data.item.user.lastnameEn)+" ")]}}]),model:{value:(_vm.filterData.filterDietitian),callback:function ($$v) {_vm.$set(_vm.filterData, "filterDietitian", $$v)},expression:"filterData.filterDietitian"}},[_c('template',{slot:"no-data"},[_c('v-flex',{staticClass:"mt-2 mb-2",attrs:{"xs12":"","md12":""}},[_c('h5',{staticClass:"font-weight-medium ml-4"},[_vm._v(" Search for dietitian... ")])])],1)],2)],1),_c('v-flex',{staticClass:"ml-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[_c('v-layout',[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.searchStartDateDate,"label":"From Date","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.appDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menuFromDate),callback:function ($$v) {_vm.menuFromDate=$$v},expression:"menuFromDate"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectFromDate},model:{value:(_vm.appDate),callback:function ($$v) {_vm.appDate=$$v},expression:"appDate"}})],1)],1)],1),_c('v-flex',{staticClass:"ml-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[_c('v-layout',[_c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"290"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"value":_vm.searchEndDateDate,"label":"To Date","solo":"","append-icon":"fa-calendar-alt black--text","dense":""},on:{"click:clear":function($event){_vm.appToDate = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"secondary"},on:{"change":_vm.selectToDate},model:{value:(_vm.appToDate),callback:function ($$v) {_vm.appToDate=$$v},expression:"appToDate"}})],1)],1)],1),_c('v-flex',{staticClass:"mr-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[_c('v-autocomplete',{attrs:{"items":_vm.actionTypes,"item-text":"text","item-value":"value","solo":"","dense":"","attach":"","label":"Action Type","clearable":""},on:{"change":_vm.filterSearch},model:{value:(_vm.filterData.actionType),callback:function ($$v) {_vm.$set(_vm.filterData, "actionType", $$v)},expression:"filterData.actionType"}})],1),_c('v-flex',{staticClass:"ml-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md1":""}},[_c('v-btn',{staticClass:"white--text ml-5 ",attrs:{"color":"primary","dense":""},on:{"click":function($event){return _vm.clearSearch()}}},[_c('v-icon',[_vm._v("mdi-refresh")])],1)],1),_c('v-flex',{staticClass:"ml-4 mx-sm-2",attrs:{"xs5":"","sm3":"","md2":""}},[(_vm.isAdmin)?_c('export-dietitian-appointment',{attrs:{"headers":_vm.headers,"input":{
        input: { limit: _vm.totalItemsCount, skip: 1 },
        filterData: _vm.filterData,
        searchKey: _vm.searchKey
      }}}):_vm._e()],1)],1),_c('v-layout',{staticClass:"mt-5",attrs:{"wrap":"","justify-end":""}}),_c('v-data-table',{staticClass:"elevation-1 mt-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.dietitianAppointmentList,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"options":_vm.options,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.user",fn:function(ref){
      var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'CustomerDetails',
        params: { customerId: item.customer._id },
        query: { customerId: item.customer._id }
      }}},[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]}},{key:"item.dietitian",fn:function(ref){
      var item = ref.item;
return [_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
        name: 'DietitianDetails',
        params: { dietitianId: item.dietitian._id },
        query: { dietitianId: item.dietitian._id }
      }}},[_vm._v(" "+_vm._s(item.dietitian.user.firstnameEn[0].toUpperCase() + item.dietitian.user.firstnameEn.substring(1))+" "+_vm._s(item.dietitian.user.lastnameEn[0].toUpperCase() + item.dietitian.user.lastnameEn.substring(1))+" ")])]}},{key:"item.bookedDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookedDate(item))+" ")]}},{key:"item.appointmentDate",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.appointmentDate(item.followUp))+" ")]}},{key:"item.mode",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.mode(item.followUp))+" ")]}},{key:"item.shift",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.shift(item.followUp))+" ")]}},{key:"item.followUpType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.followUpType(item.followUp))+" ")]}},{key:"item.actionType",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.followUp.actionType)+" ")]}},{key:"item.actionStatus",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.followUp.actionStatus)+" ")]}},{key:"item.bookingFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.bookingFee(item.followUp))+" ")]}},{key:"item.additionalFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.additionalFee(item.followUp))+" ")]}},{key:"item.callFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.callFee(item.followUp))+" ")]}},{key:"item.totalFee",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.totalFee(item.followUp))+" ")]}},{key:"item.paymentStatus",fn:function(ref){
      var item = ref.item;
return [_c('div',[_vm._v(" BOOKING FEE: "),_c('span',{class:item.followUp.payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
            ? 'green--text'
            : item.followUp.payment_status == 'PENDING'
              ? 'orange--text'
              : item.followUp.payment_status == 'CANCELLED'
                ? 'blue--text'
                : item.followUp.payment_status == 'FAILED'
                  ? 'red--text'
                  : ''},[_vm._v(" "+_vm._s(item.followUp.payment_status)+" ")])]),(item.followUp.actionStatus == 'FINISHED' && item.followUp.modeOfAppointment == 'ON_VISIT' && item.followUp.additional_payment_status)?_c('div',[_vm._v(" ADDITIONAL FEE : "),_c('span',{class:item.followUp.additional_payment_status == 'SUCCESS' || item.followUp.additional_payment_status == 'FREE'
            ? 'green--text'
            : item.followUp.additional_payment_status == 'PENDING'
              ? 'orange--text'
              : item.followUp.additional_payment_status == 'CANCELLED'
                ? 'blue--text'
                : item.followUp.additional_payment_status == 'FAILED'
                  ? 'red--text'
                  : ''},[_vm._v(" "+_vm._s(item.followUp.additional_payment_status)+" ")])]):_vm._e()]}}],null,true)}),_c('v-layout',{staticClass:"mt-10"},[(_vm.isAdmin)?_c('h2',{staticClass:"black--text text-h5 font-weight-bold"},[_vm._v(" Calendar View ")]):_c('h2',{staticClass:"primary--text text-h5 font-weight-bold"},[_vm._v(" Calendar View ")])]),_c('div'),_c('v-spacer'),_c('v-snackbar',{attrs:{"timeout":"3000","right":"","top":"","color":_vm.snackColor},model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(_vm._s(_vm.text))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }