<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0">
        <h2 class="black--text text-h5 font-weight-bold ms-3">Vendors</h2>
        <v-spacer></v-spacer>
        <v-layout justify-end class="me-3">
          <CreateVendor v-if="hasRole(AdminUserPermissions.SUPPLIER_STOCK_ADD)"></CreateVendor>
          <!-- <EditVendor
            v-if="hasRole(AdminUserPermissions.DIET_PLAN_EDIT)"
            :vendorDetails="item"
          /> -->
        </v-layout>
      </v-row>
      <!-- {{vendors}} -->
      <!-- {{brandList}} -->
      <v-data-table :mobile-breakpoint="0" :headers="headers" :items="itemsWithSno" class="elevation-1 mt-10"
        :loading="loading">
        <template #item.brand="{ item }">
          {{ getBrandName(item) }}
        </template>
        <template #item.area="{ item }">
          <span v-if="item.address && item.address.area">
            {{ item.address.area.nameEn }}
          </span>
        </template>
        <template #item.block="{ item }">
          <span v-if="item.address && item.address.block">
            {{ item.address.block.block }}
          </span>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-row>
            <EditVendor v-if="hasRole(AdminUserPermissions.SUPPLIER_STOCK_EDIT)" :vendorDetails="item" />
            <DeleteVendor v-if="hasRole(AdminUserPermissions.SUPPLIER_STOCK_DELETE)" :vendorDetails="item" />
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import CreateVendor from "../components/CreateVendor.vue";
import EditVendor from "../components/EditVendor.vue";
import DeleteVendor from "../components/DeleteVendor.vue";

export default {
  components: {
    CreateVendor,
    EditVendor,
    DeleteVendor,
  },
  mounted() {
    this.$root.$on("updateVendor", () => {
      this.loading = true;
      this.$apollo.queries.listAllVendors.refetch({});
    });
  },
  methods: {
    getBrandName(item) {
      if (item.brands) {
        return item.brands.map((x) => x.nameEn).join(", ");
      }
    },
  },
  data() {
    return {
      loading: false,
      vendors: [],
      brandList: ["apple", "HP", "LENOVO"],
      headers: [
        {
          text: "#",
          sortable: false,
          value: "slNo",
          width: 100,
        },
        { text: "Company Name", value: "companyNameEn", width: 200 },
        { text: "Phone", value: "phone", width: 200 , sortable:false},
        { text: "Contact Person Name", value: "contactPersonName", width: 200 },
        {
          text: "Contact Person Phone",
          value: "contactPersonPhone",
          width: 200,
        },
        { text: "Contact Person Type", value: "contactPersonType", width: 200 },
        { text: "Brands", value: "brand", width: 200 },
        { text: "Area", value: "area", width: 200 },
        { text: "Block", value: "block", width: 200 },
        { text: "Actions", value: "actions", width: 110, sortable: false },
      ],
    };
  },
  apollo: {
    listAllVendors() {
      return {
        query: require("../api/listAllVendors.graphql"),
        variables: {
          skip: 0,
          limit: 0
        },
        result({ data, loading }) {
          this.vendors = data.listAllVendors;
          this.loading = loading;
        },
      };
    },
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.SUPPLIER_STOCK_VIEW)) {
      this.$router.push({
        name: "Error404",
      });
    }
  },
  computed: {
    itemsWithSno() {
      return this.vendors.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
  },
};
</script>
