<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading"
        class="  white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportReferral"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="usersList"
        class="elevation-1 "
      >
        <template v-slot:[`item.name`]="{ item }">
          {{
            item.firstnameEn[0].toUpperCase() + item.firstnameEn.substring(1)
          }}
          {{ item.lastnameEn[0].toUpperCase() + item.lastnameEn.substring(1) }}
        </template>
        <template v-slot:[`item.referralCode`]="{ item }">
          {{
            parseInt(item.userId)
              .toString(16)
              .toUpperCase()
          }}
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    userType: {}
  },
  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "slNo"
    );
    // this.getAllUsers();
  },
  data() {
    return {
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      usersList: []
    };
  },

  methods: {
    exportExcel() {
      this.loading = true;
      this.getAllUsers();
    },
    getAllUsers() {
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getAllUsers.graphql"),
          variables: {
            conditions: JSON.stringify(this.userType),
            limit: 0,
            skip: 0
          }
        })
        .refetch({})
        .then(data => {
          if (data.data.getAllUsers) {
            this.usersList = data.data.getAllUsers.users;
            setTimeout(() => {
              Utils.htmlToExcel("exportReferral", "Referrals", false);

              this.loading = false;
            }, 100);
          }
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    }
  }
};
</script>
