var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.notificationList,"items-per-page":25,"options":_vm.options,"loading":_vm.loading,"server-items-length":_vm.totalItemsCount,"footer-props":{ 'items-per-page-options': [25, 50, 100, 500] }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.notificationType",fn:function(ref){
var item = ref.item;
return [(item.notificationType == 'DateAndTime')?_c('span',[_vm._v("Date And Time")]):_vm._e(),(item.notificationType == 'DayAndTime')?_c('span',[_vm._v("Day And Time")]):_vm._e(),(item.notificationType == 'EveryMonth')?_c('span',[_vm._v("Every Month")]):_vm._e()]}},{key:"item.createdDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"MMM DD YYYY"))+" ")]}},{key:"item.createTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.createdDate)),"hh:mm:A"))+" ")]}},{key:"item.info",fn:function(ref){
var item = ref.item;
return [(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_DELETE))?_c('ScheduleNotificationDetails',{attrs:{"notification":item}}):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.hasRole(_vm.AdminUserPermissions.MASTER_DATA_DELETE))?_c('ScheduleNotificationDelete',{attrs:{"notification":item}}):_vm._e()]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }