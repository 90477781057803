<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="1000px">
      <template v-slot:activator="{ on }">
        <v-btn v-on="on" v-if="hasRole(AdminUserPermissions.CUSTOMER_EDIT)" class="white--text ma-2 text-capitalize"
          color="primary">
          <v-icon small class="white--text me-1">mdi-square-edit-outline</v-icon>
          Edit
        </v-btn>
      </template>
      <v-card class="pa-3" color="pop_bg">
        <v-card-title class="justify-center">
          <!-- <span class="primary--text" v-show="isDisable">Customer Details</span> -->
          <span class="black--text text-h6">Edit Customer</span>
          <v-spacer></v-spacer>
          <v-btn color="black" text @click="close"><v-icon>mdi-close</v-icon></v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-form class="pa-sm-8" @submit="updateCustomer()" v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 mt-2>
                  <h4 class="black--text text-body-2">First Name(English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field :rules="[requiredValidator('first name')]" solo dense
                    v-model="customer.user.firstnameEn"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Last Name (English)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field :rules="[requiredValidator('last name')]" solo dense
                    v-model="customer.user.lastnameEn"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">First Name(Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field :rules="[requiredValidator('first name(arabic)')]" solo dense
                    v-model="customer.user.firstnameAr"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Last Name (Arabic)</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field solo :rules="[requiredValidator('last name(arabic)')]" dense
                    v-model="customer.user.lastnameAr"></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2>
                  <h4 class="black--text text-body-2">Height</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field solo dense min="0" type="number" v-model="customer.height"
                    :rules="positiveNo"></v-text-field>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Weight</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field solo dense min="0" :rules="positiveNo" type="number"
                    v-model="customer.weight"></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Email</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-text-field solo dense @click="doesEmailExist = false" :rules="[
                    this.doesEmailExist != true || 'email already exists.'
                  ]" v-model="customer.user.email"></v-text-field>
                </v-flex>

                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Contact Number</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-text-field solo dense type="number" v-model="customer.user.phone" @click="doesPhoneExist = false"
                    :rules="[
                      requiredValidator('phone'),
                      minLengthValidator('phone', 8),
                      maxLengthValidator('phone', 8),
                      this.doesPhoneExist != true || 'phone already exists.'
                    ]"></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Date of birth</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                    transition="scale-transition" offset-y min-width="290px">
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field solo :value="formatDate" dense append-icon="fa-calendar-alt black--text" readonly
                        :rules="[requiredValidator('date of birth')]" v-bind="attrs" v-on="on"></v-text-field>
                    </template>
                    <v-date-picker color="secondary" :max="new Date() | moment('YYYY-MM-DD')" v-model="dateofbirth"
                      @input="menu2 = false"></v-date-picker>
                  </v-menu>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Gender</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select :items="gender" :rules="[requiredValidator('gender')]" v-model="customer.user.gender"
                    item-text="text" item-value="value" dense attach solo></v-select>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <!-- <v-flex xs12 sm4 md3 lg2 ps-lg-6 mt-2> -->
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Customer Goal</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg3>
                  <v-select :items="customerGoalList" v-model="customer.customerGoal" item-text="text"
                    item-value="value" dense attach solo></v-select>
                </v-flex>
                <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                  <h4 class="black--text text-body-2">Customer Tag</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg4>
                  <v-select v-model="customer.tags" :items="allCustomerTags" solo dense item-value="_id"
                    item-text="name" attach chips small-chips multiple></v-select>
                </v-flex>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Contact Method</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-select :items="methodList" :rules="[requiredValidator('contact method')]" solo dense attach
                      item-text="method" item-value="_id" v-model="customer.contactMethod._id"></v-select></v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2 pe-md-2">
                    <h4 class="black--text text-body-2">Mobile Verified</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-switch v-model="customer.user.phoneVerified"></v-switch>
                  </v-flex>
                </v-layout>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4 md3 lg2 class="mt-n2 mt-sm-2">
                  <h4 class="text-body-2 black--text">Comment</h4>
                </v-flex>
                <v-flex xs12 sm8 md9 lg10 class="pe-lg-12">
                  <v-textarea rows="1" v-model="customer.comment" solo dense></v-textarea>
                </v-flex>
              </v-layout>
              <!-- {{ customer.addressDetails }} -->
              <div v-for="(address, index) in customer.addressDetails" :key="index">
                <v-layout wrap class="mb-4">
                  <v-flex xs10 sm11 md11 lg10 mt-2>
                    <h4 class="text-h6 black--text font-weight-bold">
                      Address {{ index + 1 }}
                    </h4>
                  </v-flex>

                  <v-flex xs1 sm1 md1 lg2 v-if="customer.addressDetails.length > 1">
                    <v-btn fab small class="secondary--text" color="primary" @click.stop="onDeleteAddress(address)">
                      <v-icon dark>mdi-delete</v-icon>
                    </v-btn>
                  </v-flex></v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Area</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-autocomplete v-model="address.area" :items="allAreas" item-text="nameEn" item-value="_id" solo
                      chips dense attach small-chips @change="getBlocksByArea(address)"
                      :rules="[requiredValidator('area')]" clearable deletable-chips :loading="areasLoading">
                      <template slot="item" slot-scope="data">{{ data.item.nameEn }},
                        {{ data.item.nameAr }}</template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Block</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-select v-model="address.block" :items="address.blocksByArea" item-text="block" item-value="_id"
                      :loading="blockLoading" solo @change="getShiftsByArea(address)"
                      :rules="[requiredValidator('block')]" dense attach placeholder="Select a block">
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Shift</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-select v-model="address.shift" :items="address.allShifts" solo dense attach
                      :loading="shiftLoading"></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Street</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="address.street" solo :rules="[requiredValidator('street')]"
                      dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Jedha</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-text-field v-model="address.jedha" solo dense></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">House Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="address.house_number" solo dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Floor Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-text-field v-model="address.building_number" solo dense></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Apartment Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="address.apartment_number" solo dense></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Address Type</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-select :items="addressTypeList" :rules="[requiredValidator('address type')]"
                      v-model="address.addressType" item-text="text" item-value="value" dense attach solo>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2>
                    <h4 class="black--text text-body-2">Comments</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg9>
                    <v-textarea rows="1" v-model="address.comments" solo dense></v-textarea>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2>
                    <h4 class="black--text text-body-2"></h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg9>
                    <v-checkbox label="Set As Default Address" @change="setAsDefault(address)"
                      v-model="address.is_default"></v-checkbox>
                  </v-flex>
                </v-layout>
                <!-- <v-text-field
                    label="Map Location"
                    v-model="address.map_location"
                    outlined
                    dense
                  ></v-text-field> -->
              </div>

              <v-layout wrap>
                <v-flex xs12 mt-2 mb-4>
                  <h4 v-if="isNewAddress" class="text-h6 white--text font-weight-bold">
                    New Address
                  </h4>
                  <v-btn color="primary" class="white--text text-capitalize" v-show="!isNewAddress"
                    @click="enableNewAddress()">
                    +Add Address</v-btn>
                </v-flex>
              </v-layout>

              <div v-if="isNewAddress">
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Area</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-autocomplete v-model="newAddress.area" :items="allAreas" item-text="nameEn" item-value="_id" solo
                      chips dense small-chips @change="getBlocksByArea(newAddress)" :rules="[requiredValidator('area')]"
                      clearable deletable-chips :loading="areasLoading">
                      <template slot="no-data">
                        <v-flex xs12 md6 class="mt-2 mb-2">
                          <h5 class="font-weight-medium ml-4">
                            Search for area...
                          </h5>
                        </v-flex>
                      </template>
                      <template slot="item" slot-scope="data">{{ data.item.nameEn }},
                        {{ data.item.nameAr }}</template>
                    </v-autocomplete>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Block</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-select v-model="newAddress.block" :items="newAddress.blocksByArea" item-text="block"
                      item-value="_id" solo :loading="blockLoading" :rules="[requiredValidator('block')]"
                      @change="getShiftsByArea(newAddress)" dense attach>
                      <template slot="no-data">
                        <v-flex xs12 md6 class="mt-2 mb-2">
                          <h5 class="font-weight-medium ml-4">
                            Select a block
                          </h5>
                        </v-flex>
                      </template>
                    </v-select>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Shift</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-select v-model="newAddress.shift" :items="newAddress.allShifts" solo dense attach
                      :rules="[requiredValidator('shift')]"></v-select>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Street</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="newAddress.street" :rules="[requiredValidator('street')]" solo
                      dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Jedha</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-text-field v-model="newAddress.jedha" solo dense></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">House Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="newAddress.house_number" solo dense></v-text-field>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Floor Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-text-field v-model="newAddress.building_number" solo dense></v-text-field>
                  </v-flex>
                  <v-flex xs12 sm4 md3 lg2 ps-lg-6 class="mt-n2 mt-sm-2">
                    <h4 class="black--text text-body-2">Apartment Number</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg4>
                    <v-text-field v-model="newAddress.apartment_number" solo dense></v-text-field>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2 mt-2>
                    <h4 class="black--text text-body-2">Address Type</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg3>
                    <v-select :items="addressTypeList" :rules="[requiredValidator('address type')]"
                      v-model="newAddress.addressType" item-text="text" item-value="value" dense attach solo>
                    </v-select>
                  </v-flex>
                </v-layout>
                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2>
                    <h4 class="black--text text-body-2">Comments</h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg9>
                    <v-textarea rows="1" v-model="newAddress.comments" solo dense></v-textarea>
                  </v-flex>
                </v-layout>

                <v-layout wrap>
                  <v-flex xs12 sm4 md3 lg2>
                    <h4 class="black--text text-body-2"></h4>
                  </v-flex>
                  <v-flex xs12 sm8 md9 lg9>
                    <v-checkbox label="Set As Default Address" @change="setAsDefault(newAddress)"
                      v-model="newAddress.is_default"></v-checkbox>
                  </v-flex>
                </v-layout>
                <!-- <v-text-field
                    label="Map Location"
                    v-model="newAddress.map_location"
                    outlined
                    dense
                  ></v-text-field> -->

                <v-layout justify-end>
                  <v-btn color="primary" class="white--text text-capitalize" v-show="isNewAddress"
                    @click="createAddress()">Add</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" class="white--text text-capitalize" small1 v-show="isNewAddress"
                    @click="cancel()">Cancel</v-btn>
                </v-layout>
              </div>

              <v-divider class="mb-6 mt-4"></v-divider>

              <v-layout justify-end class="mb-n5 mb-sm-n10">
                <v-btn :width="$vuetify.breakpoint.xs ? 90 : 120" color="secondary" class="white--text text-capitalize"
                  :disabled="!valid" @click="updateCustomer()" :loading="btnloading">Save</v-btn>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="snackbarColor">
      {{ text }}
    </v-snackbar>
    <!-- {{ customerInfo }} -->
  </div>
</template>

<script>
import {
  requiredValidator,
  emailValidator,
  minLengthValidator,
  maxLengthValidator
} from "../../core/methods/validations";
import moment from "moment";
import { DATE_FORMAT } from "../../utils/utils";
export default {
  props: {
    customerInfo: {
      required: false,
      default() {
        return {};
      }
    }
  },
  created() {
    this.customer = JSON.parse(JSON.stringify(this.customerInfo));
    if (!this.customer.contactMethod) {
      this.customer.contactMethod = { _id: "" };
    }
    this.getAllContactMethods();
    this.getAllAreas();
    this.customer.addressDetails.forEach(element => {
      // element.blocksByArea = [];
      this.getBlocksByArea(element);
      this.getShiftsByArea(element);
    });
    if (this.customer.user.dob) {
      let date = new Date(parseInt(this.customer.user.dob));
      this.dateofbirth = moment(date).format("YYYY-MM-DD");
    }
  },
  data() {
    return {
      minLengthValidator: minLengthValidator,
      maxLengthValidator: maxLengthValidator,
      blockLoading: false,
      shiftLoading: false,
      phoneVerified: false,
      valid: true,
      customer: [],
      emailValidator: emailValidator,
      requiredValidator: requiredValidator,
      errors: [],
      allShifts: ["MORNING", "EVENING"],
      customerGoalList: [
        { text: "NONE", value: "NONE" },
        { text: "Lose Weight", value: "LOSE_WEIGHT" },
        { text: "Maintain Weight", value: "MAINTAIN_WEIGHT" },
        { text: "Gain Weight", value: "GAIN_WEIGHT" }
      ],
      show_dialog_box: false,
      addressId: 1,
      snackbar: false,
      text: "",
      doesPhoneExist: false,
      doesEmailExist: false,
      snackbarColor: "",
      btnloading: false,
      dislikedItemLoading: false,
      gender: [
        { text: "Male", value: "MALE" },
        { text: "Female", value: "FEMALE" }
      ],
      addressTypeList: ["Office", "Home", "Others"],
      allAreas: [],
      areasLoading: false,
      areaSearchKey: "",
      awaitingAreasSearch: false,
      allCustomerTags: [],
      isNewAddress: false,
      menu2: false,
      dateofbirth: "",
      newAddress: {
        _id: "",
        area: "",
        block: "",
        street: "",
        jedha: "",
        house_number: "",
        building_number: "",
        apartment_number: "",
        comments: "",
        map_location: "",
        addressType: "",
        is_default: false
      },
      methodList: [],
      positiveNo: [v => v >= 0 || "Enter positive No"]
    };
  },
  apollo: {
    customerTags() {
      return {
        query: require("../api/customerTags.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          this.allCustomerTags = data.customerTags;
        }
      };
    }
  },
  computed: {
    formatDate() {
      return this.dateofbirth
        ? moment(this.dateofbirth).format(DATE_FORMAT)
        : "";
    }
  },
  methods: {
    setAsDefault(address) {
      debugger
      if (this.customer.addressDetails) {
        this.customer.addressDetails.forEach(element => {
          if (element._id != address._id) {
            element.is_default = false;
          }
        });
      }
      if (this.newAddress._id != address._id) {
        this.newAddress.is_default = false;
      }
    },
    onDeleteAddress(address) {
      this.customer.addressDetails = this.customer.addressDetails.filter(
        x => x._id != address._id
      );
    },
    getAllContactMethods() {
      this.$apollo
        .watchQuery({
          query: require("@/contact-method/api/getAllContactMethods.graphql"),
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .refetch({
          variables: {
            limit: 0,
            skip: 0
          }
        })
        .then(data => {
          this.methodList = data.data.getAllContactMethods.methods;
        });
    },
    getAllAreas() {
      this.$apollo
        .query({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: ""
          }
        })
        .then(data => {
          this.allAreas = data.data.getAllAreas.areas;
          this.areasLoading = false;
        });
    },

    getShiftsByArea(address) {
      let area = address.area;
      if (address.area._id) {
        area = address.area._id;
      }
      let block = address.block;
      if (address.block) {
        if (address.block._id) {
          block = address.block._id;
        }
      }
      if (area && block) {
        this.shiftLoading = true;
        this.$apollo
          .query({
            query: require("@/customer/api/getShiftsByArea.graphql"),
            variables: {
              area: area,
              block: block
            }
          })
          .then(data => {
            // debugger;
            address.allShifts = data.data.getShiftsByArea.shifts;
            this.shiftLoading = false;
          });
      } else {
        this.allShifts = [];
      }
    },

    enableNewAddress() {
      this.isNewAddress = true;
      this.newAddress = {
        _id: "",
        area: "",
        block: "",
        street: "",
        jedha: "",
        house_number: "",
        building_number: "",
        apartment_number: "",
        comments: "",
        map_location: "",
        blocksByArea: [],
        allShifts: [],
        is_default: false
      };
    },
    cancel() {
      this.isNewAddress = false;
    },
    getBlocksByArea(address) {
      if (address) {
        this.blockLoading = true;
        let area = address.area;
        if (address.area._id) {
          area = address.area._id;
        }
        this.$apollo
          .query({
            query: require("@/customer/api/getBlocksByArea.graphql"),
            variables: {
              id: area
            }
          })
          .then(data => {
            this.blockLoading = false;
            address.blocksByArea = data.data.getBlocksByArea.blocks;
          });
      }
    },
    createAddress() {
      this.addressId = this.addressId + 1;
      this.newAddress._id = this.addressId;
      this.customer.addressDetails.push(
        JSON.parse(JSON.stringify(this.newAddress))
      );
      this.isNewAddress = false;
    },

    close() {
      this.isNewAddress = false;
      this.show_dialog_box = false;
    },

    updateCustomer() {
      if (!this.valid) return false;
      console.log(this.phoneVerified);

      this.customer.user.dob = this.dateofbirth;
      let addressList = JSON.parse(
        JSON.stringify(this.customer.addressDetails)
      );

      let deliveryInputs = new Array();
      addressList.forEach(element => {
        let obj = {
          is_default: element.is_default,
          area: element.area._id ? element.area._id : element.area,
          block: element.block._id ? element.block._id : element.block,
          shift: element.shift,
          street: element.street,
          jedha: element.jedha,
          house_number: element.house_number,
          building_number: element.building_number,
          apartment_number: element.apartment_number,
          comments: element.comments,
          map_location: element.map_location,
          addressType: element.addressType,
          id: element._id
        };
        deliveryInputs.push(obj);
      });

      let updatedTags = [];
      if (this.customer.tags != null) {
        this.customer.tags.map(function (elt) {
          if (elt._id === undefined) updatedTags.push(elt);
          else updatedTags.push(elt._id);
        });
      }

      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateCustomer.graphql"),
          variables: {
            customerId: this.customer._id,
            userId: this.customer.user._id,
            shift: this.customer.shift,
            height: this.customer.height,
            weight: this.customer.weight,
            userInput: {
              firstnameEn: this.customer.user.firstnameEn,
              lastnameEn: this.customer.user.lastnameEn,
              firstnameAr: this.customer.user.firstnameAr,
              lastnameAr: this.customer.user.lastnameAr,
              phoneVerified: this.customer.user.phoneVerified,
              phone: this.customer.user.phone,
              email: this.customer.user.email,
              dob: this.customer.user.dob,
              gender: this.customer.user.gender
            },
            deliveryInput: deliveryInputs,
            tags: updatedTags,
            comment: this.customer.comment,
            customerGoal: this.customer.customerGoal,
            contactMethod: this.customer.contactMethod._id
          }
        })
        .then(() => {
          this.$root.$emit("updateGetCustomerById");
          this.snackbar = true;
          this.snackbarColor = "success";
          this.text = "Customer details updated";
          this.btnloading = false;
          this.close();
        })
        .catch(error => {
          if (error.graphQLErrors != null) {
            if (error.message === "GraphQL error: EMAIL_ALREADY_EXIST") {
              this.doesEmailExist = true;
            } else if (error.message === "GraphQL error: PHONE_ALREADY_EXIST") {
              this.doesPhoneExist = true;
            } else {
              this.text = error.graphQLErrors[0].message;
              this.snackbarColor = "error";
              this.snackbar = true;
              this.btnloading = false;
            }
            window.scrollTo(0, 0);
            this.$refs.form.$el.scrollIntoView({ behavior: "smooth" });

            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.isNewAddress = false;
        });
    }
  }
};
</script>
<style>
.theme--dark.v-input input,
.theme--dark.v-input textarea {
  color: yellow !important;
}

.theme--dark.v-select .v-select__selections {
  color: yellow !important;
}

.theme--dark.v-chip {
  color: yellow !important;
}

.theme--dark.v-icon {
  color: yellow !important;
}

.theme--dark.v-label {
  color: cornsilk !important;
}
</style>
