<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="172 "
        :loading="loading"
        class="mt-n1  white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="exportMenuCategory"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="menuItemExportList"
        class="elevation-1 "
      >
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    }
  },
  data() {
    return {
      menuItemExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "slNo"
    );
    // this.getAllCategories();
  },

  methods: {
    getAllCategories() {
      this.$apollo
        .query({
          query: require("@/menu-packages/api/getAllCategories.graphql"),
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.menuItemExportList = data.data.getAllCategories.category;
            setTimeout(() => {
              Utils.htmlToExcel("exportMenuCategory", "Menu Category", false);
              this.loading=false
            }, 100);
          }
        });
    },
    exportExcel() {
      this.loading = true;
      this.getAllCategories();
    },
  }
};
</script>
