<template>
  <v-container class="pa-4 pa-sm-8" fluid>
    <v-row class="mt-3 mt-sm-0">
      <h2 class="black--text text-h5 font-weight-bold ms-3">
        Meals Category
      </h2>
      <v-layout justify-end class="me-4 mt-6 mt-sm-0">
        <app-category-form
          v-if="hasRole(AdminUserPermissions.MEALS_CATEGORY_ADD)"
          class="me-2 me-md-0"
        ></app-category-form>
        <AuditTrialLog class="ms-md-4" auditType="CATEGORY_LOG" />
      </v-layout>
    </v-row>
    <v-layout justify-end class="mt-5">
      <ExportMenuCategory :headers="headers" />
    </v-layout>
    <v-system-bar height="1" class="mt-10"></v-system-bar>
    <v-data-table
      :mobile-breakpoint="0"
      :headers="headers"
      :items="itemsWithSno"
      class="elevation-1"
      :items-per-page="25"
      :options.sync="options"
      :loading="loading"
      :server-items-length="totalItemsCount"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
    >
      <template v-slot:[`item.active`]="{ item }">
        <!-- <v-checkbox color="primary" disabled-color="primary" v-model="item.active" disabled></v-checkbox> -->
        <v-icon v-show="item.active" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.active" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:[`item.pcValueNotRequired`]="{ item }">
        <v-icon v-show="item.pcValueNotRequired" color="primary"
          >mdi-checkbox-marked</v-icon
        >
        <v-icon v-show="!item.pcValueNotRequired" color="grey"
          >mdi-checkbox-blank-outline</v-icon
        >
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-row>
          <app-category-update-form
            v-if="hasRole(AdminUserPermissions.MEALS_CATEGORY_EDIT)"
            :categoryInfo="item"
          ></app-category-update-form>
          <app-category-delete
            v-if="hasRole(AdminUserPermissions.MEALS_CATEGORY_DELETE)"
            :category="item"
          />
        </v-row>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import CategoryUpdateForm from "../components/CategoryUpdateForm.vue";
import CategoryForm from "../components/CategoryForm";
import CategoryDelete from "../components/CategoryDelete";
import ExportMenuCategory from "../components/ExportMenuCategory.vue";
import AuditTrialLog from "@/audit-trial-log/components/AuditTrialLog.vue";
export default {
  components: {
    "app-category-update-form": CategoryUpdateForm,
    "app-category-form": CategoryForm,
    "app-category-delete": CategoryDelete,
    AuditTrialLog,
    ExportMenuCategory
  },
  data() {
    return {
      categoriesList: [],
      totalItemsCount: 25,
      variables: {
        limit: 25,
        skip: 0
      },
      options: {
        itemsPerPage: 25,
        page: 1
      },
      loading: true,
      headers: [
        {
          text: "#",
          value: "slNo",
          sortable: false
        },
        { text: "Name", value: "nameEn", sortable: false },
        { text: "Name Ar", value: "nameAr", sortable: false },
        { text: "Active", value: "active", sortable: false },
        { text: "Hide P/C", value: "pcValueNotRequired", sortable: false },
        { text: "Show Order", value: "showOrder", sortable: false },
        { text: "Actions", value: "actions", sortable: false }
      ]
    };
  },
  apollo: {
    getAllCategories() {
      return {
        query: require("@/menu-packages/api/getAllCategories.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          this.loading = loading;
          this.categoriesList = data.getAllCategories.category;
          this.totalItemsCount = data.getAllCategories.totalCount;
        }
      };
    }
  },
  watch: {
    options: {
      handler() {
        (this.variables.limit = this.options.itemsPerPage),
          (this.variables.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getAllCategories)
          this.$apollo.queries.getAllCategories.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 500,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage
          });
      },
      deep: true
    }
  },
  computed: {
    itemsWithSno() {
      if (this.categoriesList == null) return [];
      return this.categoriesList.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  },
  methods: {},
  created() {
    if (!this.hasRole(this.AdminUserPermissions.MEALS_CATEGORY_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  }
};
</script>
