<template>
  <div>
    <v-container class="pa-4 pa-sm-8" fluid>
      <v-row class="mt-3 mt-sm-0">
        <h2 class="black--text text-h5 font-weight-bold ms-3">Diet Plan</h2>
        <!-- <v-spacer></v-spacer> -->
        <v-layout justify-end class="me-3">
          <ExportDietPlan class="mr-5" :headers="headers" />

          <CreateDietPlan
            v-if="hasRole(AdminUserPermissions.DIET_PLAN_ADD)"
          ></CreateDietPlan>
        </v-layout>
      </v-row>

      <v-data-table
        :mobile-breakpoint="0"
        :headers="headers"
        :items="itemsWithSno"
        class="elevation-1 mt-10"
        :loading="loading"
      >
        <template v-slot:item.active="{ item }">
          <v-icon v-show="item.active" color="primary"
            >mdi-checkbox-marked</v-icon
          >
          <v-icon v-show="!item.active" color="grey"
            >mdi-checkbox-blank-outline</v-icon
          >
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <!-- {{ item }} -->
            <UpdateDietPlan
              v-if="hasRole(AdminUserPermissions.DIET_PLAN_EDIT)"
              :dietPlan="item"
            />
            <DeleteDietPlan
              v-if="hasRole(AdminUserPermissions.DIET_PLAN_DELETE)"
              :dietPlan="item"
            />
          </v-row>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>

<script>
import CreateDietPlan from "../components/CreateDietPlan.vue";
import DeleteDietPlan from "../components/DeleteDietPlan.vue";
import UpdateDietPlan from "../components/UpdateDietPlan.vue";
import ExportDietPlan from "../components/ExportDietPlan.vue";
export default {
  components: {
    CreateDietPlan,
    UpdateDietPlan,
    DeleteDietPlan,
    ExportDietPlan
  },
  // mounted() {
  //   this.$root.$on("updateDietPlan", () => {
  //     this.loading = true;
  //     this.$apollo.queries.getAllDietPlans.refetch({});
  //   });
  // },
  data() {
    return {
      loading: false,
      dietPlans: [],
      headers: [
        {
          text: "#",
          sortable: false,
          value: "slNo",
          width: 120
        },
        { text: "Diet Plan En", value: "nameEn", width: 230 },
        { text: "Diet Plan Ar", value: "nameAr", width: 230 },
        { text: "Active", value: "active", width: 90 },
        { text: "Sort Order", value: "showOrder", sortable: false, width: 90 },
        { text: "Action", value: "actions", sortable: false, width: 90 }
      ]
    };
  },
  apollo: {
    getAllDietPlans() {
      return {
        query: require("../api/getAllDietPlans.graphql"),
        variables: this.variables,
        result({ data, loading }) {
          console.log(data)
          this.dietPlans = data.getAllDietPlans
          this.loading = loading;
        }
      };
    }
  },
  created() {
    if (!this.hasRole(this.AdminUserPermissions.DIET_PLAN_VIEW)) {
      this.$router.push({
        name: "Error404"
      });
    }
  },
  computed: {
    itemsWithSno() {
      return this.dietPlans.map((d, index) => ({ ...d, slNo: index + 1 }));
    }
  }
};
</script>
