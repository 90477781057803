<template>
  <v-container class="px-0 mx-0">
    <v-btn
      @click="exportExcel"
      width="150"
      :loading="loading"
      class="mt-n3 white--text text-capitalize btn_fnt"
      color="primary"
    >
      <v-icon small class="me-1" color="white">mdi-export</v-icon>Export
    </v-btn>
    <div v-show="false">
      <v-data-table
        id="exportSalesOrder"
        hide-default-footer
        :items-per-page="-1"
        :headers="headers"
        :items="salesOrderList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.date`]="{ item }">{{
          new Date(parseInt(item.created_At)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.startDate`]="{ item }">{{
          new Date(parseInt(item.start_date)) | moment("MMM DD YYYY")
        }}</template>
        <template v-slot:[`item.endDate`]="{ item }">{{
          new Date(parseInt(item.end_date)) | moment("MMM DD YYYY")
        }}</template>
        <!-- <template v-slot:[`item.package_price`]="{ item }">{{
        numberWithCommas(item.package_price)
      }}</template>
      <template v-slot:[`item.discount`]="{ item }">{{
        numberWithCommas(item.discount)
      }}</template>
      <template v-slot:[`item.net_price`]="{ item }"
        >{{ numberWithCommas(item.net_price) }}
      </template> -->
        <template v-slot:[`item.phone`]="{ item }"
          >{{ item.user ? item.user.phone : "--" }}
        </template>
        <!-- <template v-slot:[`item.plan`]="{ item }"
        >{{ item.package ? item.package.titleEn : "--" }}
      </template> -->
        <!-- <template v-slot:[`item.days`]="{ item }">
        <span v-if="item.package"
          >{{
            item.package.availableChoices.length
              ? item.package.availableChoices[0].availableNumOfDays
              : ""
          }}
        </span>
      </template>
      <template v-slot:[`item.total`]="{ item }">
        <PriceSplitup :subscribedPackage="item" />
      </template> -->
        <template v-slot:[`item.paymentStatus`]="{ item }">
          <span
            :class="
              item.payment_status == 'SUCCESS'
                ? 'green--text'
                : item.payment_status == 'PENDING'
                ? 'orange--text'
                : item.payment_status == 'UPCOMING'
                ? 'blue--text'
                : item.payment_status == 'EXPIRED'
                ? 'red--text'
                : ''
            "
          >
            {{ item.payment_status }}</span
          >
        </template>
        <!-- <template v-slot:[`item.referredUser`]="{ item }">
        <span v-if="item.referredByUser">
          {{ toTitleCase(item.referredByUser.firstnameEn) }}
          {{ toTitleCase(item.referredByUser.lastnameEn) }}
        </span>
        <span v-else>--</span>
      </template>
      <template v-slot:[`item.referredUserRole`]="{ item }">
        <span v-if="item.referredByUser">
          {{ item.referredByUser.role }}
        </span>
        <span v-else>--</span>
      </template> -->
        <!-- :item-class="itemRowBackground" -->
        <template v-slot:[`item.customer`]="{ item }">
          <span v-if="item.user">
            <router-link
              v-if="!item.is_deleted"
              :to="{
                name: 'CustomerDetails',
                params: { customerId: item.customer },
                query: { customerId: item.customer }
              }"
              class="mx-auto"
            >
              {{ toTitleCase(item.user.firstnameEn) }}
              {{ toTitleCase(item.user.lastnameEn) }}
              <!-- <div class="mx-1" /> -->
            </router-link>

            <span v-else>
              <!-- <div class="mx-1" /> -->
              {{ toTitleCase(item.user.firstnameEn) }}
              {{ toTitleCase(item.user.lastnameEn) }}
            </span>
          </span>
          <!-- </v-row> -->
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>
<script>
import { Utils } from "../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    },
    fDate: {
      required: true
    },
    tDate: {
      required: true
    }
  },
  data() {
    return {
      salesOrderList: [],
      dayData: "",
      typeData: "",
      loading: false,
      variables: {
        limit: -1,
        skip: 0,
        startDate: "",
        endDate: ""
      }
    };
  },

  created() {
    // this.getAllSubscribedPackages();
  },

  methods: {
    toTitleCase(str) {
      if (!str) return "";
      return str.replace(/\w\S*/g, function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    },
    getAllSubscribedPackages() {
      this.variables.startDate = this.fDate;
      this.variables.endDate = this.tDate;
      this.loading = true;
      this.$apollo
        .watchQuery({
          query: require("../api/getAllInactiveSubscribedPackagesWithDate.graphql"),
          variables: this.variables
        })
        .refetch({ variables: this.variables })
        .then(data => {
          console.log(data);
          this.salesOrderList =
            data.data.getAllInactiveSubscribedPackagesWithDate.sales_orders;
          this.loading = false;

          setTimeout(() => {
            this.loading = false;
            Utils.htmlToExcel("exportSalesOrder", "Sales Order List", false);
          }, 1000);
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
    },

    exportExcel() {
      this.getAllSubscribedPackages();
    },
    numberWithCommas(x) {
      if (!x) return 0;
      return x
        .toFixed(3)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
  }
};
</script>
