<template>
  <div>
    <v-dialog v-model="dialog" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          color="secondary"
          class="black--text text-capitalize font-weight-bold"
        >
          Update Credentials
        </v-btn>
      </template>
      <v-card class="pa-3">
        <v-card-title>
          <span class="text-h6 primary--text"> Credentials</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-card-title>
        <v-divider></v-divider>
        <v-form
          class="pa-sm-8 mt-n6"
          @submit="updateConfigurations()"
          v-model="valid"
          ref="form"
        >
          <v-card-text>
            <v-container>
              <!-- {{ splashScreenContent }} -->
              <v-layout wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">Gateway Private Key (TAP)</h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md10 class="mt-5 mt-md-0">
                  <v-text-field
                    outlined
                    dense
                    name="input-7-4"
                    label="key"
                    v-model="gatewayKey"
                    :rules="[requiredValidator('key')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap>
                <v-flex xs12 md8>
                  <h4 class="font-weight-medium">
                    SMS Url - Place Holders: [MOBILE], [MESSAGE]
                  </h4>
                </v-flex>
              </v-layout>
              <v-layout class="mt-4" wrap>
                <v-flex xs12 sm8 md10 class="mt-5 mt-md-0">
                  <v-text-field
                    outlined
                    dense
                    name="input-7-4"
                    label="sms url"
                    v-model="smsURL"
                    :rules="[requiredValidator('sms url')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions class="mb-sm-n4 mt-n14">
            <!-- <v-spacer></v-spacer> -->
            <v-layout justify-center>
              <v-btn
                color="primary"
                :width="$vuetify.breakpoint.smAndUp ? 120 : 90"
                :loading="btnloading"
                @click="updateConfigurations"
                :disabled="!valid"
                >Save</v-btn
              >
            </v-layout>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top :color="color">{{
      text
    }}</v-snackbar>
    <v-snackbar
      v-model="snackbarError"
      timeout="3000"
      right
      top
      color="error"
      >{{ text }}</v-snackbar
    >
  </div>
</template>

<script>
import { requiredValidator } from "../../core/methods/validations";
export default {
  data() {
    return {
      requiredValidator: requiredValidator,
      dialog: false,
      snackbarError: false,
      btnloading: false,
      color: "success",
      snackbar: false,
      text: "",
      valid: true,
      gatewayKey: "",
      smsURL: ""
    };
  },
  apollo: {
    getAppConfigurations() {
      return {
        query: require("../api/getAppConfigurations.graphql"),
        result({ data, loading }) {
          this.loading = loading;
          if (data.getAppConfigurations.paymentGateway) {
            this.gatewayKey = data.getAppConfigurations.paymentGateway.apiToken;
          }
          if (data.getAppConfigurations.smsGateway) {
            this.smsURL = data.getAppConfigurations.smsGateway.url;
          }
        }
      };
    }
  },
  methods: {
    updateConfigurations() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("../api/updateConfigurations.graphql"),
          variables: {
            paymentGateway: {
              apiToken: this.gatewayKey
            },
            smsGateway: {
              url: this.smsURL
            }
          },
          update: () => {
            try {
              // this.$root.$emit("updateBlock");
              this.color = "success";
              this.text = "Splash Screen Content Updated";
              this.snackbar = true;
            } catch (e) {
              console.log(e);
              console.log("Something bad happend");
            }
            this.btnloading = false;
            this.dialog = false;
          }
        })
        .catch(error => {
          this.btnloading = false;
          this.text = error;
          this.color = "error";
          this.snackbarError = true;

          console.log(error);
        });
    },
    close() {
      this.dialog = false;
    }
  }
};
</script>
