var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-dialog',{attrs:{"persistent":"","max-width":"700px"},model:{value:(_vm.statusDialogBox),callback:function ($$v) {_vm.statusDialogBox=$$v},expression:"statusDialogBox"}},[_c('v-card',{staticClass:"pa-6"},[(_vm.loading)?_c('v-overlay',[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1):_vm._e(),_c('v-card-text',{staticClass:"subtitle-1 mb-0 mt-6 text-center"},[_vm._v("Do you really want to deactivate meal?")]),(_vm.customersUsingMeal[0])?_c('div',[_c('v-card-text',{staticClass:"subtitle-1 mb-0 mt-n6 text-center"},[_vm._v("Customers using this meal")]),_c('v-data-table',{attrs:{"mobile-breakpoint":0,"headers":_vm.headers,"items":_vm.customersUsingMeal,"items-per-page":5},scopedSlots:_vm._u([{key:"item.customerName",fn:function(ref){
var item = ref.item;
return [(item.customer)?_c('router-link',{staticClass:"mx-auto",attrs:{"to":{
                name: 'CustomerDetails',
                query: { customerId: item.customer._id },
              }}},[(item.customer)?_c('span',[(item.customer.user)?_c('span',[_vm._v(" "+_vm._s(item.customer.user.firstnameEn[0].toUpperCase() + item.customer.user.firstnameEn.substring(1))+" "+_vm._s(item.customer.user.lastnameEn[0].toUpperCase() + item.customer.user.lastnameEn.substring(1))+" ")]):_vm._e()]):_vm._e()]):_vm._e()]}},{key:"item.date",fn:function(ref){
              var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(new Date(parseInt(item.selected_menu.date)),"DD MMM YYYY")))]}},{key:"item.phone",fn:function(ref){
              var item = ref.item;
return [(item.customer)?_c('span',[(item.customer.user)?_c('span',[_vm._v(" "+_vm._s(item.customer.user.phone)+" ")]):_vm._e()]):_vm._e()]}}],null,false,2101238473)})],1):_vm._e(),_c('v-card-actions',{attrs:{"justify":"center"}},[_c('v-radio-group',{model:{value:(_vm.disableStatus),callback:function ($$v) {_vm.disableStatus=$$v},expression:"disableStatus"}},[_c('v-radio',{attrs:{"disabled":_vm.disableSelection,"label":"Disable for future selection only?","value":"DISABLE"}}),_c('v-radio',{attrs:{"disabled":_vm.disableSelection,"value":"DISABLE_WITH_REMOVE","label":"Remove all selected meals from customer list for this date?"}})],1)],1),_c('v-spacer'),_c('v-card-actions',{attrs:{"justify":"center"}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-btn',{attrs:{"color":" mx-2 success","depressed":""},on:{"click":_vm.close}},[_vm._v("No")]),_c('v-btn',{attrs:{"color":" mx-2 primary","depressed":"","loading":_vm.button_loading},on:{"click":function($event){_vm.button_loading = true;
              _vm.confirmChange();}}},[_vm._v("Yes")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }