<template>
  <div>
    <v-dialog v-model="show_dialog_box" max-width="800px">
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          class="white--text text-capitalize font-weight-bold"
          color="primary"
        >
          <v-icon small class="me-1">mdi-plus</v-icon> Add
        </v-btn>
      </template>
      <v-card>
        <v-card-title>
          <span class="text-h6 primary--text">Brand</span>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container class="mt-2">
            <v-form v-model="valid" ref="form">
              <v-layout wrap>
                <v-flex xs12 sm4> Brand Name (English) </v-flex>

                <v-flex xs12 sm8>
                  <v-text-field
                    dense
                    outlined
                    name="input-7-4"
                    label="name english"
                    v-model="nameEn"
                    :rules="[requiredValidator('name')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4> Brand Name (Arabic) </v-flex>

                <v-flex xs12 sm8>
                  <v-text-field
                    dense
                    outlined
                    name="input-7-4"
                    label="name arabic"
                    v-model="nameAr"
                    :rules="[requiredValidator('name')]"
                  ></v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4> Description (English) </v-flex>

                <v-flex xs12 sm8>
                  <v-text-field
                    dense
                    outlined
                    name="input-7-4"
                    label="description english"
                    v-model="descriptionEn"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>

              <v-layout wrap>
                <v-flex xs12 sm4> Description (Arabic) </v-flex>

                <v-flex xs12 sm8>
                  <v-text-field
                    dense
                    outlined
                    name="input-7-4"
                    label="description arabic"
                    v-model="descriptionAr"
                  >
                  </v-text-field>
                </v-flex>
              </v-layout>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="mt-n8 justify-center">
          <v-btn
            color="primary"
            width="150"
            @click="createBrand"
            :loading="btnloading"
            :disabled="!valid"
          >
            SAVE
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="success">
      {{ text }}
    </v-snackbar>
  </div>
</template>

<script>
import { requiredValidator } from "../../../core/methods/validations";

export default {
  data() {
    return {
      btnloading: false,
      nameEn: "",
      nameAr: "",
      descriptionEn: "",
      descriptionAr: "",
      valid: true,
      errors: [],
      snackbar: false,
      text: "",
      question: "",
      answer: "",
      showOrder: 0,
      active: true,
      show_dialog_box: false,
      requiredValidator: requiredValidator,
    };
  },
  methods: {
    close() {
      this.show_dialog_box = false;
    },
    createBrand() {
      this.btnloading = true;
      this.$apollo
        .mutate({
          mutation: require("@/stock/brand/api/createBrand.graphql"),
          variables: {
            nameEn: this.nameEn,
            nameAr: this.nameAr,
            descriptionAr: this.descriptionAr,
            descriptionEn: this.descriptionEn,
          },
          update: (store, { data: { createBrand } }) => {
            try {
              const query = {
                query: require("@/stock/brand/api/listAllBrands.graphql"),
                variables: {
                  limit: 25,
                  skip: 0,
                },
              };

              let allBrands = store.readQuery(query);

              allBrands.listAllBrands.unshift(createBrand); //push to top
              store.writeQuery({
                ...query,
                data: allBrands,
              });

              this.text = "Brand Added";
              this.snackbar = true;
              this.btnloading = false;
              this.show_dialog_box = false;
              this.$refs.form.reset();
            } catch (e) {
              console.log("Something bad happend- FaqForm.vue");
              console.log(e);
              this.btnloading = false;
            }

            this.show_dialog_box = false;
            this.btnloading = false;
          },
        })

        .catch((error) => {
          if (error.graphQLErrors != null) {
            console.error(error.graphQLErrors);
          }
          this.btnloading = false;
          this.show_dialog_box = false;
        });
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
