<template>
  <v-container class="px-0 mx-0">

    <v-layout justify-end>
      <v-btn @click="exportExcel" width="180" :loading="loading" class=" white--text text-capitalize btn_fnt"
        color="primary">
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table id="exportCustomerList" hide-default-footer :items-per-page="-1" :headers="headers"
        :items="customerList" class="elevation-1 mt-6">
        <template v-slot:[`item.customerId`]="{ item }">
          {{ padLeft(item.customerId, 4) }}
        </template>
        <template v-slot:[`item.name`]="{ item }">
          {{
    item.user.firstnameEn[0].toUpperCase() +
    item.user.firstnameEn.substring(1)
}}
          <!-- <div class="mx-1" /> -->
          {{
    item.user.lastnameEn[0].toUpperCase() +
    item.user.lastnameEn.substring(1)
}}
          <!-- </v-row> -->
        </template>
        <template v-slot:[`item.tag`]="{ item }">
          <span v-for="tag in item.tags" :key="tag._id">
            {{ tag.name }}<br />
          </span>
        </template>

        <template v-slot:[`item.area`]="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="area" />
        </template>

        <template v-slot:[`item.block`]="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="block" />
        </template>

        <template v-slot:[`item.building`]="{ item }">
          <CustomerAddressColumn :address="defaultAddress(item.addressDetails)" type="building" />
        </template>

      </v-data-table>
    </div>
  </v-container>
</template>
  
<script>
import { requiredValidator } from "../../../core/methods/validations";
import { DATE_FORMAT, Utils } from "../../../utils/utils";
import moment from "moment";
import CustomerAddressColumn from "../../components/CustomerAddressColumn.vue";
export default {
  components: {
    CustomerAddressColumn,
  },
  props: {
    dob: {
      required: true
    },
    type: {
      required: true
    }
  },
  data() {
    return {
      snackbar: false,
      text: "",
      snackbarColor: "",
      valid: true,
      dialog: false,
      btnloading: false,
      loading: false,
      show_alert: false,
      error: null,
      requiredValidator,
      startDate: "",
      endDate: "",
      menuStartDate: false,
      menuEndDate: false,
      customerList: [],
      areas: [],
      areasLoading: false,
      blocks: [],
      headers: [
        {
          text: "#",
          value: "customerId",
          width: 100
        },
        { text: "Customer Name", value: "name", width: 150 },
        { text: "Tag", value: "tag", width: 100, sortable: false },
        {
          text: "Contact Number",
          value: "user.phone",
          width: 170,
          sortable: false
        },

        { text: "Area", value: "area", width: 150, sortable: false },
        {
          text: "Block, Street, Jedha",
          value: "block",
          width: 220,
          sortable: false
        },
        { text: "Building", value: "building", width: 200, sortable: false },

      ]
    };
  },
  methods: {
    customersList() {
      let variables = {
        dob: this.dob,
        type: this.type,
        skip: 0,
        limit: 0
      };
      this.$apollo
        .query({
          query: require("@/customer/api/birthdayReport.graphql"),
          variables
        })
        .then(async data => {
          if (data.data) {
            this.customerList = await data.data.birthdayReport
              .customers;

            this.customerList.forEach(element => {
              if (element.addressDetails) {
                element.addressDetails.forEach(address => {
                  if (address.area) {
                    let area = this.areas.find(x => x._id == address.area._id);
                    address.area = area;
                  }
                  if (address.block) {
                    let block = this.blocks.find(x => x._id == address.block._id);
                    address.block = block;
                  }
                });
              }
            });
            if (this.customerList.length) {
              setTimeout(() => {
                Utils.htmlToExcel(
                  "exportCustomerList",
                  "Birthday Report",
                  false
                );
              }, 100);
              this.dialog = false;
            } else {
              this.snackbarColor = "red";
              this.text = "No Data is available on selected dates";
              this.snackbar = true;
            }
            this.btnloading = false;
            return true;
          }
          this.snackbarColor = "red";
          this.text = "some error";
          this.snackbar = true;
          return false;
        });
    },
    close() {
      this.error = null;
      this.dialog = false;
      this.$refs.form.reset();
      this.btnloading = false;
    },
    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    async exportExcel() {
      this.btnloading = true;
      await this.customersList();

    },
    defaultAddress(addresses) {
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          return defaultAddress;
        } else {
          return addresses[0];
        }
      }
    },
    defaultArea(addresses) {
      debugger
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          return defaultAddress.area.nameEn;
        } else {
          return addresses[0].area.nameEn;
        }
      }
    },
    defaultBuilding(addresses) {
      let address = "";
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          address = `${defaultAddress.house_number}, ${defaultAddress.building_number}, ${defaultAddress.apartment_number}`;
          return address;
        } else {
          address = `${addresses[0].house_number}, ${addresses[0].building_number}, ${addresses[0].apartment_number}`;
          return address;
        }
      }
    },
    defaultBlock(addresses) {
      let address = "";
      if (addresses.length > 0) {
        let defaultAddress = addresses.find(x => x.is_default == true);
        if (defaultAddress) {
          address = `${defaultAddress.block.block}, ${defaultAddress.street}, ${defaultAddress.jedha}`;
          return address;
        } else {
          address = `${addresses[0].block.block}, ${addresses[0].street}, ${addresses[0].jedha}`;
          return address;
        }
      }
    },
    createdDate(date) {
      return date ? moment(parseInt(date)).format(DATE_FORMAT) : "";
    },

    getAllAreas() {
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getAllAreas.graphql"),
          variables: {
            searchKey: ""
          }
        })
        .refetch({
          variables: {
            searchKey: ""
          }
        })
        .then(data => {
          let areas = data.data.getAllAreas.areas;
          areas = areas.sort((a, b) =>
            a.nameEn > b.nameEn ? 1 : b.nameEn > a.nameEn ? -1 : 0
          );

          this.areas = areas;
          this.areasLoading = false;
        });
    },
    getAllBlocks() {
      this.$apollo
        .watchQuery({
          query: require("@/customer/api/getAllBlocks.graphql"),
          variables: {
            searchKey: "",
            skip: 0,
            limit: 0
          }
        })
        .refetch({
          variables: {
            searchKey: "",
            skip: 0,
            limit: 0
          }
        })
        .then(data => {
          let blocks = data.data.getAllBlocks.blocks;
          blocks = blocks.sort((a, b) =>
            a.block > b.block ? 1 : b.block > a.block ? -1 : 0
          );

          this.blocks = blocks;
        });
    },
  },
  created() {
    this.getAllAreas();
    this.getAllBlocks();
  },
  computed: {
    formatStartDate() {
      return this.startDate ? moment(this.startDate).format(DATE_FORMAT) : "";
    },
    formatEndDate() {
      return this.endDate ? moment(this.endDate).format(DATE_FORMAT) : "";
    },
    startDateMinDates() {
      return this.endDate
        ? moment(this.endDate)
          .subtract(1, "month")
          .format("YYYY-MM-DD")
        : "";
    },
    startDateMaxDates() {
      let today = moment().format("YYYY-MM-DD");
      return this.endDate ? moment(this.endDate).format("YYYY-MM-DD") : today;

    },
    endDateMaxDates() {
      let today = moment();
      if (this.startDate) {
        let day = moment(this.startDate).add(1, "month");
        if (day > today) {
          return today.format("YYYY-MM-DD");
        } else {
          return day.format("YYYY-MM-DD");
        }
      } else {
        return today.format("YYYY-MM-DD");
      }
    },
    endDateMinDates() {
      return this.startDate ? moment(this.startDate).format("YYYY-MM-DD") : "";
    }
  }
};
</script>
