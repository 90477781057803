<template>
  <v-container>
    <v-tabs
      center-active
      v-model="tabs"
      background-color="#F8ECC4"
      slider-size="44"
      slider-color="primary"
    >
      <v-tab
        class="
          font-weight-bold
          text-caption text-sm-body-2
          dark--text 
          text-capitalize 
          white
          elevation-2
          mb-1
          rounded-lg
        "
        :key="0"
        :class="{ 'font-weight-bold white--text': tabs === 0 }"
        ><label style="cursor: pointer" class="custom_slider"
          >Invoice</label
        ></v-tab
      >
      <v-tab
        class="
          font-weight-bold 
          text-caption text- sm-body-2
          dark--text     
          text-capitalize
          white
          elevation-2
          mb-1
          ms-4
          rounded-lg
        "
        :class="{ 'font-weight-bold white--text ': tabs === 1 }"
        :key="1"
        ><label style="cursor: pointer" class="custom_slider"
          >Refund</label
        ></v-tab
      >
    </v-tabs>

    <v-tabs-items v-model="tabs">
      <v-tab-item :key="0">
        <InvoiceTable />
      </v-tab-item>
      <v-tab-item :key="1">
        <CreditNoteTable />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import InvoiceTable from "./InvoiceTable.vue";
import CreditNoteTable from "./CreditNoteTable.vue";
export default {
  components: {
    CreditNoteTable,
    InvoiceTable
  },
  data: () => ({
    tabs: "",
    showInward: true,
    showOutward: false,
    showIngredient: false
  })
};
</script>
<style>
/* .theme--light.v-btn--active:hover::before,
.theme--light.v-btn--active::before {
  color: rgb(0,171,200) !important; 
} */
.custom_slider {
  z-index: 1 !important;
}

.v-tabs-slider {
  border-radius: 7px;
  margin-top: -3px;
}
</style>
