<template>
  <v-container class="pa-8">
    <v-layout class="mb-2">
      <v-flex>
        <h2 class="primary--text font-weight-bold">Brand</h2>
      </v-flex>
      <v-spacer></v-spacer>
      <BrandForm />
    </v-layout>
    <!-- {{ faqList }} -->
    <BrandTable :brandList="brandList" :loading="loading"></BrandTable>
  </v-container>
</template>

<script>
import BrandForm from "../components/BrandForm.vue";
import BrandTable from "../components/BrandTable.vue";

export default {
  components: {
    BrandTable,
    BrandForm,
  },
  methods: {},
  data: () => ({
    loading: true,
    brandList: [],
    totalItemsCount: 25,
    variables: {
      limit: 25,
      skip: 0,
    },
  }),
  apollo: {
    listAllBrands() {
      // console.log("djfd");
      return {
        query: require("@/stock/brand/api/listAllBrands.graphql"),

        result({ data, loading }) {
          this.loading = loading;
          this.brandList = data.listAllBrands;
        },
      };
    },
  },
};
</script>