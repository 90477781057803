<template>
  <v-container fluid class="px-0">
    <!-- <v-overlay :value="btnloading">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay> -->

    <v-layout class="mt-2 ms-n5 ps-5 ps-sm-0" wrap>
      <v-flex xs8 sm3 md3 lg2 class="mt-5 mt-sm-0 ms-sm-5 me-5">
        <v-menu v-model="menu" :close-on-content-click="false" max-width="290">
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              :value="formatDate"
              readonly
              label="Date"
              solo
              append-icon="fa-calendar-alt black--text"
              v-bind="attrs"
              v-on="on"
              dense
            ></v-text-field>
          </template>

          <v-date-picker
            color="secondary"
            v-model="date"
            @change="menu = false"
          ></v-date-picker>
        </v-menu>
      </v-flex>
      <v-flex xs8 sm3 md2 lg2>
        <v-select
          :items="deliveryStatuses"
          v-model="deliveryStatus"
          item-value="value"
          item-text="text"
          filled
          label="All"
          solo
          dense
          attach
        ></v-select>
      </v-flex>
      <v-flex xs8 sm3 md1 lg2 class="ml-2">
        <v-select
          :items="['All', 'MORNING', 'EVENING']"
          v-model="deliveryShift"
          item-value="value"
          item-text="text"
          filled
          label="All"
          solo
          dense
          attach
        ></v-select>
      </v-flex>
      <v-flex xs6 sm4 md3 lg2 class="mt-md-0 ms-sm-5 ms-md-0">
        <v-btn
          v-on="on"
          class="ms-sm-5 black--text text-capitalize"
          color="secondary"
          width="150"
          @click="searchDelivery"
          >Search
        </v-btn>
      </v-flex>
      <v-flex xs5 sm4 md2 lg2 xl1 class="mt-md-0 ms-sm-5 ms-md-0 ms-xl-n16">
        <ErrorLog class="mt-n2 ms-n1 mb-sm-6 mb-md-0" :errorLog="errorLog" />
      </v-flex>
      <v-flex xs5 sm4 md1 lg1 xl1 class="mt-md-0 ms-sm-5 ms-md-0 ms-xl-n16">
        <ReportLog :date="date" :quickView="false"></ReportLog>
      </v-flex>
    </v-layout>
    <v-layout wrap>
      <v-flex
        xs12
        sm9
        md9
        lg7
        class="mt-10 mt-sm-0 ms-sm-5 ms-lg-0 me-5 ps-3 ps-sm-0 mb-5 mb-sm-0"
        v-if="reportGeneratedDate"
      >
        <v-row class="ms-lg-1">
          <v-flex xs12 sm9 md7 lg7 class="text-body-1 black--text">
            Report generated on
            {{
              getDate(new Date(parseInt(reportGeneratedDate)))
                | moment("MMM DD YYYY HH:mm a")
            }}
          </v-flex>
          <v-flex xs6 sm3 md3 lg4 class="ms-lg-n2 ms-xl-n3">
            <v-btn
              v-if="hasRole(this.AdminUserPermissions.GENERATE_ITEMS)"
              class="ms-md-3 ms-lg-10 mt-3 mt-sm-0 black--text text-capitalize"
              color="secondary"
              width="150"
              :loading="btnloading"
              @click="isAllowToGenerateReport"
              >ReGenerate
            </v-btn>
          </v-flex>
        </v-row>
      </v-flex>
      <v-flex
        xs12
        sm9
        md9
        lg7
        class="mt-4 mt-sm-0 ms-sm-5 ms-lg-0 me-5 ps-3 ps-sm-0"
        v-else
      >
        <v-row class="ms-lg-1">
          <v-flex xs12 sm9 md7 lg7 class="text-body-1 black--text">
            Report not generated
          </v-flex>
          <v-flex xs6 sm3 md3 lg4 class="ms-lg-n3 ms-xl-n4">
            <v-btn
              v-if="hasRole(this.AdminUserPermissions.GENERATE_ITEMS)"
              color="secondary"
              class="md-md-3 ms-lg-10 ms-lg-11 mt-3 mt-sm-0 black--text text-capitalize px-8"
              width="150"
              @click="isAllowToGenerateReport"
              >Generate
            </v-btn>
          </v-flex>
        </v-row>
      </v-flex>
    </v-layout>
    <v-layout wrap class="mt-12 ms-3">
      <v-flex xs12 md12 lg6 xl7 class="pe-xl-16">
        <DownloadReports
          :deliveryDate="date"
          :headers="headers"
          :deliveryStatus="deliveryStatus"
          :hasData="pendingList.length"
          :deliveryShift="deliveryShift"
        />
      </v-flex>
    </v-layout>
    <!-- <v-divider class="mt-12 mb-10"></v-divider> -->

    <!-- <v-layout wrap justify-sm-end class="ms-sm-n3 ms-sm-0 mt-6 d-flex">
      <DeliverPdf
        :pendingList="pendingList"
        :date="date"
        :disabled="pendingList.length == 0"
        class="me-7 me-sm-3"
      />

      <DeliveryOrderPdf
        :pendingList="pendingList"
        :date="date"
        :disabled="pendingList.length == 0"
        class="me-2 me-sm-3"
      />

      <DeliveryExport :pendingList="pendingList" :headers="headers" />
    </v-layout> -->
    <v-data-table
      :mobile-breakpoint="0"
      v-model="selected"
      id="myTable"
      :headers="headers"
      :items="itemsWithSno"
      :loading="loading"
      item-key="slNo"
      :options.sync="options"
      class="elevation-1 mt-6"
      :server-items-length="totalCount"
      :items-per-page="25"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 500] }"
      :search="search"
    >
      <template v-slot:item.order_id="{ item }">
        #{{ padLeft(item.order_id, 4) }}
      </template>
      <template v-slot:item.customerName="{ item }">
        <span v-if="item.customer">
          {{
            item.customer.user.firstnameEn[0].toUpperCase() +
            item.customer.user.firstnameEn.substring(1)
          }}
          {{
            item.customer.user.lastnameEn[0].toUpperCase() +
            item.customer.user.lastnameEn.substring(1)
          }}
        </span>
      </template>
      <template v-slot:item.customerId="{ item }">
        #{{ padLeft(item.customer.customerId, 4) }}
      </template>
      <template v-slot:item.tags="{ item }">
        <span v-if="item.customer.tags">
          <v-row v-for="tag in item.customer.tags" :key="tag._id">
            {{ tag.name }}
          </v-row>
        </span>
      </template>
      <template v-slot:item.area="{ item }">
        <CustomerAddressColumn :addresses="item.address" type="area" />
      </template>

      <template v-slot:item.block="{ item }">
        <CustomerAddressColumn :addresses="item.address" type="block" />
      </template>

      <template v-slot:item.building="{ item }">
        <CustomerAddressColumn :addresses="item.address" type="building" />
      </template>
      <template v-slot:item.deliveryDate="{ item }">
        {{ getDate(new Date(parseFloat(item.date))) | moment("MMM DD YYYY") }}
      </template>
      <template v-slot:item.startDate="{ item }">
        {{
          getDate(new Date(item.subscribedPackage.start_date))
            | moment("MMM DD YYYY")
        }}
      </template>
      <template v-slot:item.endDate="{ item }">
        {{
          getDate(new Date(item.subscribedPackage.end_date))
            | moment("MMM DD YYYY")
        }}
      </template>
      <template v-slot:item.shift="{ item }">
        {{ item.shift }}
      </template>
      <template v-slot:item.agent="{ item }">
        <span v-if="item.driver">
          {{ item.driver.user.firstnameEn }} {{ item.driver.user.lastnameEn }}
        </span>
      </template>
      <template v-slot:item.status="{ item }">
        {{ getDeliveryStatus(item.delivery_status) }}
      </template>
      <template v-slot:item.statusUpdatedTime="{ item }">
        <span v-if="item.statusUpdatedTime">
          {{
            getDate(new Date(item.statusUpdatedTime))
              | moment("MMM DD YYYY hh:mm")
          }}
        </span>
      </template>
    </v-data-table>

    <v-dialog v-model="showErrorDialog" max-width="500">
      <v-card class="pa-3">
        <v-row>
          <v-card-title class="text-h6 primary--text mt-n4"
            >Meals Log</v-card-title
          >
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="showErrorDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-divider></v-divider>
        <v-spacer></v-spacer>
        <ul class="mt-5" v-if="errorLog.length > 0">
          <li v-for="item in errorLog" :key="item">{{ item }}</li>
        </ul>
        <div v-else>All meals are generated successfully!</div>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snackbar" timeout="3000" right top color="error">{{
      text
    }}</v-snackbar>

    <v-snackbar v-model="snackbarReport" timeout="3000" right top :color="black"
      >Please check Log after some time to know the report status</v-snackbar
    >
  </v-container>
</template>

<script>
// import DeliverPdf from "../components/DeliveryPdf.vue";
// import DeliveryOrderPdf from "../components/DeliveryOrderPdf.vue";
// import DeliveryExport from "../components/DeliveryExport.vue";
import DownloadReports from "./DownloadReports.vue";

import ErrorLog from "../components/ErrorLog.vue";
import ReportLog from "../components/ReportLog.vue";
import CustomerAddressColumn from "../components/AddressColumn.vue";
import moment from "moment";
import { tz } from "moment-timezone";
import { DATE_FORMAT, TIME_ZONE } from "../../utils/utils";
import { apolloClient } from "../../vue-apollo-report";

export default {
  components: {
    CustomerAddressColumn,
    DownloadReports,
    ErrorLog,
    ReportLog,
  },
  data() {
    return {
      on: "",
      snackbar: false,
      snackbarReport: false,
      deliveryShift: "MORNING",
      text: "",
      deliveryStatuses: [
        { text: "All", value: "All" },
        { text: "Pending", value: "PENDING" },
        { text: "Delivered", value: "DELIVERED" },
        { text: "Not Delivered", value: "NOT_DELIVERED" },
      ],
      totalCount: 25,
      skip: 0,
      limit: 25,
      options: {
        itemsPerPage: 25,
        page: 1,
      },
      date: new Date().toISOString().substr(0, 10),
      loading: true,
      pendingList: [],
      nowDate: new Date().toISOString().slice(0, 10),
      showErrorDialog: false,
      errorLog: [],
      deliveryStatus: "All",
      search: "",
      btnloading: false,
      selected: [],
      menu: "",
      tz: tz,
      reportGeneratedDate: "",
      headers: [
        { text: "Q No.", value: "order_id", width: 50, sortable: false },
        {
          text: "Customer Name",
          value: "customerName",
          width: 150,
          sortable: false,
        },
        {
          text: "Customer Id",
          value: "customerId",
          width: 80,
          sortable: false,
        },
        { text: "Tags", value: "tags", sortable: false, width: 80 },
        {
          text: "Mobile",
          value: "customer.user.phone",
          sortable: false,
          width: 100,
        },
        { text: "Zone", value: "zone.zoneName", width: 150, sortable: false },
        { text: "Area", value: "area", width: 150, sortable: false },
        {
          text: "Block, Street, Jedha",
          value: "block",
          width: 220,
          sortable: false,
        },
        { text: "Building", value: "building", width: 200, sortable: false },
        {
          text: "Delivery Date",
          value: "deliveryDate",
          width: 120,
          sortable: false,
        },
        { text: "Start Date", value: "startDate", width: 120, sortable: false },
        { text: "End Date", value: "endDate", width: 120, sortable: false },
        { text: "Delivery Time", value: "shift", sortable: false, width: 150 },
        { text: "Delivery Agent", value: "agent", width: 150, sortable: false },
        {
          text: "Status",
          value: "status",
          width: 60,
          sortable: false,
        },
        {
          text: "Status Updated Time",
          value: "statusUpdatedTime",
          width: 180,
          sortable: false,
        },
      ],
    };
  },
  watch: {
    options: {
      handler() {
        (this.limit = this.options.itemsPerPage),
          (this.skip =
            this.options.page * this.options.itemsPerPage -
            this.options.itemsPerPage);
        if (this.$apollo.queries.getDeliveryDetailsByDate)
          this.$apollo.queries.getDeliveryDetailsByDate.refetch({
            limit:
              this.options.itemsPerPage !== -1
                ? this.options.itemsPerPage
                : 50000,
            skip:
              this.options.page * this.options.itemsPerPage -
              this.options.itemsPerPage,
          });
      },
      deep: true,
    },
  },
  apollo: {
    getDeliveryDetailsByDate() {
      let dateTime = moment().format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      return {
        query: require("../api/getDeliveryDetailsByDate.graphql"),
        variables: {
          status: this.deliveryStatus,
          shift: this.deliveryShift,
          date: modifiedDate,
          skip: this.skip,
          limit: this.limit,
        },
        result({ data, loading }) {
          this.loading = loading;
          this.pendingList = [];
          this.reportGeneratedDate = "";
          this.errorLog = [];
          this.totalCount = [];
          if (data.getDeliveryDetailsByDate) {
            this.pendingList = data.getDeliveryDetailsByDate.orderDelivery;
            this.reportGeneratedDate =
              data.getDeliveryDetailsByDate.generatedDate;
            this.errorLog = data.getDeliveryDetailsByDate.errorLog;
            this.totalCount = data.getDeliveryDetailsByDate.totalCount;
          }
        },
      };
    },
  },
  methods: {
    getDate(date) {
      let converted = moment(date).tz(TIME_ZONE);
      return converted;
    },
    getDeliveryStatus(deliveryStatus) {
      if (deliveryStatus == "PENDING") {
        deliveryStatus = "Pending";
      }
      if (deliveryStatus == "DELIVERED") {
        deliveryStatus = "Delivered";
      }
      if (deliveryStatus == "NOT_DELIVERED") {
        deliveryStatus = "Not Delivered";
      }
      return deliveryStatus;
    },

    padLeft(number, length) {
      var stringValue = "" + number;
      while (stringValue.length < length) {
        stringValue = "0" + stringValue;
      }
      return stringValue;
    },
    generateReport() {
      if (this.btnloading == false) {
        this.snackbarReport = true;
        this.btnloading = true;
        apolloClient
          .mutate({
            mutation: require("../api/generateDeliveryReport.graphql"),
            variables: {
              date: this.date,
              isInstantView: false,
            },
          })
          .then((data) => {
            this.btnloading = false;
            if (data.data.generateDeliveryReport.errors) {
              this.errorLog = data.data.generateDeliveryReport.errors;
              this.showErrorDialog = true;
            }
            this.searchDelivery();
          })
          .catch((error) => {
            this.searchDelivery();
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },

    isAllowToGenerateReport() {
      if (this.btnloading == false) {
        this.btnloading = true;
        this.$apollo
          .query({
            query: require("../api/isAllowToGenerateReport.graphql"),
            variables: {
              date: this.date,
            },
          })
          .then((data) => {
            this.btnloading = false;
            if (data.data.isAllowToGenerateReport) {
              this.generateReport();
            } else {
              this.text = `Please generate report upto ${this.date}`;
              this.snackbar = true;
            }
          })
          .catch((error) => {
            if (error.graphQLErrors != null) {
              console.error(error.graphQLErrors);
            }
            this.btnloading = false;
          });
      }
    },

    searchDelivery() {
      const dateTime = moment(`${this.date}`).format("YYYY-MM-DD");
      let modifiedDate = new Date(dateTime);
      console.log(modifiedDate);

      this.$apollo.queries.getDeliveryDetailsByDate.refetch({
        status: this.deliveryStatus,
        shift: this.deliveryShift,
        date: modifiedDate,
      });
    },
  },
  computed: {
    itemsWithSno() {
      if (this.pendingList == null) return [];
      return this.pendingList.map((d, index) => ({ ...d, slNo: index + 1 }));
    },
    formatDate() {
      return this.date ? moment(this.date).format(DATE_FORMAT) : "";
    },
  },
};
</script>
<style>
.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.2 !important;
}
</style>
