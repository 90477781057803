<template>
  <v-container class="px-0 mx-0">
    <v-layout justify-end>
      <v-btn
        @click="exportExcel"
        width="150"
        :loading="loading" 
        class="mt-n3 white--text text-capitalize btn_fnt"
        color="primary"
      >
        <v-icon small class="me-2">mdi-export</v-icon>Export
      </v-btn>
    </v-layout>
    <div v-show="false">
      <v-data-table
        id="DietitianAppoinments"
        hide-default-footer
        :items-per-page="-1"
        :headers="head"
        :items="customerDietitianApptExportList"
        class="elevation-1 mt-6"
      >
        <template v-slot:[`item.name`]="{ item }">
          <span v-if="item.customer">
            <span v-if="item.customer.user">
              {{
                item.customer.user.firstnameEn[0].toUpperCase() +
                  item.customer.user.firstnameEn.substring(1)
              }}
              {{
                item.customer.user.lastnameEn[0].toUpperCase() +
                  item.customer.user.lastnameEn.substring(1)
              }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.dietitian`]="{ item }">
          <span v-if="item.dietitian">
            <span v-if="item.dietitian.user">
              {{
                item.dietitian.user.firstnameEn[0].toUpperCase() +
                  item.dietitian.user.firstnameEn.substring(1)
              }}
              {{
                item.dietitian.user.lastnameEn[0].toUpperCase() +
                  item.dietitian.user.lastnameEn.substring(1)
              }}
            </span>
          </span>
        </template>
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ new Date(parseInt(item.createdDate)) | moment("MMM DD YYYY") }}
        </template>
        <template v-slot:[`item.appointmentTime`]="{ item }">
          <span v-if="item.appointmentDate">
            {{
              new Date(parseInt(item.appointmentDate)) | moment("MMM DD YYYY")
            }}
            {{ item.appointmentTime }}
          </span>
        </template>
      </v-data-table>
    </div>
  </v-container>
</template>

<script>
import { Utils } from "../../../utils/utils";
export default {
  props: {
    headers: {
      required: true
    }
  },
  data() {
    return {
      customerDietitianApptExportList: [],
      loading: false,
      variables: {
        limit: 0,
        skip: 0
      },
      head: []
    };
  },

  created() {
    this.head = this.headers.filter(
      x => x.value != "actions" && x.value != "slNo"
    );
    // this.getAllActiveDietitianAppoinments();
  },

  methods: {
    getAllActiveDietitianAppoinments() {
      this.$apollo
        .watchQuery({
          query: require("../../api/getAllActiveDietitianAppoinments.graphql"),
          variables: this.variables
        })
        .refetch({
          variables: this.variables
        })
        .then(data => {
          if (data.data) {
            this.customerDietitianApptExportList =
              data.data.getAllActiveDietitianAppoinments.dietitianAppointments;
            setTimeout(() => {
              Utils.htmlToExcel(
                "DietitianAppoinments",
                "Dietitian Appoinments",
                false
              );
            }, 100);
          }
        });
      this.loading = false;
    },

    exportExcel() {
      this.loading = true;
      this.getAllActiveDietitianAppoinments();
    }
  }
};
</script>
